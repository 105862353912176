<template>
  <div id="app" v-on:scroll="handleScroll">
    <top-nav-transparent
      v-if="returnValidRoutes && dataready"
      :scroll_direction.sync="scroll_direction"
      :current_scroll.sync="new_scroll"
      :page_settings_default.sync="utilscontent.top_nav"
    />
    <!-- <top-nav v-if="returnValidRoutes" />
    <top-nav-search v-if="$route.name === 'search'" /> -->
    <transition name="fade" mode="out-in">
      <router-view class="inner" />
    </transition>
    <events-dialog v-if="$route.path !== '/events'" />
    <footer-nav
      v-if="
        $route.path !== '/whyindoinfo' && $route.path !== '/events' && dataready
      "
      :footer_head.sync="utilscontent.footer_nav.footer_head"
    />
    <register-dialog
      v-if="dataready"
      :page_settings_default.sync="utilscontent.register_dialog"
    />
    <!-- <season-events-dialog /> -->
    <announcement-dialog />
    <company-search placeholder="Search Companies"></company-search>
  </div>
</template>
<script>
import TopNav from "@/components/utils/TopNav.vue";
import TopNavSearch from "@/components/utils/TopNavSearch.vue";
import TopNavTransparent from "@/components/utils/TopNavTransparent.vue";
import EventsDialog from "@/components/utils/EventsDialog.vue";
import RegisterDialog from "@/components/utils/RegisterDialog.vue";
import SeasonEventsDialog from "@/components/utils/SeasonEventsDialog.vue";
import AnnouncementDialog from "@/components/utils/AnnouncementDialog.vue";

import CompanySearch from "./components/utils/CompanySearch.vue";
import { mapGetters } from "vuex";
// Footers
import FooterNav from "@/components/utils/FooterNav.vue";
export default {
  components: {
    TopNav,
    TopNavSearch,
    TopNavTransparent,
    FooterNav,
    EventsDialog,
    RegisterDialog,
    SeasonEventsDialog,
    CompanySearch,
    AnnouncementDialog,
  },
  data() {
    return {
      isUserScrolling: "",
      old_scroll: 0,
      new_scroll: 0,
      dataready: false,
      scroll_direction: "up",
      currentLanguage: "ID",
      utilscontent: {},
      register_page_settings: {},
    };
  },

  metaInfo: {
    title: "Indoinfo CyberQuote Indonesia",
    // all titles will be injected into this template
    titleTemplate: "%s | Business Information Report",
    meta: [
      {
        name: "description",
        content:
          "Layanan informasi untuk mitigasi risiko bisnis, berbasis data & analisa yang memberikan solusi efektif bagi organisasi dan perusahaan",
      },
    ],
  },
  methods: {
    handleScroll($event) {
      this.isUserScrolling = window.scrollY > 0;
      this.new_scroll = window.scrollY;

      if (this.new_scroll < this.old_scroll) {
        this.old_scroll = this.old_scroll - (this.old_scroll - this.new_scroll);
        // console.log("OLD SCROLL (move up): ", this.old_scroll);
        this.scroll_direction = "up";
      }
      if (this.new_scroll > this.old_scroll) {
        this.old_scroll = this.new_scroll;
        // console.log("OLD SCROLL (move down): ", this.old_scroll);
        this.scroll_direction = "down";
      }
      // console.log("calling handleScroll", window.scrollY);
    },
    iniateSwapLanguage() {
      this.currentLanguage = this.returnCurrentLanguage;
      if (this.returnCurrentLanguage === "EN") {
        this.utilscontent = this.returnEnglishContent;
        this.dataready = true;
      } else if (this.returnCurrentLanguage === "ID") {
        this.utilscontent = this.returnIndonesianContent;
        this.dataready = true;
      }
    },
  },
  computed: {
    ...mapGetters([
      "language",
      "ut/indonesian",
      "ut/english",
      "register_dialog",
    ]),
    returnValidRoutes() {
      if (
        this.$route.name === "register" ||
        this.$route.name === "team-support" ||
        this.$route.name === "events"
      ) {
        return false;
      } else {
        return true;
      }
    },
    returnCurrentLanguage() {
      return this.language;
    },
    returnIndonesianContent() {
      return this["ut/indonesian"];
    },
    returnEnglishContent() {
      return this["ut/english"];
    },
  },
  watch: {
    returnCurrentLanguage(newVal, oldVal) {
      this.iniateSwapLanguage();
    },
    register_dialog(val) {
      console.log("REGISTER STOPPED WORKING", val);
    },
    old_scroll() {
      this.$store.dispatch("CHANGE_SCROLLS", {
        isUserScrolling: this.isUserScrolling,
        new_scroll: this.new_scroll,
        old_scroll: this.old_scroll,
        scroll_direction: this.scroll_direction,
      });
    },
  },
  mounted() {
    this.iniateSwapLanguage();
    console.log("BASE URL", process.env);
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.$store.dispatch("CHANGE_SITE_CONFIG", location.hostname);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss">
#app {
  position: relative;
  // scroll-behavior: smooth;
}

// VUESAX EDITS
#app
  .vs-dialog-content.fullScreen
  .vs-dialog.vs-dialog--fullScreen.vs-dialog--scroll
  .vs-dialog__content.notFooter {
  max-height: calc(100vh - 30px) !important;
  overflow: auto !important;
}
.vs-notification__content__header {
  h4 {
    font-size: 0.9rem !important;
    color: #fff;
    font-family: "poppinsBold";
  }
}
.vs-notification__content__text {
  p {
    font-size: 0.9rem !important;
    color: #fff;
    font-family: "poppinsSemiBold";
  }
}

.vs-dialog-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.vs-input-content .vs-input {
  width: 100% !important;
}

.vs-card {
  margin-top: 10px !important;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  min-width: 100% !important;
  min-height: 100% !important;
}

.events .vs-card {
  background: #fefefe !important;
}

/* rules that target drain elements */

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0.5s ease;
  opacity: 0;
}
</style>
