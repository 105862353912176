var terms_con_indonesian = {
    dialog_title: "Syarat dan Kondisi",
    sections: [
        {
            section_title: "Kebijakan Privasi & Syarat Ketentuan Layanan Jasa Indoinfo",
            section_desc: [
                {
                    type: 'paragraph',
                    desc: ['Kebijakan Privasi berikut ini menjelaskan bagaimana Layanan Jasa Indoinfo ("Layanan Jasa") dalam mengumpulkan, menyimpan, menggunakan, memindahkan, mengungkapkan dan melindungi data dan/atau informasi tentang Perusahaan Anda yang dapat diidentifikasikan oleh sistem Layanan Jasa. Mohon Anda membaca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa Anda telah memahami isi dari ketentuan Kebijakan Privasi ini kami berlakukan. Kebijakan Privasi ini mencakup hal-hal sebagai berikut:']
                },
                {
                    type: 'list',
                    desc: [
                        'Informasi Layanan Jasa',
                        'Pengumpulan dan Penyimpanan Informasi',
                        'Penggunaan Informasi',
                        'Keamanan Data Informasi',
                        'Perubahan atas Kebijakan Privasi ini'
                    ]
                }
            ],
            section_elaborate: [
                {
                    elab_title: 'Informasi Layanan Jasa.',
                    elab_desc_type: 'paragraph',
                    elab_desc: ['Layanan Jasa merupakan bagian produk PT Indoinfo Indonesia dan atau Afiliasi ("Indoinfo Indonesia dan atau Afiliasi"), yang sistemnya di operasikan secara online untuk mempermudah layanan terhadap konsumen, Layanan Jasa menjaga kerahasian data informasi yang telah dikirim, data informasi yang dikirim akan menjadi acuan penilaian terhadap setiap perusahaan yang telah terdaftar di Layanan Jasa.'],
                },
                {
                    elab_title: 'Pengumpulan dan Penyimpanan Informasi.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        '1. Kami mengumpulkan dan menyimpan data Informasi dari Perusahaan Anda agar dapat mempermudah Layanan Jasa. Perusahaan Anda dapat memberikan Informasi tersebut sesuai dengan list dokumen yang telah di tetapkan oleh Indoinfo Indonesia dan atau Afiliasi saat Anda mendaftar dan beberapa informasi akan secara otomatis dikumpulkan ketika Anda terdaftar di situs website atau aplikasi kami.',
                        '2. Ketika Perusahaan Anda mengunjungi Situs website dan atau aplikasi kami, administrator kami akan memproses data teknis seperti alamat IP Anda, halaman web yang pernah anda kunjungi, browser internet yang anda gunakan, halaman web yang sebelumnya atau selanjutnya anda kunjungi dan durasi setiap kunjungan atau sesi yang memungkinkan kami untuk mengirimkan fungsi-fungsi layanan. Sebagai tambahan, dalam beberapa hal, browser dapat menyarankan untuk melihat dokumen apa saja yang harus di kumpulkan, kami dapat memastikan bahwa Perusahaan Anda dapat (terus) menemukan informasi pada Situs web dengan cara yang cepat dan sederhana.'
                    ]
                },
                {
                    elab_title: 'Penggunaan Informasi.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Anda menyetujui bahwa semua informasi yang kami terima dapat kami gunakan untuk berbagai keperluan Layanan Jasa kami dan dapat diverifikasi keabsahan datanya ke pihak ketiga lain jika dianggap perlu sesuai dengan standar sistem aplikasi Layanan Jasa kami.',
                        'Jika ada kesalahan, atau ketidaksesuaian dokumen maka dimungkinkan untuk melakukan konfirmasi ulang kepada Perusahaan Anda agar data tersebut benar-benar akurat dan sesuai standar Layanan Jasa.',
                        'Setiap perusahaan yang terdaftar akan diberikan akses masuk di website kami. Perusahaan Anda menyetujui untuk tidak menggunakan informasi yang diungkapkan terkait tujuan lain tanpa persetujuan tertulis dari Indoinfo Indonesia dan atau Afiliasi.'

                    ]
                },
                {
                    elab_title: 'Keamanan Data Informasi.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Kami tidak menjamin keamanan data yang dikirim oleh Perusahaan Anda tidak akan ditahan atau terganggu ketika sedang dikirimkan kepada kami. Setiap pengiriman informasi oleh Perusahaan Anda kepada kami merupakan resiko tersendiri. Perusahan Anda tidak boleh mengungkapkan sandi situs online Layanan Jasa kepada siapapun. Bagaimanapun efektifnya suatu teknologi, tidak ada sistem keamanan yang tidak dapat ditembus.',
                    ]
                },
                {
                    elab_title: 'Perubahan atas Kebijakan Privasi ini.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Kami dapat mengubah Kebijakan Privasi ini untuk mencerminkan perubahan dalam kegiatan kami tanpa persetujuan Perusahaan Anda.',
                        'Kami menghimbau perusahaan anda untuk meninjau halaman ini secara berkala untuk mengetahui informasi terbaru tentang bagaimana ketentuan. Kebijakan Privasi ini kami berlakukan..'
                    ]
                }
            ]
        },
        {
            section_title: "Syarat dan Ketentuan Indoinfo",
            section_desc: [
                {
                    type: 'paragraph',
                    desc: ['Syarat dan Ketentuan yang ditetapkan di bawah ini mengatur pemakaian Layanan Jasa yang ditawarkan oleh Indoinfo Indonesia dan atau Afiliasi terkait penggunaan di situs atau aplikasi kami. Pengguna disarankan membaca dengan seksama karena dapat berdampak kepada hak dan kewajiban Pengguna di bawah hukum. Dengan mendaftar dan menggunakan situs atau aplikasi kami, maka pengguna dianggap telah membaca, mengerti, memahami dan menyutujui semua isi dalam Syarat dan Ketentuan. Syarat dan Ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Pengguna dengan Indoinfo Indonesia dan atau Afiliasi. Jika pengguna tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat dan Ketentuan, maka pengguna tidak diperkenankan menggunakan layanan di situs atau aplikasi kami. Syarat dan Ketentuan ini mencakup hal-hal sebagai berikut:']
                },
                {
                    type: 'list',
                    desc: [
                        'Definisi Layanan Jasa',
                        'Pelapasan (Memutuskan kontrak kerja)',
                        'Ganti Rugi',
                        'Pilihan Hukum',
                        'Pembaharuan'
                    ]
                }
            ],
            section_elaborate: [
                {
                    elab_title: 'Definisi Layanan Jasa',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        '1. Syarat dan Ketentuan adalah perjanjian antara konsumen dan Layanan Jasa yang berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung jawab konsumen dan Layanan Jasa, serta tata cara penggunaan sistem Layanan Jasa.',
                        '2.Perusahaan Anda adalah pihak yang menggunakan Layanan Jasa, namun tidak terbatas berkunjung ke Situs website kami.',
                        '3. Perusahaan Anda adalah Pengguna terdaftar yang melakukan permintaan assesment or review document di Situs Layanan Jasa.'
                    ]
                },
                {
                    elab_title: 'Pelapasan (Memutuskan kontrak kerja)',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Perusahaan Anda tidak memiliki wewenang untuk menuntut atas data informasi yang timbul dari atau dengan cara apapun berhubungan dengan sengketa tersebut. Dengan demikian maka Perusahaan Anda setuju melepaskan segala perlindungan hukum (yang terdapat dalam undang-undang atau peraturan hukum yang berlaku di Indonesia) yang akan membatasi cakupan ketentuan pelepasan ini.'
                    ]
                },
                {
                    elab_title: 'Ganti Rugi',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Perusahaan Anda akan melepaskan Layanan Jasa dari tuntutan ganti rugi dan menjaga Indoinfo Indonesia dan atau Afiliasi dari setiap klaim atau tuntutan, termasuk biaya hukum yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal Perusahaan Anda melanggar Perjanjian ini dan/atau pelanggaran terhadap hukum.'
                    ]
                },
                {
                    elab_title: 'Pilihan Hukum',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Kebijakan Privasi ini dibuat dalam bahasa Inggris dan bahasa Indonesia. Dengan demikian kedua bahasa adalah legal dan sah, dalam hal terjadi perbedaan antara bahasa Inggris dan teks bahasa Indonesia, maka yang menjadi acuan adalah teks bahasa Indonesia. Kebijakan Privasi dan Syarat Ketentuan ini mengikat Perusahaan Anda dengan Layanan Jasa. Setiap dan semua perselisihan yang timbul dari atau sehubungan dengan penafsiran atau pelaksanaan Kebijakan Privasi ini harus diselesaikan dengan saling berkonsultasi antara Para Pihak dengan itikad baik.',
                        'Jikalau penyelesaian damai mengalami kegagalan maka penyelesaian perselisihan berdasarkan peraturan Badan Arbitrase Nasional Indonesia (BANI) di Indonesia dimana hasil putusan bersifat final dan mengikat Para Pihak.'
                    ]
                }
            ]
        }
    ]
}

var terms_con_english = {
    dialog_title: "Privacy Policy & Terms of Service Indoinfo",
    sections: [
        {
            section_title: "Privacy Policy & Terms of Service Indoinfo",
            section_desc: [
                {
                    type: 'paragraph',
                    desc: ['The following Privacy Policy describes how Indoinfo Services ("Services") collects, stores, uses, moves, discloses and protects data and / or information related to your Company that the Service System identifies. Please read this Privacy Policy carefully to ensure that you have an understanding of the terms of this Privacy Policy we apply. This Privacy Policy includes the following :']
                },
                {
                    type: 'list',
                    desc: [
                        'Service Information',
                        'Information Collection and Storage',
                        'Use of Information',
                        'Information Data Security',
                        'Changes to this Privacy Policy'
                    ]
                }
            ],
            section_elaborate: [
                {
                    elab_title: 'Service Information.',
                    elab_desc_type: 'paragraph',
                    elab_desc: ['The Service is part of PT Indoinfo Indonesia and or Affiliates products ("Indoinfo Indonesia and or Affiliates"), where the systems are operated online to facilitate customer service, the Service keeps the confidentiality of data that has been transmitted and may serve as a base to conduct assessment on any company that has been registered with the Service.'
                    ],
                },
                {
                    elab_title: 'Information Collection and Storage.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        '1. We collect and store Information data on your Company in order to facilitate the Service. Your Company will directly provide such Information in accordance with the document list form set by Indoinfo Indonesia and or Affiliates when you register and some information will be automatically collected when you are registered in our websites and or applications.',
                        '2. When your Company visits our Website and or applications, our administrators will process technical data such as your IP address, web page you visited, internet browser you used, previous or next page visit and duration of each visit or session which allows us to submit Website functions. In addition, in some cases, browsers may advise you to view what documents should be collected, we can ensure that your Company can (continue) finding information on the Website in a quick and simple way.'
                    ]
                },
                {
                    elab_title: 'Use of Information.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'You agree that all informations which have been received shall be used in our Various Services and could be verified against any third party whenever necessary as per our service standard.',
                        'For any error, or document inaccuracy then a re-confirmation to your company could be applied in order for us to get the most accurate information as per our service standard.',
                        'Every company listed will receive access to login on our website. Your company agrees that it shall not use information disclosed for any other purposes without the prior written consent of Indoinfo Indonesia and or Affiliates.'

                    ]
                },
                {
                    elab_title: 'Information Data Security.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        "We do not guarantee the security of data sent by the Company will not be detained or interrupted while it is being sent to us. Any submission of information by your Company to us is a risk. Your company may not disclose the password of any Service Center's online site to anyone. No matter how effective a technology is, there is no impenetrable security system.",
                    ]
                },
                {
                    elab_title: 'Changes to this Privacy Policy.',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        "We may change this Privacy Policy to reflect changes in our activities without your Company's consent. We encourage your company to periodically review this page to find out the latest information about how our Privacy Policy applies..",
                    ]
                }
            ]
        },
        {
            section_title: "Terms of Service Indoinfo",
            section_desc: [
                {
                    type: 'paragraph',
                    desc: ['The Terms and Conditions set out below govern the use of the Services offered by Indoinfo Indonesia and or Affiliates regarding the use of our websites and or application. Users are advised to read carefully as they may affect the rights and obligations of the User under the law. By registering and using our website and or application, users shall be deemed to have read, understood, understood and agreed to all content in the Terms and Conditions. These Terms and Conditions constitute agreements set forth in a valid agreement between the User and D&B Indonesia and or Affiliates. If the user does not consent to one, part, or all of the Terms and Conditions, the user is not permitted to use the service at our website and or application. Terms of this provision include the following:']
                },
                {
                    type: 'list',
                    desc: [
                        'Definition of Service',
                        'Breathing (Deciding the contract of employment)',
                        'Indemnification',
                        'Choice of Law',
                        'Renewal'
                    ]
                }
            ],
            section_elaborate: [
                {
                    elab_title: 'Definition of Service',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        '1. Terms and Conditions are agreements between the consumer and the Service Provider which contain a set of rules governing the rights, obligations, responsibilities of the consumer and the Service, as well as the procedures for the use of the Service System.',
                        '2. Your Company is a party that uses the Service, but is not limited to visiting our Website.',
                        '3. Your Company is a Registered User who makes an assessment request or review document on the Service Website.'
                    ]
                },
                {
                    elab_title: 'Disposal (Deciding the employment contract)',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Your company has no claimed authority over information data arising from or in any way connected with the dispute. Accordingly, your Company intentionally waives any legal protection (contained in any law or regulation applicable in Indonesia) which will limit the scope of this waiver.'
                    ]
                },
                {
                    elab_title: 'Indemnification',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'Your Company will release the Service from indemnification and hold Indoinfo Indonesia and Affiliates from any claim or demand, including reasonable legal fees, incurred by a third party arising in the event that your Company violates this Agreement and or a violation of the law.'
                    ]
                },
                {
                    elab_title: 'Choice of Law',
                    elab_desc_type: 'paragraph',
                    elab_desc: [
                        'This Privacy Policy is made in English and Bahasa Indonesia. Thus both languages are legal and legitimate, in the event of any discrepancy between English and Indonesian text, the reference shall be the Indonesian text. This Privacy Policy and Terms of Conditions will bind your Company with the Service. Any and all disputes arising out of or in connection with the interpretation or implementation of this Privacy Policy shall be resolved by mutual consultation between the Parties in good faith.',
                        'Failing such a peaceful settlement, any such dispute shall be filed for arbitration under the rules of the Indonesian National Arbitration Board (BANI) in Indonesia and the proceeds of such arbitration shall be final and binding on the Parties.'
                    ]
                }
            ]
        }
    ]
}

export { terms_con_indonesian, terms_con_english }