<template>
  <div class="position: absolute;">
    <div class="container-fluid container-fluid mx-0 px-0">
      <div class="row footer_header px-0 mx-0" style="position: relative">
        <!-- <div class="col-md-12 col-12 d-flex justify-content-start mt-5">
        <img :src="logo" alt="" class="logo" />
      </div> -->
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-5 col-12 d-flex align-items-start mt-5">
              <div class="row py-0 my-0">
                <div class="col-12 py-0 my-0 d-flex align-items-center">
                  <img :src="logo" alt="" class="logo" />
                </div>
                <div class="col-12 py-3 my-0 pl-4 pr-4">
                  <p class="openSansBold c-primary">PT Cyberquote Indonesia</p>
                  <p style="color: #6f8197">
                    Atria @Sudirman Lv. 23B Jl. Jend. Sudirman <br />
                    Kav. 33A Jakarta Pusat 10220
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row justify-content-md-end justify-content-start">
                <div
                  class="col-md-4 col-6 d-flex mt-md-5 mb-md-5 mt-0 px-4"
                  v-for="(item, index) in footer_head"
                  :key="index"
                >
                  <ul>
                    <li
                      class="mb-4 openSansBold c-primary"
                      style="font-size: 20px"
                    >
                      {{ item.name }}
                    </li>
                    <li
                      v-for="(link, index) in item.links"
                      :key="index + 'links' + Math.random()"
                      class="mt-2"
                      style="font-size: 18px"
                    >
                      <span
                        v-if="
                          link.type != 'internal' && link.type != 'external'
                        "
                        @click="goToRoute(link.link, link.type, link.choice)"
                      >
                        {{ link.name }}
                      </span>

                      <router-link
                        v-if="link.type == 'internal'"
                        :to="link.link"
                        style="text-decoration: none; color: inherit"
                      >
                        {{ link.name }}
                      </router-link>

                      <a
                        v-if="link.type == 'external'"
                        :href="link.link"
                        target="_blank"
                        style="text-decoration: none; color: inherit"
                      >
                        {{ link.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row footer_foot mx-0 px-0 white pb-5 pt-5 py-sm-5"
        style="height: 100%"
        :class="{ 'bg-c-primary': returnCurrentRoute !== '/' }"
      >
        <div
          class="col-md-9 col-12 d-flex align-items-center justify-content-md-start justify-content-center order-md-0 order-1"
        >
          <small class="openSansBold">© Copyright 2022 Indoinfo</small>
        </div>
        <div
          class="col-md-3 col-12 order-md-1 order-0 d-flex justify-content-md-start justify-content-center align-items-center"
        >
          <div class="row" style="width: 100%">
            <div
              class="col-12 d-flex justify-content-center justify-content-md-start mt-2"
            >
              <!-- <vs-button
                block
                color="rgb(172, 204, 255)"
                style="color: white; opacity:0"
                flat
                class="poppinsBold d-flex"
                @click="openLinkTree()"
              >
                <span
                  ><img
                    src="@/assets/icons/ic_linktree.svg"
                    width="15px"
                    height="15px"
                    class="mr-2"
                    alt=""
                /></span>
                Follow Us
              </vs-button> -->

              <div
                v-for="(item, index) in social_media_links"
                :key="index"
                class="mx-2"
              >
                <a :href="'//' + item.link" target="_blank">
                  <img :src="item.img" alt="" class="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
        class="col-12 d-flex justify-content-end align-items-center"
        style="position: absolute"
      >
        <img :src="logo_white" class="icon-footer" />
      </div> -->
      </div>
    </div>
    <vs-dialog v-model="open_coming_soon" width="250px">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <lottie
            :width="200"
            :options="lottieAnimOptions.launch"
            v-on:animCreated="handleAnimation"
          >
          </lottie>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <h4 class="c-primary openSansMedium text-center">
            This feature is coming soon!
          </h4>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <p class="text-center">
            We are working hard to launch them for you! Hang tight!
          </p>
        </div>
      </div>
    </vs-dialog>
    <!-- T&C DIALOG -->
    <vs-dialog v-model="open_terms_and_condition">
      <template #header>
        <div class="row pt-4" style="width: 100%">
          <div class="col-9 d-flex justify-content-start">
            <h3 class="c-primary openSansBold">
              {{ returnTermsConditionContent.dialog_title }}
            </h3>
          </div>
          <div class="col-3">
            <div class="row custom-switcher">
              <div
                class="col-6 d-flex justify-content-center align-items-center"
                style="cursor: pointer"
                v-for="(lang, index) in lang_options"
                :key="index + 'lang'"
                @click="languageSwitch(lang.abbrev)"
                :class="{ active: current_lang === lang.abbrev }"
              >
                {{ lang.abbrev }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="container py-3 px-3 terms-container">
        <div class="row">
          <div
            class="col-12"
            v-for="(section, index) in returnTermsConditionContent.sections"
            :key="index + 'section-terms' + Math.random()"
          >
            <div class="row px-2">
              <div class="col-12">
                <h4 class="openSansSemiBold c-primary section-header">
                  {{ section.section_title }}
                </h4>
              </div>
              <div
                class="col-12 px-3"
                v-for="(section_desc, index) in section.section_desc"
                :key="index"
              >
                <div v-if="section_desc.type === 'paragraph'">
                  <p
                    v-for="(desc, index) in section_desc.desc"
                    :key="index + 'section-desc-type-p' + Math.random()"
                  >
                    {{ desc }} <br />
                  </p>
                </div>
                <ul v-if="section_desc.type === 'list'">
                  <li
                    v-for="(desc, index) in section_desc.desc"
                    :key="index + 'section-desc-type-li' + Math.random()"
                  >
                    {{ desc }}
                  </li>
                </ul>
              </div>
              <div
                class="col-12 section-elaborate"
                v-for="(section_elaborate, index) in section.section_elaborate"
                :key="index + 'section-elaborate' + Math.random()"
              >
                <div class="row">
                  <div class="col-12 c-primary title pt-2 mb-2">
                    <h5>{{ section_elaborate.elab_title }}</h5>
                  </div>
                  <div class="col-12 openSansItalic">
                    <div
                      v-for="(desc, index) in section_elaborate.elab_desc"
                      :key="index + 'section-elaborate-desc' + Math.random()"
                    >
                      <p
                        v-if="section_elaborate.elab_desc_type === 'paragraph'"
                      >
                        {{ desc }}
                      </p>
                      <ul v-if="section_elaborate.elab_desc_type === 'list'">
                        <li>
                          {{ desc }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import ic_logo from "@/assets/logo.png";
import ic_logo_white from "@/assets/logo_white_transparent.png";
import {
  terms_con_indonesian,
  terms_con_english,
} from "@/assets/asset_exports/footer_assets.js";

import linktree from "@/assets/icons/lt_ic.svg";
import tiktok from "@/assets/icons/tt_ic.svg";
import youtube from "@/assets/icons/yt_ic.svg";
import linkedin from "@/assets/icons/li_ic.svg";
import instagram from "@/assets/icons/ig_ic.svg";
import facebook from "@/assets/icons/fb_ic.svg";

import lottie from "vue-lottie/src/lottie.vue";

import * as launchingAnimation from "@/assets/animations/launching.json";
export default {
  components: {
    lottie,
  },
  props: {
    footer_head: Array,
  },
  data() {
    return {
      logo: ic_logo,
      logo_white: ic_logo_white,
      social_media_links: [
        { img: linktree, link: "linktr.ee/indoinfo.cq" },
        { img: tiktok, link: "tiktok.com/@indoinfocq" },
        {
          img: youtube,
          link: "www.youtube.com/channel/UCAWHYg0D-xT7i_wzvJsOf4Q",
        },
        {
          img: linkedin,
          link: "www.linkedin.com/company/cyberquoteindonesia/",
        },
        { img: instagram, link: "www.instagram.com/indoinfo.cq" },
        { img: facebook, link: "www.facebook.com/indoinfo.cq" },
      ],
      open_coming_soon: false,
      open_terms_and_condition: false,
      terms: {
        indonesian_terms: terms_con_indonesian,
        english_terms: terms_con_english,
      },
      current_lang: "ID",
      lang_options: [
        {
          name: "Bahasa Indonesia",
          abbrev: "ID",
        },
        {
          name: "English",
          abbrev: "EN",
        },
      ],
      anim: null,
      lottieAnimOptions: {
        launch: {
          animationData: launchingAnimation.default,
        },
      },
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    openLinkTree() {
      window.open("https://linktr.ee/indoinfo.cq");
    },
    goToRoute(link, type, choice) {
      console.log("footer: ", link, type, choice);
      if (type === "external") {
        window.open(link, "_blank");
      }
      if (type === "internal") {
        this.$router.push({ path: link });
      }

      if (type === "dialog") {
        console.log("opening dialog", choice);
        if (choice === "terms-conditions") {
          this.open_terms_and_condition = true;
        }

        if (choice === "under-maintenance") {
          this.open_coming_soon = true;
        }
      }
    },
    languageSwitch(lang) {
      this.current_lang = lang;
    },
  },

  computed: {
    returnTermsConditionContent() {
      if (this.current_lang === "ID") {
        return this.terms.indonesian_terms;
      }

      if (this.current_lang === "EN") {
        return this.terms.english_terms;
      }
    },
    returnCurrentRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 100%;
  max-height: 50px;
  width: auto;
}
.footer_header {
  background-color: #f2f7ff;
}
ul {
  list-style: none;
  padding: 0px;
  color: #6f8197;
  li {
    cursor: pointer;
  }
  li:hover {
    color: #0052b4;
  }
}

.footer_foot {
  .icon {
    // animate
    transition: all 0.1s ease-in-out;

    &:hover {
      // move top
      transform: translateY(-3px);
    }
  }
}

.icon-footer {
  height: 10px;
  width: auto;
}

.terms-container {
  background: #f2f7ff;
  max-height: 60vh;
  overflow-y: auto;
  .section-header {
    background: rgb(223, 233, 248);
    padding: 10px;
  }
  .section-elaborate {
    padding-left: 20px !important;
    .title {
      border-bottom: 1px solid #0052b4;
      border-top: 1px solid #0052b4;
      background: rgb(227, 238, 255);
    }
  }
}

.custom-switcher {
  background-color: black !important;
  border-radius: 20px;
  color: #fff;
  .active {
    background-color: #003c86;
    border-radius: 20px;
    padding: 5px;
    transition: all 150ms ease-in-out;
  }
}
</style>
