<template>
  <div class="container-fluid mx-0 px-0" style="">
    <!-- OVERLAY SMALL PHONES NAVIGATION -->
    <div class="overlay px-3" style="" v-if="open_overlay_menu">
      <div class="cd-flex justify-content-end">
        <span
          class="material-icons"
          style="color: #fff; position: fixed; z-index: 99; right: 5%"
          @click="open_overlay_menu = false"
          >close</span
        >
      </div>
      <div class="row">
        <div
          class="col-12 overlay-menu"
          v-for="(menu, index) in drop_menu"
          :key="index + 'overlay'"
        >
          <h2 @click="drop_item_overlay(menu.id, menu.shoulddrop)">
            {{ menu.name }}
            <span
              class="material-icons-round"
              v-if="menu.shoulddrop && menu.id === drop"
              >expand_less</span
            >
            <span
              class="material-icons-round"
              v-if="menu.shoulddrop && menu.id !== drop"
              >expand_more</span
            >
          </h2>
          <transition>
            <div class="row" v-if="menu.id === drop">
              <!-- LEFT PANE -->
              <div
                class="col-12"
                v-for="list in menu.left_pane"
                :key="list + 'overlay' + Math.random()"
              >
                <h4>
                  <b> {{ list.left_pane_title }}</b>
                </h4>
                <div
                  class="row mt-2"
                  v-for="option in list.left_pane_options"
                  :key="option + 'overlay' + Math.random()"
                >
                  <div class="col-12" v-if="list.left_pane_style === 'text'">
                    <b>{{ option.options_title }}</b>
                  </div>
                  <div class="col-12" v-if="list.left_pane_style === 'text'">
                    {{ option.options_description }}
                  </div>
                  <div
                    class="col-12"
                    v-if="list.left_pane_style === 'card-with-icon'"
                  >
                    <div class="row">
                      <div class="col-2 d-flex align-items-center">
                        <span class="material-icons-round">{{
                          option.icon
                        }}</span>
                      </div>
                      <div class="col-10">
                        <div class="row">
                          <div class="col-12">
                            <b>{{ option.options_title }}</b>
                          </div>
                          <div class="col-12">
                            {{ option.options_description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END OF LEFT PANE -->

              <!-- RIGHT PANE -->
              <div
                class="col-12"
                v-for="list in menu.right_pane"
                :key="list + 'overlay' + Math.random()"
              >
                <h4>
                  <b> {{ list.right_pane_title }}</b>
                </h4>
                <div
                  class="row mt-2"
                  v-for="option in list.right_pane_options"
                  :key="option + 'overlay' + Math.random()"
                >
                  <div class="col-12" v-if="list.right_pane_style === 'text'">
                    <b>{{ option.options_title }}</b>
                  </div>
                  <div class="col-12" v-if="list.right_pane_style === 'text'">
                    {{ option.options_description }}
                  </div>
                  <div
                    class="col-12"
                    v-if="list.right_pane_style === 'card-with-icon'"
                  >
                    <div class="row">
                      <div class="col-2 d-flex align-items-center">
                        <span class="material-icons-round">{{
                          option.icon
                        }}</span>
                      </div>
                      <div class="col-10">
                        <div class="row">
                          <div class="col-12">
                            <b>{{ option.options_title }}</b>
                          </div>
                          <div class="col-12">
                            {{ option.options_description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ENF OF RIGHT PANE -->
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- NORMAL NAVIGATION -->
    <nav>
      <div class="d-flex row mx-0 px-5">
        <!-- CHANGE LANGUAGE -->
        <div class="col-6 mx-0 px-0 d-flex justify-content-start">
          <div class="language d-flex align-items-center">
            <span class="mx-2" v-if="lang_selected === 'EN'">
              <img :src="us" class="flag" alt="" />
            </span>
            <span class="mx-2" v-if="lang_selected === 'ID'">
              <img :src="id" class="flag" alt="" />
            </span>
            <b-form-select
              v-model="lang_selected"
              :options="lang_options"
              style="border: none !important; cursor: pointer"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                  class="openSansRegular"
                  >Pick a language</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>
        <!-- END OF CHANGE LANGUAGE -->
        <!-- LOGIN -->
        <div
          class="col-6 d-flex justify-content-end align-items-center"
          style="color: #0052b4"
        >
          Login
          <span
            v-if="$screen.width < 990"
            class="material-icons-round ml-2"
            @click="open_overlay_menu = true"
            >menu</span
          >
        </div>
        <!-- END OF LOGIN -->

        <!-- -------------- -->
        <!-- MAIN BAR COMPONENT -->

        <!-- LOGO -->
        <div
          class="
            col-12 col-md-4
            d-flex
            justify-content-center justify-content-md-start
            pointer
            mx-0
            px-0
          "
          @click="goToRoute('home')"
        >
          <img :src="logoIMG" alt="" />
        </div>
        <!-- END OF LOGO -->
        <!-- NAVIGATION LISTS -->
        <!-- will only show if it is Desktop -->
        <div
          class="col-4 d-flex align-items-center justify-content-around"
          v-if="$screen.width > 990"
        >
          <!-- MENU -->
          <div
            class="d-flex align-items-center px-0"
            v-for="menu in drop_menu"
            :key="menu.id"
            @mouseenter="drop_item(menu.id, menu.shoulddrop)"
            @mouseleave="drop = ''"
            style="height: 100%"
          >
            <!-- MENU NAMES -->
            <span> {{ menu.name }} </span>
            <!-- END OF MENU NAMES -->

            <!-- DROP DOWN CARDS -->
            <transition name="fade">
              <b-card
                v-show="drop === menu.id"
                @mouseleave="drop = ''"
                class="mega-menu-container"
              >
                <div class="row">
                  <!-- HEADER/CARD MENU NAME -->
                  <div class="col-12">
                    <small>
                      {{ menu.name }}
                    </small>
                  </div>
                  <!-- END OF MENU NAME -->

                  <!-- MENU WILL BE DIVIDED BY 2 (LEFT AND RIGHT) -->
                  <!-- !important left pane is always the default benchmark -->
                  <!-- if right pane is empty then make the cols to 12, else if not empty divide the columns by 2 (col-6 each)  -->

                  <!-- LEFT PANE DEFAULT -->
                  <div
                    class="d-flex justify-content-start"
                    :class="{
                      'col-6': menu.right_pane !== '',
                      'col-12': menu.right_pane === '',
                    }"
                  >
                    <div
                      class="row"
                      v-for="list in menu.left_pane"
                      :key="list + Math.random()"
                    >
                      <div class="col-12">
                        <!-- LEFT PANE TITLE -->
                        <h4>
                          {{ list.left_pane_title }}
                        </h4>
                        <!-- END OF LEFT PANE TITLE -->
                      </div>
                      <!-- This below checks the nested node, which will be left pane options -->
                      <div
                        class="col-12 pl-5"
                        v-for="option in list.left_pane_options"
                        :key="option + Math.random()"
                      >
                        <!-- TEXT STYLE -->
                        <div
                          v-if="list.left_pane_style === 'text'"
                          @click="megaMenuGoToRoute(option.link)"
                        >
                          <h5>
                            {{ option.options_title }}
                          </h5>
                          <p>
                            {{ option.options_description }}
                          </p>
                        </div>

                        <!-- END OF TEXT STYLE -->

                        <!-- CARD STYLE -->
                        <b-card
                          v-if="list.left_pane_style === 'card-with-icon'"
                          class="card card-menu-list"
                        >
                          <div class="row">
                            <div class="col-1 d-flex align-items-center">
                              <span class="material-icons-round">check</span>
                            </div>
                            <div class="col-11">
                              <div class="row">
                                <div class="col-12">
                                  <b>{{ option.options_title }}</b>
                                </div>
                                <div class="col-12">
                                  {{ option.options_description }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card>
                        <!-- END OF CARD STYLE -->
                      </div>
                    </div>
                  </div>
                  <!-- RIGHT PANE -->
                  <!-- if right pane exists then (col-6) -->
                  <!-- RIGHT PANE -->
                  <div
                    v-if="menu.right_pane !== ''"
                    class="col-6 d-flex justify-content-start"
                  >
                    <div
                      class="row"
                      v-for="list in menu.right_pane"
                      :key="list + Math.random()"
                    >
                      <!-- RIGHT PANE TITLE -->
                      <div class="col-12">
                        <h4>
                          {{ list.right_pane_title }}
                        </h4>
                      </div>
                      <!-- END OF RIGHT PANE TITLE -->
                      <!-- This below checks the nested node, which will be left pane options -->
                      <div
                        class="col-12 pr-5"
                        v-for="option in list.right_pane_options"
                        :key="option + Math.random()"
                      >
                        <!-- TEXT STYLE -->
                        <div v-if="list.right_pane_style === 'text'">
                          <h5>
                            {{ option.options_title }}
                          </h5>
                          <p>
                            {{ option.options_description }}
                          </p>
                        </div>
                        <!-- END OF TEXT STYLE -->

                        <!-- CARD STYLE WITH ICON -->
                        <b-card
                          v-if="list.right_pane_style === 'card-with-icon'"
                          class="card card-menu-list"
                        >
                          <div class="row">
                            <div class="col-1 d-flex align-items-center">
                              <span class="material-icons-round">check</span>
                            </div>
                            <div class="col-11">
                              <div class="row">
                                <div class="col-12">
                                  <b>{{ option.options_title }}</b>
                                </div>
                                <div class="col-12">
                                  {{ option.options_description }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card>
                        <!-- END CARD STYLE WITH ICON -->
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </transition>
            <!-- END OF DROP DOWN CARDS -->
          </div>
          <!-- END OF MENU -->
        </div>
        <!-- END OF NAVIGATION LISTS -->
        <!-- CTAs -->
        <div
          class="
            col-md-2 col-6
            d-flex
            align-items-center
            justify-content-center justify-content-md-end
            py-4 py-lg-0
            px-0
          "
        >
          <b-button
            variant="outline-primary"
            class=""
            size="sm"
            block
            @click="goToRoute('Contact Sales')"
            >Contact Sales</b-button
          >
        </div>
        <div
          class="
            col-md-2 col-6
            d-flex
            align-items-center
            justify-content-center justify-content-md-end
            py-4 py-lg-0
            px-0
          "
        >
          <b-button
            variant="primary"
            class="mx-2"
            size="sm"
            block
            @click="goToRoute('Register')"
            >Try for Free</b-button
          >
        </div>

        <!-- END OF CTAs -->
      </div>
    </nav>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
import indoflag from "@/assets/flags/id.png";
import usflag from "@/assets/flags/en.png";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      logoIMG: logo,
      id: indoflag,
      us: usflag,
      lang_selected: "EN",
      lang_options: [
        { value: "EN", text: "English" },
        { value: "ID", text: "Indonesia" },
      ],
      drop: "",
      drop_menu: [
        {
          id: "a_ii",
          name: "About Indoinfo",
          shoulddrop: true,
          left_pane: [
            {
              left_pane_title: "Overview",
              left_pane_style: "text",
              left_pane_options: [
                {
                  options_title: "Why Indoinfo",
                  options_description:
                    "test Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  link: "test",
                },
                {
                  options_title: "Why Indoinfo2",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                },
                {
                  options_title: "Why Indoinfo3",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                },
              ],
            },
          ],
          right_pane: [
            {
              right_pane_title: "Features",
              right_pane_style: "card-with-icon",
              right_pane_options: [
                {
                  options_title: "Why Indoinfo",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  icon: "check",
                },
                {
                  options_title: "Why Indoinfo2",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  icon: "check",
                },
                {
                  options_title: "Why Indoinfo3",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  icon: "check",
                },
              ],
            },
          ],
        },
        {
          id: "wwd",
          name: "What We Do",
          shoulddrop: true,
          left_pane: [
            {
              left_pane_title: "Overview",
              left_pane_style: "text",
              left_pane_options: [
                {
                  options_title: "Why Indoinfo",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                },
                {
                  options_title: "Why Indoinfo2",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                },
                {
                  options_title: "Why Indoinfo3",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                },
              ],
            },
          ],
          right_pane: [
            {
              right_pane_title: "Features",
              right_pane_style: "card-with-icon",
              right_pane_options: [
                {
                  options_title: "Why Indoinfo",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  icon: "check",
                },
                {
                  options_title: "Why Indoinfo2",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  icon: "check",
                },
                {
                  options_title: "Why Indoinfo3",
                  options_description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  icon: "check",
                },
              ],
            },
          ],
        },
        {
          id: "insg",
          name: "Insights",
          shoulddrop: false,
        },
      ],
      open_overlay_menu: false,
    };
  },
  methods: {
    drop_item(id, shoulddrop) {
      if (shoulddrop) {
        this.drop = id;
      } else {
        this.drop = "";
      }
    },
    drop_item_overlay(id, shoulddrop) {
      if (shoulddrop) {
        if (this.drop === id) {
          this.drop = "";
        } else {
          this.drop = id;
        }
      } else {
        // make router work here
      }
    },
    goToRoute(name) {
      if (name === "Register" || name === "Contact Sales") {
        var x = {
          state: true,
          tab: name,
        };
        this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
      } else {
        this.$router.push({ name: name });
      }
    },
    megaMenuGoToRoute(link) {
      if (link === undefined) {
        console.log("this link is undefined");
      } else {
        console.log("link: ", link);
      }
    },
  },
  computed: {
    ...mapGetters(["language"]),
    returnLanguage() {
      return this.language;
    },
  },
  watch: {
    lang_selected(newVal, oldVal) {
      this.$store.dispatch("CHANGE_LANGUAGE", newVal);
    },
    returnLanguage(newVal, oldVal) {
      console.log("listening the store event", newVal);
    },
  },
};
</script>

<style scoped>
nav {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.823) !important;
}
.overlay {
  height: 100vh !important;
  background: rgb(30, 167, 255);
  background: linear-gradient(
    0deg,
    rgba(30, 167, 255, 1) 0%,
    rgba(3, 71, 194, 1) 100%
  );
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 50vh;
  padding-top: 4vh;
}

.overlay .row .overlay-menu {
  color: rgba(255, 255, 255, 0.611);

  cursor: pointer;
}

.overlay-active {
  color: #fff;
}

.card.card-menu-list {
  background: #f2f2f2;
  border: none;
  padding: 0px;
  margin-top: 10px;
}
.card.mega-menu-container {
  position: absolute;
  min-width: 70vw;
  z-index: 2;
  left: -50%;
  top: 100%;
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.212);
}
.flag {
  max-height: 15px;
}

.language {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: gray;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>