<template>
  <div v-if="should_send_email"></div>
</template>

<script>
import axios from "axios";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
export default {
  props: {
    mail_type: String,
    user_details: Object,
    should_send_email: Boolean,
    email_send_status: String,
  },
  data() {
    return {
      end_point: "registration/submit",
    };
  },
  methods: {
    sendMail() {
      this.$emit("update:email_send_status", "sending");
      let mail_body = this.user_details;
      let ep = "";
      mail_body["lang"] = this.returnLanguage;

      if (this.mail_type == "Register") ep = "registration/submit";
      if (this.mail_type == "Contact Sales") ep = "contact/sales";

      console.log("user details: ", mail_body);
      console.log("CURRENT LANGUAGE: ", this.returnLanguage);
      this.getToken()
        .then((res) => {
          axios
            .post(this.siteConfig.e_p + ep, mail_body, {
              params: {
                init_token: res,
              },
            })
            .then((res) => {
              this.$emit("update:email_send_status", "success");
              this.$emit("update:should_send_email", false);
              console.log("send mail result: ", res);
            })
            .catch((err) => {
              this.$emit("update:email_send_status", "failed");
              console.log("send mail error: ", err);
            });
        })
        .catch((err) => {
          console.log("error: ", err);
          this.$emit("update:email_send_status", "failed");
        });
      this.$emit("update:should_send_email", false);
    },
    getToken() {
      return new Promise((resolve, reject) => {
        load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
          (recaptcha) => {
            recaptcha
              .execute("get")
              .then((token) => {
                resolve(token);
              })
              .catch((error) => {
                reject(error);
              });
          }
        );
      });
    },
  },
  computed: {
    ...mapGetters(["siteConfig", "language"]),
    returnSiteConfig() {
      return this.siteConfig;
    },

    returnLanguage() {
      return this.language.toLowerCase();
    },
  },
  watch: {
    should_send_email(val) {
      if (val) {
        console.log("EMAIL SENDING", val);
        this.sendMail();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>