<template>
  <div class="row mx-0 px-0 py- nav-bar align-items-center">
    <div class="col-6 d-flex align-items-center title">
      <span class="material-icons-round pointer" @click="goBackHome()">
        arrow_back_ios
      </span>
      <span class="poppinsBold py-0">
        Indoinfo | <span class="poppinsRegular mr-2">events</span
        ><span class="text-gradient">{{ new Date().getFullYear() }}</span>
      </span>
    </div>
    <div class="col-6 d-flex align-items-center justify-content-end">
      <div class="search-container poppinsRegular" v-if="$screen.width > 700">
        <input
          type="text"
          id="searchinput"
          v-model="search_input"
          :placeholder="returnPlaceholder"
        />
      </div>
      <div
        class="ml-2 d-flex align-items-center lang_container"
        @click="drop_lang_container = !drop_lang_container"
      >
        <span class="material-icons-round class-mx-1"> translate </span>
        <span class="mx-1">{{ returnCurrentLanguage }}</span>
        <span
          class="material-icons-round class-mx-1"
          :class="{
            more: drop_lang_container,
            less: !drop_lang_container,
          }"
        >
          expand_more
        </span>
      </div>
    </div>
    <div class="carddraw poppinsBold" v-if="drop_lang_container">
      <ul>
        <li
          v-for="(lang, index) in langs"
          :key="index + '-lang'"
          :class="{ 'text-gradient': lang.code === returnCurrentLanguage }"
          @click="changeLanguage(lang.code)"
        >
          {{ lang.name }}
        </li>
        <li>
          {{ search_input }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    search_query: {
      type: String,
    },
  },
  data() {
    return {
      langs: [
        {
          code: "EN",
          name: "English",
        },
        {
          code: "ID",
          name: "Bahasa Indonesia",
        },
      ],
      drop_lang_container: false,
      search_placeholder_en: "Search events",
      search_placeholder_id: "Cari acara",
      search_input: "",
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$store.dispatch("CHANGE_LANGUAGE", lang);
      this.drop_lang_container = false;
    },
    pushSearchToParent() {
      console.log("search", this.search_input);
      this.$emit("update:search_query", this.search_input);
    },
    goBackHome() {
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["siteConfig", "language"]),
    returnCurrentLanguage() {
      return this.language;
    },
    returnPlaceholder() {
      return this.language === "EN"
        ? this.search_placeholder_en
        : this.search_placeholder_id;
    },
  },
  watch: {
    search_input: function (val) {
      console.log("search input", val);
      this.pushSearchToParent();
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  background: rgb(255, 255, 255);
  height: 60px;
  box-shadow: 1px 0px 90px 1px rgba(0, 81, 180, 0.51);
  position: fixed;
  width: 100%;
  z-index: 99;
  font-size: 3vw;
}

.material-icons-round {
  font-size: 3.5vw !important;
}
/* Extra small devices (phones, 600px and down) */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .nav-bar {
    font-size: 3vw !important;
  }
  .material-icons-round {
    font-size: 2vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .nav-bar {
    font-size: 2vw !important;
  }
  .material-icons-round {
    font-size: 2vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .nav-bar {
    font-size: 2vw !important;
  }
  .material-icons-round {
    font-size: 2vw !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .nav-bar {
    font-size: 1vw !important;
  }
  .material-icons-round {
    font-size: 1.7vw !important;
  }
}

.text-gradient {
  background: rgb(57, 0, 138);
  background: linear-gradient(
    30deg,
    rgba(0, 82, 180, 1) 30%,
    rgb(104, 0, 250) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.search-container {
  input {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 10px;
    width: 250px;
    height: 40px;
  }
  input#searchinput {
    background-color: #f9f9f9 !important;
    box-shadow: 1px 0px 5px 1px rgba(90, 90, 90, 0.25) inset,
      0 0 1px rgb(9, 9, 9) !important;
    border: none !important;
    border-radius: 40px !important;
  }

  input#searchinput:focus {
    //   background-color: rgb(255, 255, 255) !important;
  }

  input#searchinput::placeholder {
    color: rgb(88, 88, 88) !important;
  }
}

.lang_container {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 10px;
  height: 40px;
  border-radius: 40px !important;
  cursor: pointer;
}

.lang_container:hover {
  background-color: #f4f4f4 !important;
}

.carddraw {
  position: absolute;
  background-color: #fff;
  right: 15px;
  top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  ul {
    list-style: none;
    padding: 0px;
    li {
      padding: 5px;
      cursor: pointer;
    }
    li:hover {
      background: rgb(57, 0, 138);
      background: linear-gradient(
        30deg,
        rgba(0, 82, 180, 1) 30%,
        rgb(104, 0, 250) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.more {
  transition: all 250ms ease-in-out;
  transform: rotate(180deg);
}
.less {
  transition: all 250ms ease-in-out;
  transform: rotate(0deg);
}
</style>
