<template>
  <div
    class="container d-flex align-items-center"
    style="margin-top: 10vh; padding-bottom: 60px"
  >
    <div class="row">
      <!-- SECTION: PAGE TITLE -->
      <div class="col-12 d-flex justify-content-center px-3">
        <h2 class="text-left openSansBold">
          {{ left_splitted_header
          }}<span class="emphasis">{{ outlined_header }}</span
          >{{ right_splitted_header }}
        </h2>
      </div>
      <!-- !SECTION -->

      <!-- SECTION: SECTION SELECTIONS -->
      <!-- Select -->
      <div
        class="col-12 mt-5 px-0 mx-0 d-flex justify-content-center"
        style="width: 150%"
        v-if="$screen.lg"
      >
        <span
          class="openSansMedium px-3 pills text-center"
          :class="{ active: selection.id === current_selection.id }"
          v-for="selection in selections"
          :key="selection.id"
          @click="ChangeMenu(null, selection)"
        >
          {{ selection.name }}
        </span>
      </div>
      <div class="col-12 d-flex justify-content-center" v-if="!$screen.lg">
        <!-- mobile select -->
        <custom-drop-down
          :current_selection.sync="current_selection"
          :drop_menu.sync="drop_menu"
          :selections.sync="selections"
          @update="current_selection, drop_menu, selections"
        />
      </div>
      <!-- !SECTION -->

      <!-- SECTION: IMAGE BANNER -->
      <!-- Image Card -->
      <!--  v-if="!is_popular_available" -->
      <div class="col-12 py-2" v-if="!isSmallCard">
        <div
          class="row image_card_bg"
          style="border: none"
          v-bind:style="{ 'background-image': 'url(' + image_card_bg + ')' }"
        >
          <div
            class="col-12 d-flex align-items-center d-flex justify-content-center"
            style="height: 100%"
          >
            <div
              class="card"
              style="width: 100%; background: none; border: none"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6"></div>
                  <div class="col-12 col-md-6">
                    <ul
                      class="facts"
                      v-for="(fact, index) in returnFeaturesDescription[0]
                        .points"
                      :key="'image-card-facts' + index"
                      style="color: #545465"
                    >
                      <li class="d-flex align-items-start">
                        <span
                          class="material-icons-round mr-3"
                          style="color: #2c8cff"
                          >verified</span
                        >
                        {{ fact }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- !SECTION -->
      <!-- SECTION: CARDS -->
      <!-- Cards -->
      <div class="col-12">
        <div class="row justify-content-center">
          <div
            class="col-12 mt-3 px-3"
            v-for="(data, index) in returnArray"
            :key="index + 'cards' + Math.random()"
            :class="{
              'col-md-auto': isSmallCard,
              'col-md-12': !isSmallCard,
            }"
            :style="`${isSmallCard ?  'width: 13em !important' : ''}`"
          >
            <div class="features-card p-4">
              <!-- SECTION: SHOW POPULAR CARDS -->
              <vs-tooltip primary bottom>
                <div class="row" v-if="isSmallCard" style="color: #545465">
                  <!-- ICONS -->
                  <div
                    @click="openVideo(data.id, data.clickable)"
                    class="col-12 d-flex justify-content-center align-items-center"
                    style="padding-top: 20px"
                  >
                    <img :src="data.img" class="small-icons" />
                  </div>
                  <!-- END OF ICONS -->
                  <!-- PRODUCT NAME -->
                  <div
                    class="col-12 d-flex justify-content-center mt-3 text-center align-items-center"
                  >
                    <h6 class="openSansMedium" style="font-size: 13px">
                      {{ data.name }}
                    </h6>
                  </div>
                  <!-- PRODUCT NAME -->
                </div>
                <template #tooltip>
                  <p style="max-width: 180px">
                    <!-- <span class="openSansBold">
                      {{ data.name }}
                    </span>
                    <br /> -->
                    <span
                      v-if="
                        data.tooltip_desc !== undefined ||
                        data.tooltip_desc !== ''
                      "
                    >
                      {{ data.tooltip_desc }}
                    </span>
                  </p>
                </template>
              </vs-tooltip>
              <!-- !SECTION: END OF SHOW POPULAR CARDS -->

              <!-- SECTION: SHOW SPECIFIC CATEGORIES CARDS -->
              <div class="row categories">
                <div
                  class="col-md-10 col-12 order-1 order-md-0 categories-text"
                  v-if="!isSmallCard"
                >
                  <div class="row" style="color: #545465">
                    <div class="col-12 d-flex py-3">
                      <h6 class="openSansSemiBold c-primary">
                        {{ data.name }}
                      </h6>
                      <h6
                        class="openSansMedium"
                        v-if="data.short_desc !== undefined"
                      >
                        {{ data.short_desc }}
                      </h6>
                    </div>
                    <div
                      class="col-12 mt-0 py-2"
                      v-if="data.title !== undefined"
                    >
                      <h4 class="openSansMedium">{{ data.title }}</h4>
                    </div>
                    <div class="col-12" v-if="data.description !== undefined">
                      <p class="">{{ data.description }}</p>
                    </div>
                    <div class="col-12" v-if="data.facts !== undefined">
                      <ul
                        class="facts"
                        v-for="(fact, index) in data.facts"
                        :key="index + 'facts' + Math.random()"
                      >
                        <li class="d-flex align-items-start">
                          <span
                            class="material-icons-round mr-3"
                            style="color: #2c8cff"
                            >verified</span
                          >{{ fact }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-12" v-if="data.buttons !== undefined">
                      <div class="row">
                        <div
                          class="col-12 col-md-4 py-2 py-md-0 d-flex justify-content-start"
                          v-for="(button, index) in data.buttons"
                          :key="index + 'CTABTN'"
                        >
                          <button
                            class="btn btn-block btn-md"
                            :class="[
                              button.style === 'btn-primary'
                                ? 'btn-primary'
                                : 'btn-outline-primary',
                            ]"
                            @click="openLink(button.type, button.link)"
                          >
                            {{ button.text }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!isSmallCard"
                  class="col-md-2 col-12 order-0 order-md-1 cateogries-images d-flex justify-content-center align-items-md-center"
                >
                  <img
                    :src="data.img"
                    alt=""
                    @click="openVideo(data.id, data.clickable)"
                    class="icons"
                  />
                </div>
              </div>
              <!-- !SECTION END OF SHOW SPECIFIC CATEGORIES CARDS -->
            </div>
          </div>
        </div>
      </div>
      <!-- !SECTION -->
    </div>
  </div>
</template>

<script>
import software_sample1 from "@/assets/sample/sampleSoftware1.png";
import software_sample2 from "@/assets/sample/sampleSoftware2.png";
import CustomDropDown from "@/components/utils/CustomDropDown.vue";
import image_card_background from "@/assets/bgs/image_card_bg.png";
export default {
  components: {
    CustomDropDown,
  },
  props: {
    header: String,
    outlined_text: String,
    features: Array,
    features_description: Array,
    // should show popular
    is_popular_available: Boolean,
    // misc
    open_video: Boolean,
    open_video_pid: String, //product_id
    default_selections: Array,
    current_language: String,
  },
  data() {
    return {
      image_card_bg: image_card_background,
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",

      // test images
      img_sample1: software_sample1,
      img_sample2: software_sample2,
      drop_menu: false,
      current_selection: {
        id: "Popular",
        name: "Popular",
      },
      selections: [],
    };
  },

  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
      } else {
        console.log("overview feature missing/could not find outlined text.");
      }
    },
    alterSelectionOptions() {
      const self = this;
      if (this.is_popular_available) {
        this.selections = this.default_selections;
      }

      if (!this.is_popular_available) {
        this.selections = filterIsPopular();
        this.current_selection = filterIsPopular()[0];
        console.log("popular has been filtered: ", filterIsPopular());
        console.log("popular has been filtered2: ", filterIsPopular()[0]);

        function filterIsPopular() {
          var x = self.lodash.filter(self.default_selections, function (o) {
            return o.id !== "Popular";
          });
          return x;
        }
      }
    },
    ChangeMenu(type, selected) {
      let self = this;
      if (type === "drop-menu") {
        self.drop_menu = !self.drop_menu;
      }

      if (selected !== undefined) {
        self.current_selection = selected;
      }
    },
    openVideo(vid_id, is_clickable) {
      console.log("is clickable? ", is_clickable);
      console.log("clicked open video", vid_id);
      if (is_clickable) {
        this.$emit("update:open_video_pid", vid_id);
        this.$emit("update:open_video", true);
      }
    },
    goToLink() {
      this.$router.push("/team-support");
    },
    openRegisterDialog(link) {
      var x = {
        state: true,
        tab: link,
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
    openLink(type, link) {
      if (type === "dialog") {
        var x = {
          state: true,
          tab: link,
        };
        this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
      }
      if (type === "view-pdf") {
        window.open(link, "_blank", "fullscreen=yes");
      }
    },
  },
  computed: {
    returnFeaturesDescription() {
      // filter and search  features_description if name is equal to current_selection.name
      const self = this;
      const filtered = self.lodash.filter(
        self.features_description,
        function (o) {
          return o.name === self.current_selection.name;
        }
      );
      // return filtered[0].description;
      return filtered;
    },
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },

    returnArray() {
      let self = this;
      if (this.current_selection.id !== "Popular") {
        var x = this.lodash.filter(self.features, function (o) {
          return o.cat === self.current_selection.id;
        });
        return x;
      } else {
        var x = this.lodash.filter(self.features, function (o) {
          return o.popular === true;
        });
        return x;
      }
    },

    isSmallCard() {
      // if popular is available
      if (this.is_popular_available) {
        // if current selection is not equal to popular
        if (this.current_selection.id !== "Popular") {
          // return false (this is not a small card)
          return false;
        } else {
          // else if popular is available and current selection is equal to popular small card show
          return true;
        }
      } else {
        // if popular card is unavailable all card should be small
        console.log("is popular: ", this.is_popular_available);
        console.log(
          "is popular not current popular tab: ",
          this.current_selection.id !== "Popular"
        );
        return true;
      }
    },
  },
  watch: {
    current_language() {
      this.CutandDump();
      this.current_selection = {
        id: "Popular",
        name: "Popular",
      };
      this.alterSelectionOptions();
    },
  },
  mounted() {
    this.CutandDump();

    this.alterSelectionOptions();
  },
};
</script>

<style scoped lang="scss">
.icons {
  height: 100px;
  max-height: 100px;
  width: auto;
  min-height: 100px;
}

.small-icons {
  height: 45px;
  max-height: 45px;
  width: auto;
  min-height: 45px;
}

.pills {
  font-size: 15px;
  color: #4b4b4b;
  cursor: pointer;
  border-bottom: -50;
  -webkit-transition: all 50ms ease-in-out;
  -moz-transition: all 50ms ease-in-out;
  -o-transition: all 50ms ease-in-out;
  transition: all 50ms ease-in-out;
  border-left: 1px solid rgb(195, 195, 195);
}

.pills:first-child {
  border-left: none;
}

.active {
  color: #0052b4;
  font-weight: 500;
  border-bottom: 5px solid #0052b4;
}

img {
  width: 100%;
  max-width: 500px;
  height: auto;
}
.features-card {
  // background-color: #f2f7ff;
  border: none;
  cursor: pointer;
  transition: transform ease 300ms;
  box-shadow: 0px 0px 10px #0000001f;
  border-radius: 10px;
  font-size: 15px;
  min-height: 180px;
  min-width: 100%;
  max-width: 200px;
}

.features-card:hover {
  transform: translate(0, -10px);
  box-shadow: 5px -5px 10px #0000001f;
}

.facts {
  list-style: none;
}

.image_card_bg {
  min-height: 300px !important;
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
