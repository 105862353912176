<template>
  <div class="custom-drop-down" style="min-width: 100%">
    <div
      class="d-flex justify-content-center dropdown-current-item"
      @click="ChangeMenu('drop-menu')"
    >
      {{ current_selection.name }}
      <span class="material-icons-round gray" :class="{ rotate: drop_menu }"
        >expand_more</span
      >
    </div>
    <div class="row px-0 mx-0">
      <div
        class="col-12 d-flex justify-content-center mx-0 px-0"
        v-if="drop_menu"
      >
        <ul :class="{ 'dropdown-active': drop_menu }" class="">
          <li
            :class="{ active: selection.id === current_selection.id }"
            class="text-center openSansMedium"
            v-for="selection in selections"
            :key="selection.id + 'drop'"
            @click="ChangeMenu('drop-menu', selection)"
          >
            {{ selection.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    drop_menu: Boolean,
    selections: Array,
    current_selection: Object,
  },
  data() {
    return {
      dropdown_active: false,
    };
  },
  methods: {
    ChangeMenu(type, selected) {
      if (type === "drop-menu") {
        this.$emit("update:drop_menu", !this.drop_menu);
      }

      if (selected !== undefined) {
        this.$emit("update:current_selection", selected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-active {
  position: absolute;
  z-index: 9;
  width: 100%;
}
.custom-drop-down {

  .dropdown-current-item {
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    max-width: 100%;
    .rotate {
      transform: rotate(180deg);
    }
  }
  ul {
    list-style: none;
    padding: 0px;
    margin-top: 2px;
    box-shadow: 5px 5px 10px #0000001f;
    li {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 100px;
      padding-left: 100px;
      background: #fff;
      border: 1px solid #e9e9e9;
      cursor: pointer;
    }
    li:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    li:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    li:hover {
      color: #0052b4;
    }
    li.active {
      background-color: #0052b4;
      color: #fff;
    }
  }
}
</style>