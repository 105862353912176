<template>
  <div
    class="container-fluid px-0 mx-0"
    style="background-color: #f9f9f9; padding-bottom: 50px; padding-top: 50px"
  >
    <div class="container">
      <div class="row px-2 mx-0 align-self-center">
        <div class="col-12 d-flex justify-content-center text-center ">
          <h2 class="openSansBold">
            {{ left_splitted_header
            }}<span class="emphasis">{{ outlined_header }}</span
            >{{ right_splitted_header }}
          </h2>
        </div>
        <div class="col-12 d-flex justify-content-center"></div>
      </div>
      <div class="col-sm-12 px-0 col-lg-4" v-if="!$screen.lg">
        <custom-drop-down
          :current_selection.sync="currentoption"
          :drop_menu.sync="drop_menu"
          :selections.sync="whychooseoptions"
          @update="current_selection, drop_menu, whychooseoptions"
        />
      </div>

      <div class="row mx-0 px-0 px-md-5 d-flex align-items-center mt-md-5">
        <div
          class="col-sm-12 col-lg-4 d-flex justify-content-start"
          v-if="$screen.lg"
        >
          <div class="row">
            <div
              class="
                col col-lg-12
                tabs
                d-flex
                align-items-center
                justify-content-center
              "
              v-for="(option, index) in whychooseoptions"
              :key="index + 'optionswhy'"
              :class="{ active: option.id === currentoption.id }"
              @click="loadCurrent(option, index)"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-8 d-flex justify-content-start mt-3 mt-md-4">
          <div class="row mx-0 px-0">
            <div class="col-12 mx-0 px-0">
              <h1 class="text-center text-md-start">{{ currentoption.header }}</h1>
            </div>
            <div class="col-12 mx-0 px-0">
              <p class="text-center text-md-start">
                {{ currentoption.desc }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomDropDown from "@/components/utils/CustomDropDown.vue";
export default {
  components: {
    CustomDropDown,
  },
  props: {
    header: String,
    outlined_text: String,
    description: String,
    whychooseoptions: Array,
    current_language: String,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      currentoption: {},
      current_index: "",
      drop_menu: false,
    };
  },
  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("could not find");
      }
    },
    loadCurrent(data) {
      if (data === null) {
        this.currentoption = this.whychooseoptions[0];
        console.log("test", this.currentoption);
      } else {
        this.currentoption = data;
        console.log("test2", this.currentoption);
      }
    },
  },
  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    current_language() {
      this.CutandDump();
      this.loadCurrent(null);
    },
  },
  mounted() {
    this.loadCurrent(null);
    this.CutandDump();
  },
};
</script>

<style scoped>
.tabs {
  width: 100%;
  color: #a3a3a3;
  height: 50px;
  cursor: pointer;
  transition: transform ease 300ms;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tabs {
    border-bottom: 4px solid #c4c4c4;
  }
  .tabs.active {
    color: #0052b4;
    border-bottom: 4px solid #0052b4;
    font-weight: 700;
  }

  .tabs:hover {
    color: #0052b4;
    border-bottom: 4px solid #0052b4;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tabs {
    border-bottom: 4px solid #c4c4c4;
  }
  .tabs.active {
    color: #0052b4;
    border-bottom: 4px solid #0052b4;
    font-weight: 700;
  }

  .tabs:hover {
    color: #0052b4;
    border-bottom: 4px solid #0052b4;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .tabs {
    border-left: 4px solid #c4c4c4;
    border-bottom: none;
  }
  .tabs.active {
    color: #0052b4;
    border-left: 4px solid #0052b4;
    border-bottom: none;
    font-weight: 700;
  }

  .tabs:hover {
    color: #0052b4;
    border-bottom: none;
    border-left: 4px solid #0052b4;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .tabs {
    border-left: 4px solid #c4c4c4;
    border-bottom: none;
  }
  .tabs.active {
    color: #0052b4;
    border-left: 4px solid #0052b4;
    border-bottom: none;
    font-weight: 700;
  }

  .tabs:hover {
    color: #0052b4;
    border-left: 4px solid #0052b4;
    border-bottom: none;
    background-color: #ebf4ffa8;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .tabs {
    border-left: 4px solid #c4c4c4;
    border-bottom: none;
  }
  .tabs.active {
    color: #0052b4;
    border-left: 4px solid #0052b4;
    border-bottom: none;
    font-weight: 700;
  }

  .tabs:hover {
    color: #0052b4;
    border-left: 4px solid #0052b4;
    border-bottom: none;
    background-color: #ebf4ffa8;
  }
}
</style>