export default {
    namespaced: true,
    state: {
        english: {
            reason_section: {
                header: 'One Single Source of Business Information on Almost 1 Million Indonesian Companies',
                outlined_text: '1 Million',
            }
        },
        // indonesian
        indonesian: {
            banner_section: {
                title: 'Kebijakan memberi keputusan bisnis berdasarkan informasi',
                description: 'Kami menyediakan informasi, resiko dan solusi yang sesuai dengan rintangan bisnis yang sedang dihadapi bisnis Anda'
            },
            hero_cta_section: {
                header: 'Analisa Keputusan Bisnis yang Berdasarkan Data adalah Keputusan yang Berkualitas',
                buttons: [
                    {
                        text: 'Hubungi Sales',
                        style: 'btn-primary',
                        link: 'Contact Sales'
                    },
                ]
            },
        },
    },
    getters: {
        english: s => s.english,
        indonesian: s => s.indonesian
    }
}