<template>
  <div
    class="analyst-container"
    @mouseenter="semiExpand = true"
    @mouseleave="semiExpand = false"
    :class="[{ expanded: expanded, 'semi-expand': semiExpand }]"
  >
    <div class="bg" v-bind:style="{ 'background-image': 'url(' + bg + ')' }">
      <div class="row px-0 mx-0">
        <!-- header -->
        <div
          class="commentary_header col-12 my-0 poppinsBold d-flex justify-content-center"
        >
          <div class="row" style="width: 100%">
            <div class="col-md-2 col-0"></div>
            <div class="col-md-8 col-10 d-flex justify-content-center">
              <div class="row">
                <div class="col-12">
                  <span
                    class="text-gradient text-center"
                    style="text-transform: uppercase"
                  >
                    Indoinfo Business Insights
                  </span>

                  <small
                    class="new px-2 poppinsMedium ml-1"
                    v-if="returnAllCurrentMonthCount > 0"
                  >
                    +{{ returnAllCurrentMonthCount }}
                    <span class="poppinsItalic">New !</span>
                  </small>
                </div>
              </div>
            </div>

            <div class="col-2 d-flex justify-content-end">
              <span
                class="material-icons-round text-gradient pointer"
                @click="expanded = true"
                v-if="!expanded"
              >
                expand
              </span>
              <span
                class="material-icons-round text-gradient pointer"
                @click="expanded = false"
                v-if="expanded"
              >
                minimize
              </span>
            </div>
          </div>
        </div>
        <!-- body -->
        <div
          class="col-12 my-0 py-0 px-0 mx-0"
          v-if="returnCommentaries.length > 0"
        >
          <!-- 3D SCROLLER -->
          <carousel-3d
            v-if="!expanded && !openDetails"
            :disable3d="true"
            :space="335"
            :clickable="false"
            :controls-visible="true"
            startIndex="1"
            height="300px"
            style="margin: 0px !important"
          >
            <slide v-for="(slide, i) in returnCommentaries" :key="i" :index="i">
              <div
                class="content-container pointer"
                @click="setActiveComment(slide)"
              >
                <span class="c-primary title poppinsBold capitalize">
                  {{ slide.category_comment_description }}
                </span>
                <span v-if="slide.is_current_month">
                  <small class="new poppinsRegular">New !</small>
                </span>
                <div class="c-primary capitalize">
                  <small class="poppinsRegular">
                    {{ slide.category_comment }}
                  </small>
                </div>
                <!-- <div>
                  <small class="poppinsMedium" style="color: rgb(89, 89, 89)">
                    Written by: {{ slide.ba_name }}
                  </small>
                </div> -->
                <div>
                  <span>
                    <small class="poppinsMedium" style="color: rgb(89, 89, 89)">
                      {{ slide.date_from }}
                    </small>
                  </span>
                </div>
                <div class="comment-container">
                  <span class="comments" v-html="slide.comments"></span>
                </div>
                <div
                  class="d-flex"
                  v-for="(sic, index) in slide.SIC.slice(0, 1)"
                  :key="'sic-text' + index"
                >
                  <vs-button gradient>
                    <small
                      class="poppinsMedium capitalize"
                      style="line-height: 1"
                    >
                      {{ sic.sic_description }}
                    </small>
                  </vs-button>
                  <vs-tooltip primary>
                    <vs-button gradient v-if="slide.SIC.length > 1">
                      <small
                        class="poppinsSemiBold text-left capitalize"
                        style="line-height: 1"
                      >
                        {{ slide.SIC.length - 1 }}<span>+</span>
                      </small>
                    </vs-button>
                    <template #tooltip>
                      <p
                        v-for="(sic_inner, index) in slide.SIC.slice(
                          1,
                          slide.SIC.length
                        )"
                        :key="'sic-text' + index"
                        class="capitalize my-0 py-0 d-flex align-items-start text-left"
                      >
                        <span
                          class="material-icons-round mr-2 mt-1"
                          style="font-size: 10px"
                          >label</span
                        >
                        {{ sic_inner.sic_description }}.
                      </p>
                    </template>
                  </vs-tooltip>
                </div>
              </div>
            </slide>
          </carousel-3d>
          <!-- END OF 3D SCROLLER -->

          <!-- NO DETAILS EXPANDED  -->
          <div class="row mx-0 py-3 body" v-if="expanded && !openDetails">
            <div
              class="col-12 col-md-4"
              v-for="(slide, i) in returnCommentaries"
              :key="i"
              :index="i"
              @click="setActiveComment(slide)"
            >
              <div
                class="card my-2 pointer"
                style="border: none; overflow-y: hidden"
              >
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <span
                      class="c-primary title poppinsBold capitalize py-0 my-0"
                    >
                      {{ slide.category_comment_description }}

                      <span v-if="slide.is_current_month">
                        <small class="new poppinsRegular">New !</small>
                      </span>
                    </span>
                  </div>

                  <div class="c-primary capitalize">
                    <small class="poppinsRegular">
                      {{ slide.category_comment }}
                    </small>
                  </div>
                  <!-- <div class="py-0 my-0">
                    <small
                      class="poppinsMedium py-0 my-0"
                      style="color: rgb(89, 89, 89)"
                    >
                      Written by: {{ slide.ba_name }}
                    </small>
                  </div> -->
                  <div>
                    <span>
                      <small
                        class="poppinsMedium"
                        style="color: rgb(89, 89, 89)"
                      >
                        {{ slide.date_from }}
                      </small>
                    </span>
                  </div>
                  <div class="comment-container">
                    <span class="comments" v-html="slide.comments"></span>
                  </div>
                  <div
                    class="d-flex"
                    v-for="(sic, index) in slide.SIC.slice(0, 1)"
                    :key="'sic-text' + index"
                  >
                    <vs-button gradient>
                      <small
                        class="poppinsRegular text-left capitalize"
                        style="line-height: 1"
                      >
                        {{ sic.sic_description }}
                      </small>
                    </vs-button>

                    <vs-tooltip primary>
                      <vs-button gradient v-if="slide.SIC.length > 1">
                        <small
                          class="poppinsSemiBold text-left capitalize"
                          style="line-height: 1"
                        >
                          {{ slide.SIC.length - 1 }}<span>+</span>
                        </small>
                      </vs-button>
                      <template #tooltip>
                        <p
                          v-for="(sic_inner, index) in slide.SIC.slice(
                            1,
                            slide.SIC.length
                          )"
                          :key="'sic-text' + index"
                          class="capitalize my-0 py-0 d-flex align-items-start text-left"
                        >
                          <span
                            class="material-icons-round mr-2 mt-1"
                            style="font-size: 10px"
                            >label</span
                          >
                          {{ sic_inner.sic_description }}.
                        </p>
                      </template>
                    </vs-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF NO DETAILS EXPANDED -->

          <!-- DETAILS EXPANDED  -->
          <div class="row mx-0 py-3" v-if="expanded && openDetails">
            <!-- left pane -->
            <div
              class="col-12 col-md-8 left-pane px-3"
              style="background: #fff"
            >
              <div class="row py-2" ref="closeButton">
                <div class="col-12 d-flex justify-content-end">
                  <span
                    @click="closeDetails()"
                    class="material-icons-round pointer"
                    style="color: rgb(128, 128, 128)"
                    >close</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <h1 class="poppinsBold c-primary capitalize text-center">
                        {{ activeComment.category_comment_description }}
                      </h1>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                      <h5 class="poppinsBold c-primary capitalize text-center">
                        {{ activeComment.category_comment }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <vs-button
                    gradient
                    v-for="(sic, index) in activeComment.SIC"
                    :key="index"
                  >
                    <span class="capitalize poppinsMedium">
                      {{ sic.sic_description }}
                    </span>
                  </vs-button>
                </div>
                <!-- <div
                  class="
                    my-3
                    pt-3
                    col-6
                    d-flex
                    justify-content-center justify-content-md-start
                    poppinsMedium
                  "
                  style="
                    border-top: 1px solid rgb(128, 128, 128);
                    border-bottom: 1px solid rgb(128, 128, 128);
                  "
                >
                  <p class="poppinsRegular" style="color: rgb(89, 89, 89)">
                    Written by: {{ activeComment.ba_name }}
                  </p>
                </div> -->
                <div
                  class="my-3 pt-3 col-12 d-flex justify-content-center poppinsMedium text-right"
                  style="
                    border-top: 1px solid rgb(128, 128, 128);
                    border-bottom: 1px solid rgb(128, 128, 128);
                    color: rgb(89, 89, 89);
                    white-space: break-spaces;
                  "
                >
                  <p class="poppinsRegular" style="color: rgb(89, 89, 89)">
                    {{ activeComment.date_from }}
                  </p>

                  <small
                    class="new poppinsRegular ml-2 d-flex align-items-center"
                    v-if="activeComment.is_current_month"
                    >New !</small
                  >
                </div>

                <div class="col-12">
                  <span v-html="activeComment.comments"></span>
                </div>
                <div class="col-12 mx-0 px-0" v-if="!$screen.md">
                  <carousel-3d
                    :disable3d="true"
                    :space="335"
                    :clickable="false"
                    :controls-visible="true"
                    height="300px"
                    style="margin: 0px !important"
                  >
                    <slide
                      v-for="(slide, i) in returnCommentariesOtherThanActive"
                      :key="i"
                      :index="i"
                    >
                      <div
                        class="content-container pointer"
                        @click="setActiveComment(slide)"
                      >
                        <span class="c-primary title poppinsBold capitalize">
                          {{ slide.category_comment_description }}
                        </span>
                        <span v-if="slide.is_current_month">
                          <small class="new poppinsRegular">New !</small>
                        </span>
                        <div class="c-primary capitalize">
                          <small class="poppinsRegular">
                            {{ slide.category_comment }}
                          </small>
                        </div>
                        <!-- <div>
                          <small
                            class="poppinsMedium"
                            style="color: rgb(89, 89, 89)"
                          >
                            Written by: {{ slide.ba_name }}
                          </small>
                        </div> -->
                        <div>
                          <span>
                            <small
                              class="poppinsMedium"
                              style="color: rgb(89, 89, 89)"
                            >
                              {{ slide.date_from }}
                            </small>
                          </span>
                        </div>
                        <div class="comment-container">
                          <span class="comments" v-html="slide.comments"></span>
                        </div>
                        <div
                          class="d-flex"
                          v-for="(sic, index) in slide.SIC.slice(0, 1)"
                          :key="'sic-text' + index"
                        >
                          <vs-button gradient>
                            <small class="poppinsBold" style="line-height: 1">
                              {{ sic.sic_description }}
                            </small>
                          </vs-button>
                          <vs-tooltip primary>
                            <vs-button gradient v-if="slide.SIC.length > 1">
                              <small
                                class="poppinsSemiBold text-left capitalize"
                                style="line-height: 1"
                              >
                                {{ slide.SIC.length - 1 }}<span>+</span>
                              </small>
                            </vs-button>
                            <template #tooltip>
                              <p
                                v-for="(sic_inner, index) in slide.SIC.slice(
                                  1,
                                  slide.SIC.length
                                )"
                                :key="'sic-text' + index"
                                class="capitalize my-0 py-0 d-flex align-items-start text-left"
                              >
                                <span
                                  class="material-icons-round mr-2 mt-1"
                                  style="font-size: 10px"
                                  >label</span
                                >
                                {{ sic_inner.sic_description }}.
                              </p>
                            </template>
                          </vs-tooltip>
                        </div>
                      </div>
                    </slide>
                  </carousel-3d>
                </div>
              </div>
            </div>
            <!-- right pane -->
            <div class="col-12 col-md-4 right-pane" v-if="$screen.md">
              <div class="row">
                <div class="col-12 poppinsMedium">
                  <h4 class="text-gradient poppinsMedium">
                    Other Commentaries
                  </h4>
                </div>
                <div
                  class="col-12"
                  v-for="(slide, i) in returnCommentariesOtherThanActive"
                  :key="i"
                  :index="i"
                  @click="setActiveComment(slide)"
                >
                  <div
                    class="card my-2 pointer"
                    style="border: none; overflow-y: hidden"
                  >
                    <div class="card-body">
                      <span
                        class="c-primary title poppinsBold capitalize py-0 my-0"
                      >
                        {{ slide.category_comment_description }}
                      </span>
                      <span v-if="slide.is_current_month">
                        <small class="new poppinsRegular">New !</small>
                      </span>
                      <div class="c-primary capitalize">
                        <small class="poppinsRegular">
                          {{ slide.category_comment }}
                        </small>
                      </div>
                      <!-- <div class="py-0 my-0">
                        <small
                          class="poppinsMedium py-0 my-0"
                          style="color: rgb(89, 89, 89)"
                        >
                          Written by: {{ slide.ba_name }}
                        </small>
                      </div> -->
                      <span>
                        <small
                          class="poppinsMedium"
                          style="color: rgb(89, 89, 89)"
                        >
                          {{ slide.date_from }}
                        </small>
                      </span>
                      <div class="comment-container">
                        <span class="comments" v-html="slide.comments"></span>
                      </div>
                      <div
                        class="d-flex"
                        v-for="(sic, index) in slide.SIC.slice(0, 1)"
                        :key="'sic-text' + index"
                      >
                        <vs-button gradient>
                          <small
                            class="poppinsRegular text-left capitalize"
                            style="line-height: 1"
                          >
                            {{ sic.sic_description }}
                          </small>
                        </vs-button>

                        <vs-tooltip primary>
                          <vs-button gradient v-if="slide.SIC.length > 1">
                            <small
                              class="poppinsSemiBold text-left capitalize"
                              style="line-height: 1"
                            >
                              {{ slide.SIC.length - 1 }}<span>+</span>
                            </small>
                          </vs-button>
                          <template #tooltip>
                            <p
                              v-for="(sic_inner, index) in slide.SIC.slice(
                                1,
                                slide.SIC.length
                              )"
                              :key="'sic-text' + index"
                              class="capitalize my-0 py-0 d-flex align-items-start text-left"
                            >
                              <span
                                class="material-icons-round mr-2 mt-1"
                                style="font-size: 10px"
                                >label</span
                              >
                              {{ sic_inner.sic_description }}.
                            </p>
                          </template>
                        </vs-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF DETAILS EXPANDED -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Images
import axios from "axios";
import bg from "@/assets/bgs/bg_ga_comment.png";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
import { dragscroll } from "vue-dragscroll";
import moment from "moment";
export default {
  directives: {
    dragscroll,
  },
  data() {
    return {
      bg: bg,
      expanded: false,
      semiExpand: false,
      openDetails: false,
      activeComment: {},
      endpoint: `ba-comment/active`,
      commentaries: [],
      slides: 7,
    };
  },
  methods: {
    getData() {
      const self = this;
      load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              console.log("COMPANY TOKEN", token);
              axios
                .get(`${self.returnSiteConfig.e_p}${self.endpoint}`, {
                  params: {
                    init_token: token,
                  },
                })
                .then((res) => {
                  console.log("BA COMMENT", res.data);
                  let commentaries = res.data;
                  var changed_commentary = [];
                  for (let i = 0; i < commentaries.length; i++) {
                    console.log(
                      "LOWER CASE: ",
                      commentaries[i].category_comment.toLowerCase()
                    );
                    commentaries[i].category_comment =
                      commentaries[i].category_comment.toLowerCase();
                    commentaries[i].date_from = moment(
                      commentaries[i].date_from
                    ).format("MMMM DD, YYYY");
                    commentaries[i]["is_current_month"] = moment(
                      commentaries[i].date_from
                    ).isSame(new Date(), "month");
                    for (let y = 0; y < commentaries[i].SIC.length; y++) {
                      commentaries[i].SIC[y].sic_description =
                        commentaries[i].SIC[y].sic_description.toLowerCase();
                    }
                  }
                  console.log("changed commentaries", commentaries);

                  this.commentaries = commentaries;
                  this.initialExpand();
                })
                .catch((error) => {
                  throw error;
                });
            })
            .catch((error) => {
              console.log("BA COMMENT ERROR", error);
              throw error;
            });
        }
      );
    },
    setActiveComment(data) {
      this.expanded = true;
      this.openDetails = true;
      this.activeComment = data;
      this.$nextTick(() => {
        this.$refs.closeButton.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      });
    },
    closeDetails() {
      this.openDetails = false;
      this.activeComment = {};
    },
    initialExpand() {
      if (!this.globalAnalystCommentaryInitialExpanded) {
        this.semiExpand = true;
        this.$store.dispatch(
          "CHANGE_GLOBAL_ANALYST_COMMENTARY_INITIAL_EXPANDED",
          true
        );
        setTimeout(() => {
          this.semiExpand = false;
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters(["siteConfig", "globalAnalystCommentaryInitialExpanded"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnCommentaries() {
      let comments = this.commentaries.sort((a, b) => {
        return moment(b.date_from) - moment(a.date_from);
      });
      if (!this.expanded) {
        // return commentaries descending by date_from
        return comments;
      } else {
        return comments;
      }
    },
    returnCommentariesOtherThanActive() {
      return this.commentaries.filter(
        (comment) => comment.id !== this.activeComment.id
      );
    },

    returnAllCurrentMonthCount() {
      return this.commentaries.filter((comment) => comment.is_current_month)
        .length;
    },
  },
  watch: {
    expanded(val) {
      if (!val) {
        this.closeDetails();
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.analyst-container {
  min-height: 6vh;
  bottom: 0px;
  left: 0px;
  width: 100%;
  position: fixed;
  z-index: 103 !important;
  //   make animation transition
  transition: all 0.3s ease-in-out;

  .new {
    color: white;
    background: red;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    max-height: 25px;
    white-space: break-spaces;
  }
  .commentary_header {
    background: rgb(255, 255, 255);
    padding-top: 7px;
    padding-bottom: 15px;
    box-shadow: 2px 3px 3px 0px rgb(225, 225, 225);
  }

  .bg {
    height: 6vh;
    padding: 0px 0px 20px 0px;
    opacity: 0.95;
    // min-height: 95vh;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.3s ease-in-out;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    // .title {
    //   transition: all 0.3s ease-in-out;
    // }
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    .carousel-3d-container {
      .carousel-3d-slide {
        background-color: rgba(255, 0, 0, 0);
        border-radius: 5px;
        border: none;
        overflow-y: hidden;
        padding: 20px;
        .content-container {
          background-color: rgba(255, 255, 255, 0.9);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.115);
          height: 100%;
          border-radius: 10px;
          padding-left: 20px;
          padding-right: 20px;
          .title {
            font-size: 15px;
          }
          .comment-container {
            height: 50px;
            overflow: hidden;
          }
        }
      }
    }

    .body {
      position: fixed !important;
      height: 90vh;
      overflow-y: auto;
      left: 0;
      padding-top: 5px;
      &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #0052b4;
        border-radius: 3px;

        &:hover {
          background: #0048a1;
        }
      }

      .comment-container {
        height: 50px;
        overflow: hidden;
      }
    }
    .left-pane {
      // margin-top: 50px !important;
      position: fixed !important;
      height: 90vh;
      overflow-y: auto;
      left: 0;
      padding-top: 5px;
      // color: rgb(89, 89, 89);
      &::-webkit-scrollbar {
        width: 0.2em;
        height: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #0052b4;
        border-radius: 3px;

        &:hover {
          background: #0048a1;
        }
      }
    }
    .right-pane {
      // margin-top: 50px !important;
      position: fixed !important;
      height: 90vh;
      overflow-y: auto;
      right: 0;
      &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #0052b4;
        border-radius: 3px;

        &:hover {
          background: #0048a1;
        }
      }

      .comment-container {
        height: 50px;
        overflow: hidden;
      }
    }

    .card {
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.069);
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  &.semi-expand {
    .bg {
      height: 55vh;
    }
  }

  &.expanded {
    // height: 100vh;
    box-shadow: none;
    .bg {
      height: 100vh;
      overflow-y: auto;
      opacity: 1;
      //   .title {
      //     justify-content: start;
      //   }
    }
  }
}

.text-gradient {
  background: rgb(57, 0, 138);
  background: linear-gradient(
    30deg,
    rgba(0, 82, 180, 1) 30%,
    rgb(104, 0, 250) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
