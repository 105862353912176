import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import WhyIndoinfoView from "@/views/WhyIndoinfoView.vue";
import ArticleAllView from "@/views/ArticleAllView.vue";
import ArticleSingleView from "@/views/ArticleSingleView.vue";
import EventsAllView from "@/views/EventsAllView.vue";
import RedirectUser from "@/views/RedirectUser.vue";
import WhistleblowView from "@/views/WhistleblowView.vue";
import CompanyView from "@/views/CompanyView.vue";
import SearchCompanyRedirect from "@/views/SearchCompanyRedirect.vue";

// import FeatureView from '@/views/FeatureView.vue'
// import ServicesView from '@/views/ServicesView.vue'
// import PageNotFound from '@/views/PageNotFound.vue'
// delete soon
// import ArticleView from '@/views/ArticleView.vue'
// import ShowAllSection from "@/components/articlecomponents/ShowAllSection.vue"
// import ShowSpecificSection from "@/components/articlecomponents/ShowSpecificSection.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/whyindoinfo",
    name: "whyindoinfo",
    component: WhyIndoinfoView,
  },
  {
    path: "/articles",
    name: "articles",
    component: ArticleAllView,
  },
  {
    path: "/article",
    name: "articledetail",
    component: ArticleSingleView,
    props: (route) => ({ query: route.query.q }),
  },

  {
    path: "/events",
    name: "events",
    component: EventsAllView,
    props: (route) => ({ query: route.query.q }),
  },
  {
    path: "/whistleblow",
    name: "whistleblow",
    component: WhistleblowView,
  },
  {
    path: "/company",
    name: "company",
    component: CompanyView,
    props: (route) => ({
      query: `${route.query.c}&${route.query.q}&${route.query.z}`,
    }),
  },
  // {
  //   path: "/search-company-redirect/:company_name/:redirect_target_name",
  //   name: "search_company_redirect",
  //   component: SearchCompanyRedirect,
  //   props: (route) => ({ query: `${route.query.q}&${route.query.z}` }),
  // },
  { path: "*", component: RedirectUser },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior: function (to) {
    setTimeout(() => {
      let element = document.getElementById(to.hash.replace(/#/, ""));
      setTimeout(() => {
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      }, 20);
    }, 10);
  },
});

export default router;
