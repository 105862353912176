var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mx-0 px-4 d-flex",staticStyle:{"padding-bottom":"60px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",staticStyle:{"max-width":"100vw","overflow-x":"hidden"}},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"openSansBold",class:{
            'text-center': this.text_align === 'center',
            'text-left': this.text_align === 'left',
            'text-right': this.text_align === 'right',
          }},[_vm._v(" "+_vm._s(_vm.left_splitted_header)),_c('span',{staticClass:"emphasis"},[_vm._v(_vm._s(_vm.outlined_text))]),_vm._v(_vm._s(_vm.right_splitted_header)+" ")])]),(_vm.description !== undefined)?_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('p',{class:{
            'text-center': this.text_align === 'center',
            'text-left': this.text_align === 'left',
            'text-right': this.text_align === 'right',
          }},[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_vm._e(),(this.cta_text !== undefined)?_c('div',{staticClass:"col-12 d-flex",class:{
          'justify-content-center': this.text_align === 'center',
          'justify-content-start': this.text_align === 'left',
          'justify-content-end': this.text_align === 'right',
        }},[(this.cta_text !== '')?_c('div',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.goToLink()}}},[_vm._v(" "+_vm._s(this.cta_text)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-12 py-5",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"container rounded"},[_c('div',{staticClass:"slider"},[_vm._l((_vm.logostop),function(logos,index){return _c('div',{key:index + 'top-1',staticClass:"logos mx-4"},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":logos,"alt":""}})])])}),_vm._l((_vm.logostop),function(logos,index){return _c('div',{key:index + 'top-2',staticClass:"logos mx-4"},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":logos,"alt":""}})])])})],2)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }