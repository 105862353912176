<template>
  <div
    class="bg container-fluid"
    v-bind:style="{ 'background-image': 'url(' + bg + ')' }"
  >
    <div class="container px-0" style="height: 100%">
      <div class="row" style="height: 100%">
        <div class="col-md-8 col-12 py-0 my-0 d-flex align-items-center">
          <div class="row">
            <div class="col-12">
              <h1 class="white openSansBold page-title">
                {{ header }}
              </h1>
            </div>
            <div class="col-12 d-flex openSansBold">
              <div class="d-flex" v-for="(button, index) in buttons" :key="index + 'buttons'" style="width: 100%">
                <!-- <vs-button
                  gradient
                  @click="openContactDialog(button.link)"
                  class="mr-3"
                  floating
                  block
                >
                  {{ button.text }}</vs-button
                > -->
                <button
                  class="btn btn-block mr-3"
                  @click="openContactDialog(button.link)"
                  :class="[
                    { 'mr-3': index == 0 },
                    button.style === 'btn-primary'
                      ? 'btn-primary'
                      : 'btn-outline-primary',
                  ]"
                >
                  {{ button.text }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchbackground from "@/assets/bgs/cta_update.png";
export default {
  props: {
    header: String,
    buttons: Array,
  },
  data() {
    return {
      bg: searchbackground,
    };
  },
  methods: {
    openContactDialog(link) {
      var x = {
        state: true,
        tab: link,
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
  },
};
</script>

<style scoped>
.bg {
  height: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

input {
  border-radius: 20px;
}
input#searchinput {
  background-color: rgba(255, 255, 255, 0.767) !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.075) inset, 0 0 1px rgb(170, 170, 170) !important;
  border: none;
  color: #212327;
}

input#searchinput:focus {
  background-color: rgb(255, 255, 255) !important;
}

input#searchinput::placeholder {
  color: rgb(88, 88, 88) !important;
}

.form-select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: var(--lightblue) !important;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.075) inset, 0 0 0px black !important;
  outline: 0 none !important;
  background-color: var(--white) !important;
}

input,
textarea {
  background-color: var(--lightgray) !important;
  border-radius: 10px !important;
}

button {
  border-radius: 10px !important;
}

.btn-outline-primary{
  background: #acccff;
  color: #0052B4 !important;
  border: 1px solid #acccff !important ;
}

.btn-outline-primary:hover{
  border: 1px solid #0052B4 !important ;
}

.btn:focus {
  border-color: var(--pastelblue) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 1px var(--pastelblue) !important;
  outline: 0 none !important;
}

.page-title {
  color: #fff;
  font-size: 3vw !important;
}



@media only screen and (max-width: 600px) {
  .page-title {
    font-size: 6vw !important;
  }

}
</style>