<template>
  <div>
    <vs-dialog v-model="show_dialog">
      <div class="row dialog">
        <div class="col-12">
          <div class="banner-container">
            <img :src="returnImageData.img" class="banner" alt="" />
          </div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EnImage from "@/assets/season_events_images/IIEidEN.jpg";
import IdImage from "@/assets/season_events_images/IIEidID.jpg";

export default {
  data() {
    return {
      show_dialog: true,
      end_date: "2024-04-15", //yyyy-mm-dd
      images: [
        {
          lang: "EN",
          img: EnImage,
        },
        {
          lang: "ID",
          img: IdImage,
        },
      ],
    };
  },
  methods: {
    shouldShowBaner() {
      const currentDate = new Date();

      const targetDate = new Date(this.end_date);

      // Comparing dates
      if (currentDate > targetDate) {
        console.log("FALSE: current: ", currentDate, "| target:", targetDate);
        this.show_dialog = false;
      } else {
        console.log("TRUE: current", currentDate, "| target:", targetDate);
        this.show_dialog = true;
      }
    },
  },
  computed: {
    ...mapGetters(["language"]),
    returnLanguage() {
      return this.language;
    },
    returnImageData() {
      return this.images.filter((item) => {
        return item.lang === this.language;
      })[0];
    },
  },
  mounted() {
    this.shouldShowBaner();
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  // max-height: 80vh;
  // max-width: 40vw;
  width: auto;
  width: auto;
  overflow-y: auto;
  .banner-container {
    width: auto;
    height: auto;
    max-height: 80vh;
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 100%;
      max-height: 80vh;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      img {
        scale: 1.1;
      }
    }
  }
}
</style>
