<template>
  <div
    class="bg my-0"
    v-bind:style="{ 'background-image': 'url(' + searchbg + ')' }"
  >
    <div
      class="container pt-5"
      style="padding-bottom: 60px; overflow-x: hidden"
    >
      <div class="row">
        <div class="col-12">
          <h2 class="openSansBold">
            Success <span class="emphasis">Cases</span>
          </h2>
        </div>
        <div class="col-12 mt-5">
          <div class="row">
            <!-- IMAGE LEFT -->
            <div
              class="
                col-sm-12 col-md-4
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <div
                class="center-cropped"
                v-bind:style="{ 'background-image': 'url(' + img_sample + ')' }"
              >
                <img :src="img_sample" />
              </div>
            </div>
            <!-- END OF IMAGE LEFT -->
            <!-- new carousel -->
            <div class="col-sm-12 col-md-8">
              <div class="row custom-carousel">
                <!-- Left Arrows -->
                <div
                  class="col-1 d-flex align-items-center navigator"
                  style="cursor: pointer"
                  @click="prev()"
                  :class="{ active: slide !== 0 }"
                >
                  <span class="material-icons-round">arrow_back_ios</span>
                </div>

                <!-- End of Left Arrows -->
                <!-- Carousel -->
                <div class="col-10 justify-content-center">
                  <span v-for="(item, index) in cases" :key="index">
                    <div class="card border-0" v-if="slide === index">
                      <div class="card-body">
                        <h1 class="openSansSemiBold">{{ item.title }}</h1>
                        <p>{{ item.description }}</p>
                        <p>Results:</p>
                        <ol>
                          <li
                            v-for="(result, index) in item.results"
                            :key="index + 'results'"
                            class="mt-2"
                          >
                            {{ result }}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </span>
                </div>
                <!-- End of carousel -->

                <!-- Right Arrow -->
                <div
                  class="
                    col-1
                    d-flex
                    align-items-center
                    navigator
                    justify-content-end
                  "
                  style="cursor: pointer"
                  @click="next()"
                  :class="{ active: slide < cases.length - 1 }"
                >
                  <span class="material-icons-round">arrow_forward_ios</span>
                </div>
                <!-- end of Right Arrow -->

                <!-- circles -->
                <div class="col-12 d-flex justify-content-center mt-3">
                  <div
                    class="circles mx-2"
                    v-for="(item, index) in cases"
                    :key="index + '-circles'"
                    :class="{ active: index === slide }"
                    @click="slide = index"
                    style="cursor: pointer"
                  ></div>
                </div>
                <!-- end of circles -->
              </div>
            </div>
            <!-- end of new carousel -->
          </div>
          <div class="col-12 justify-content-center d-flex pt-5">
            <h4 class="text-center">{{ cta_text }}</h4>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <div class="btn btn-primary" @click="goToLink()">
              {{ cta_button_text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchbackground from "../../assets/bgs/success_story_bg.png";
import sampleImage from "../../assets/sample/sample1.png";

export default {
  props: {
    cases: Array,
    cta_text: String,
    cta_button_text: String,
  },
  data() {
    return {
      searchbg: searchbackground,
      img_sample: sampleImage,
      slide: 0,
    };
  },
  methods: {
    loopSlides() {
      let self = this;
      setTimeout(() => {
        setInterval(function () {
          if (self.slide < self.cases.length - 1) {
            self.slide += 1;
          } else {
            self.slide = 0;
          }
        }, 20000);
      }, 2000);
    },
    goToLink() {
      var x = {
        state: true,
        tab: "Contact Sales",
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
    prev() {
      if (this.slide === 0) {
        this.slide = this.cases.length - 1;
      } else {
        this.slide -= 1;
      }
      console.log("current slide:", this.slide);
      console.log("length: ", this.cases.length - 1);
    },
    next() {
      if (this.slide < this.cases.length - 1) {
        this.slide += 1;
      } else {
        this.slide = 0;
      }

      console.log("current slide:", this.slide);
      console.log("length: ", this.cases.length - 1);
    },
  },
  mounted: function () {
    this.loopSlides();
  },
};
</script>


<style scoped lang="scss" >
.bg {
  min-height: 50vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-carousel {
  height: 100% !important;
}

.circles {
  border-radius: 100%;
  background-color: #c4c4c4;
  min-height: 10px;
  min-width: 10px;
  max-height: 10px;
  max-width: 10px;
}

.circles.active {
  background-color: #0052b4;
}

.navigator {
  color: #c4c4c4;
}

.navigator.active {
  color: #0052b4;
}
</style>