var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.returnEventDialogPrompt && _vm.returnFeaturingEvent !== undefined)?_c('vs-dialog',{staticStyle:{"max-height":"100vh"},model:{value:(_vm.show_event_dialog),callback:function ($$v) {_vm.show_event_dialog=$$v},expression:"show_event_dialog"}},[_c('div',{staticClass:"row dialog"},[_c('div',{staticClass:"col-9"},[_c('h6',{staticClass:"poppinsSemiBold"},[_vm._v(" Indoinfo | "),_c('span',{staticClass:"poppinsRegular"},[_vm._v("events")]),_c('span',{staticClass:"text-gradient poppinsBold ml-1"},[_vm._v(_vm._s(new Date().getFullYear()))])])]),_c('div',{staticClass:"col-12 mt-3"},[_c('h2',{staticClass:"poppinsBold text-center text-md-left text-gradient"},[(_vm.returnCurrentLanguage === 'EN')?_c('span',[_vm._v(" "+_vm._s(_vm.returnFeaturingEvent.title_event_en)+" ")]):_vm._e(),(_vm.returnCurrentLanguage === 'ID')?_c('span',[_vm._v(" "+_vm._s(_vm.returnFeaturingEvent.title_event)+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-12 d-flex justify-content-center justify-content-md-start d-flex align-items-center"},[_c('span',{staticClass:"material-icons-round"},[_vm._v("event")]),_c('span',{staticClass:"span ml-2"},[_vm._v(" "+_vm._s(_vm.returnFeaturingEvent.date_to)+" ")])]),_c('div',{staticClass:"col-md-4 col-12 d-flex justify-content-center justify-content-md-start d-flex align-items-center"},[(_vm.returnFeaturingEvent.number_of_applicant === '-')?_c('span',{staticClass:"material-icons-round mx-2"},[_vm._v("all_inclusive")]):_vm._e(),(
          _vm.returnFeaturingEvent.number_of_applicant === '-' &&
          _vm.returnCurrentLanguage === 'EN'
        )?_c('span',{staticClass:"span"},[_vm._v(" Unlimited Entries ")]):_vm._e(),(
          _vm.returnFeaturingEvent.number_of_applicant === '-' &&
          _vm.returnCurrentLanguage === 'ID'
        )?_c('span',{staticClass:"span"},[_vm._v(" Tidak terbatas ")]):_vm._e()]),_c('div',{staticClass:"col-md-6 col-12 mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"image-container"},[(!_vm.debugmode)?_c('img',{attrs:{"src":_vm.image_path_base + _vm.returnFeaturingEvent.url_image,"alt":""}}):_vm._e(),(_vm.debugmode)?_c('img',{attrs:{"src":_vm.returnFeaturingEvent.url_image,"alt":""}}):_vm._e()])])])]),_c('div',{staticClass:"col-md-6 col-12 mt-3",staticStyle:{"padding-bottom":"150px"}},[_c('div',{staticClass:"row text-description"},[_c('div',{staticClass:"col-12"},[(_vm.returnCurrentLanguage === 'EN')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.returnFeaturingEvent.event_en)}}):_vm._e(),(_vm.returnCurrentLanguage === 'ID')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.returnFeaturingEvent.event_id)}}):_vm._e()])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mx-0 px-0",staticStyle:{"position":"absolute","bottom":"5px","max-width":"100%","background":"white"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-3 d-flex justify-content-center",staticStyle:{"width":"100%"}},[_c('vs-button',{staticClass:"poppinsBold",attrs:{"block":"","floating":"","gradient":""},on:{"click":function($event){return _vm.goToLink(_vm.returnFeaturingEvent.url_event)}}},[(_vm.returnCurrentLanguage === 'EN')?_c('span',[_vm._v(" "+_vm._s(_vm.returnFeaturingEvent.btn_en)+" ")]):_vm._e(),(_vm.returnCurrentLanguage === 'ID')?_c('span',[_vm._v(" "+_vm._s(_vm.returnFeaturingEvent.btn)+" ")]):_vm._e()])],1),_c('div',{staticClass:"col-12 py-0 d-flex justify-content-center",staticStyle:{"width":"100%"}},[_c('vs-checkbox',{model:{value:(_vm.notInterested),callback:function ($$v) {_vm.notInterested=$$v},expression:"notInterested"}},[_vm._v(" Don't show this again ")])],1)])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }