<template>
  <div class="container py-5">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <h2 class="openSansBold">
          {{ left_splitted_header
          }}<span class="emphasis">{{ outlined_text }}</span
          >{{ right_splitted_header }}
        </h2>
      </div>
    </div>
    <div class="row py-3">
      <!-- IMAGE LEFT -->
      <div
        class="col-12 col-md-6 d-flex justify-content-center align-items-center"
      >
        <div
          class="center-cropped"
          v-bind:style="{ 'background-image': 'url(' + img_sample + ')' }"
        >
          <img :src="img_sample" />
        </div>
      </div>
      <!-- END OF IMAGE LEFT -->
      <div class="col-12 col-md-6 py-md-0 py-3">
        <div class="row" style="height: 100%">
          <div
            class="col-12 d-flex align-items-center reasons pointer"
            v-for="(reason, index) in reasons"
            :key="'reason' + index"
            @click="active = index"
          >
            <h5 :class="{ active: active === index }">{{ reason.text }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sampleImage from "@/assets/sample/womanlaptop.png";
export default {
  props: {
    header: String,
    outlined_text: String,
    reasons: Array,
  },
  data() {
    return {
      img_sample: sampleImage,
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      active: 0,
    };
  },
  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("CUTE AND DUMP: could not find");
      }
    },
    setActiveInterval() {
      const self = this;
      setTimeout(() => {
        setInterval(function () {
          if (self.active < self.reasons.length - 1) {
            self.active += 1;
          } else {
            self.active = 0;
          }
        }, 10000);
      }, 10000);
    },
  },
  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    header() {
      this.CutandDump();
    },
  },
  mounted() {
    this.setActiveInterval();
    this.CutandDump();
  },
};
</script>

<style lang="scss" scoped>
.reasons {
  border-bottom: 2px solid #5858586d;
  color: #5858586d;
  .active {
    color: #000;
  }
}

.center-cropped {
  width: 90% !important;
  max-width: 90% !important;

  height: 320px !important;
  // background-position: center center;
  // background-repeat: no-repeat;
  // overflow: hidden;
  border-radius: 20px !important;
  img {
    max-width: 100%;
  }
}
</style>