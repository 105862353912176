<template>
  <div :key="cleanSlateKey">
    <div v-if="returnCurrentArticle == undefined">
      <PuSkeleton :count="1" height="60vh" />
      <div class="container" v-for="(load, lidx) in 2" :key="lidx">
        <PuSkeleton :count="2" height="30px" />
        <PuSkeleton :count="1" height="30px" width="80%" />
        <PuSkeleton :count="1" height="30px" width="90%" />
        <PuSkeleton :count="1" height="30px" />
        <PuSkeleton :count="2" height="30px" />
        <PuSkeleton :count="1" height="30px" width="90%" />
      </div>

      <div class="container mt-5">
        <div class="row">
          <div
            class="col-12 col-md-4 py-2"
            v-for="(loadimg, lidximg) in 6"
            :key="lidximg"
          >
            <PuSkeleton :count="1" height="150px" width="100%" />
            <PuSkeleton :count="1" height="30px" width="80%" />
            <PuSkeleton :count="3" height="20px" width="100%" />
          </div>
        </div>
      </div>
    </div>
    <span v-else>
      <div
        id="head"
        class="backgroundImage mx-0"
        v-bind:style="{
          'background-image':
            'url(' + image_path_base + returnCurrentArticle.url_image + ')',
        }"
      ></div>
      <div
        class="row px-0 mx-0"
        style="
          position: absolute;
          z-index: 1;
          height: 80vh;
          width: 100%;
          top: 0;
        "
      >
        <div
          class="col-12 d-flex px-3 align-items-center justify-content-center"
          style="height: 100%"
        >
          <h1 class="white openSansBold p-4 article-title">
            {{ returnCurrentArticle.title_artikel }}
          </h1>
        </div>
      </div>
      <div class="container py-0">
        <div class="row px-0 py-0 mx-0">
          <div class="col-12 article-content py-3">
            <p v-html="returnCurrentArticle.artikel"></p>
          </div>
        </div>
      </div>
      <!-- FOOTER CHANGE SOON -->
      <div class="container" v-if="returnAllArticlesFooters.length > 0">
        <div class="row px-4 mx-0">
          <div
            class="col-12 d-flex justify-content-md-start justify-content-center px-0"
          >
            <h2 class="c-primary openSansBold">Other Blogs</h2>
          </div>
        </div>
        <div class="row px-5 mx-0">
          <div
            class="col-12 col-md-3 py-3 d-flex justify-content-center"
            v-for="(item, index) in returnAllArticlesFooters"
            :key="index"
            style="border-shadow: 0px 0px 10px 10px #000"
          >
            <router-link :to="`/article?q=${item.slug}`">
              <vs-card>
                <template #title>
                  <h3 class="openSansBold" style="width: 100%">
                    {{ item.title_artikel }}
                  </h3>
                </template>
                <template #img>
                  <img :src="image_path_base + item.url_image" alt="" />
                </template>
                <template #text>
                  <p></p>
                </template>
              </vs-card>
            </router-link>
          </div>
          <div
            class="col-12 py-5 d-flex justify-content-center"
            v-if="returnAllArticlesFooters.length - 1 > 0"
          >
            <vs-button rounded border @click="seeAllBlogs()">
              <h5 class="openSansMedium py-0 my-0">View All Blogs</h5>
            </vs-button>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import axios from "axios";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      article: [],
      article_path: "article?q=",
      articles_all_path: "article/active?src=indoinfo",
      image_path_base: "https://portal.indoinfo.co.id/img/",
      all_articles: [],
      cleanSlateKey: 0,
      render: false,
    };
  },
  methods: {
    async getDataWithCaptchaArticle() {
      console.log(
        "ARTICLE VIEW INITIALIZING (GETTING DATA WITH CAPTCHA) ",
        location.hostname,
        this.returnSiteConfig.cpt
      );
      let self = this;
      console.log("current captcha: ", this.returnSiteConfig);
      await load(self.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              console.log("GET DATA TOKEN: ", token);
              axios
                .get(
                  self.returnSiteConfig.e_p +
                    self.article_path +
                    self.$route.query.q,
                  {
                    params: {
                      init_token: token,
                    },
                  }
                )
                .then((res) => {
                  console.log("the data first", res.data);
                  this.article = res.data;
                })
                .catch((error) => {
                  console.log(
                    "ARTICLE get data token error url: ",
                    self.returnSiteConfig.e_p +
                      self.article_path +
                      self.$route.query.q
                  );
                  console.log("ARTICLE get data token error", error);
                });
            })
            .catch((error) => {
              console.log("ARTICLE get data recaptcha error", error);
            });
        }
      );
    },
    async getDataWithCaptchaAllArticles() {
      console.log(
        "ARTICLE VIEW INITIALIZING (GETTING DATA WITH CAPTCHA) ",
        location.hostname,
        this.returnSiteConfig.cpt
      );
      let self = this;
      console.log("current captcha: ", this.returnSiteConfig);
      await load(self.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              console.log("GET DATA TOKEN: ", token);
              axios
                .get(self.returnSiteConfig.e_p + self.articles_all_path, {
                  params: {
                    init_token: token,
                  },
                })
                .then((res) => {
                  console.log("ARTICLES", res.data);
                  this.all_articles = res.data;
                  this.scrollToTop();
                })
                .catch((error) => {
                  this.scrollToTop();
                  console.log("ARTICLE get data token error", error);
                })
                .catch((error) => {
                  console.log("ARTICLE get data token error", error);
                  this.scrollToTop();
                });
            })
            .catch((error) => {
              console.log("ARTICLE get data recaptcha error", error);
              this.scrollToTop();
            });
        }
      );
    },
    openArticle(item) {
      this.$router.push({ path: "/article", query: { q: item.slug } });
      //   location.reload();
      console.log("OPEN ARTICLE: ", item.slug);
    },
    seeAllBlogs() {
      this.$router.push({ path: "/articles" });
    },
    cleanSlate() {
      // remove all the articles
      this.all_articles = [];
      this.article = [];
    },
    scrollToTop() {
      setTimeout(() => {
        const element = document.getElementById("head");
        if (element) {
          element.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      }, 20);
    },
  },
  computed: {
    ...mapGetters(["siteConfig"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnCurrentArticle() {
      return this.article[0];
    },
    returnAllArticlesFooters() {
      return this.all_articles.filter(
        (item) => item.slug !== this.returnCurrentArticle.slug
      );
    },

    returnRouteQuery() {
      return this.$route.query.q;
    },
  },

  watch: {
    returnRouteQuery(val) {
      console.log("CURRENT ROUTE QUERY: ", val);
      this.cleanSlate();
      this.getDataWithCaptchaArticle();
      this.getDataWithCaptchaAllArticles();
    },
  },

  mounted() {
    this.getDataWithCaptchaArticle();
    this.getDataWithCaptchaAllArticles();
  },
};
</script>

<style lang="scss" scoped>
.article-title {
  font-size: 2em;
  background-color: rgba(0, 0, 0, 0.381);
  border-radius: 10px;
}
.backgroundImage {
  min-height: 80vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: rgb(0, 0, 0);
}

.article-content {
  img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.back-icon {
  margin-top: 10px;
  cursor: pointer;
  color: #0052b4;
}
.back-icon:hover {
  color: #004497;
}
</style>
