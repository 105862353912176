import Vue from "vue";
import Vuex from "vuex";
import homecontent from "./homecontent";
import whyindoinfocontent from "./whyindoinfocontent";
import servicescontent from "./servicescontent";
import utilscontent from "./utilscontent";
import whistleblowcontent from "./whistleblowcontent";
import companysearch from "./companysearch";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "indoinfo_lang",
  storage: window.localStorage,
  reducer: (state) => ({
    language: state.language,
  }),
});

const eventsDialogPrompter = new VuexPersist({
  key: "indoinfo_dialog",
  storage: window.localStorage,
  reducer: (state) => ({
    event_dialog_prompt: state.event_dialog_prompt,
  }),
});

export default new Vuex.Store({
  state: {
    language: "EN",
    event_dialog_prompt: true,
    register_dialog: {
      state: false,
      tab: "Register",
    },
    whyindoinfo: whyindoinfocontent,
    home: homecontent,
    services: servicescontent,
    utils: utilscontent,
    whistleblow: whistleblowcontent,
    siteConfig: {},
    articles: [],

    scrolls: {
      isUserScrolling: false,
      old_scroll: 0,
      new_scroll: 0,
      scroll_direction: "",
    },

    globalAnalystCommentaryInitialExpanded: false,
  },
  getters: {
    language: (s) => s.language,
    register_dialog: (s) => s.register_dialog,
    siteConfig: (s) => s.siteConfig,
    articles: (s) => s.articles,
    scrolls: (s) => s.scrolls,
    event_dialog_prompt: (s) => s.event_dialog_prompt,
    globalAnalystCommentaryInitialExpanded: (s) =>
      s.globalAnalystCommentaryInitialExpanded,
  },
  mutations: {
    SET_SCROLLS(s, y) {
      s.scrolls = y;
    },
    SET_LANGUAGE(s, y) {
      s.language = y;
      console.log("store committed: ", y);
    },
    SET_REG_DIALOG_STATUS(s, y) {
      console.log("setting register dialog: ", s, y);
      s.register_dialog = y;
    },
    SET_SITE_CONFIG(s, y) {
      s.siteConfig = y;
    },
    SET_ARTICLES(s, y) {
      // ? type here refers to type of the commit:
      // ? 1 means initial, 2 means refetch (when user clicks on view more)
      if (y.type === 1) {
        // *USE THIS WHEN YOU WANT TO TEST THE REQUEST WITH DUMMY DATA. THIS
        // *MULTIPLIES THE ARTICLE
        // for(let x = 0; x < y.article.length; x++) {
        //   s.articles.push(y.article[x]);
        //   s.articles.push(y.article[x]);
        //   s.articles.push(y.article[x]);
        //   s.articles.push(y.article[x]);
        //   s.articles.push(y.article[x]);
        // }
        s.articles = y.article;
      }

      if (y.type === 2) {
        s.articles.push(...y.article);
      }
    },
    SET_EVENT_DIALOG_PROMPT(s, y) {
      s.event_dialog_prompt = y;
    },
    SET_GLOBAL_ANALYST_COMMENTARY_INITIAL_EXPANDED(s, y) {
      s.globalAnalystCommentaryInitialExpanded = y;
    },
  },
  actions: {
    CHANGE_LANGUAGE({ commit }, data) {
      commit("SET_LANGUAGE", data);
    },
    CHANGE_DIALOG_STATUS({ commit }, data) {
      commit("SET_REG_DIALOG_STATUS", data);
    },
    CHANGE_SITE_CONFIG({ commit }, env) {
      // console.log('CHECKING DEVTOOLS', Vue.config.devtools);
      if (env === "localhost") {
        var x = {
          cpt: process.env.VUE_APP_LOCALHOST_CPT,
          e_p: process.env.VUE_APP_LOCALHOST_EP,
        };

        commit("SET_SITE_CONFIG", x);
      }

      if (env === "192.168.100.234") {
        var x = {
          cpt: process.env.VUE_APP_LIVEDEV_CPT,
          e_p: process.env.VUE_APP_LIVEDEV_EP,
        };

        commit("SET_SITE_CONFIG", x);
      }

      if (env === "indoinfo.co.id") {
        var x = {
          cpt: process.env.VUE_APP_LIVE_CPT,
          e_p: process.env.VUE_APP_LIVE_EP,
        };

        commit("SET_SITE_CONFIG", x);
      }
      if (env === "www.indoinfo.co.id") {
        var x = {
          cpt: process.env.VUE_APP_LIVE_CPT,
          e_p: process.env.VUE_APP_LIVE_EP,
        };

        commit("SET_SITE_CONFIG", x);
      }
    },
    INITIATE_PUSH_ARTICLES({ commit }, payload) {
      console.log("SET_ARTICLE: ", payload);

      commit("SET_ARTICLES", payload);
    },
    CHANGE_SCROLLS({ commit }, payload) {
      commit("SET_SCROLLS", payload);
    },
    CHANGE_EVENT_DIALOG_PROMPT({ commit }, payload) {
      commit("SET_EVENT_DIALOG_PROMPT", payload);
    },
    CHANGE_GLOBAL_ANALYST_COMMENTARY_INITIAL_EXPANDED({ commit }, payload) {
      commit("SET_GLOBAL_ANALYST_COMMENTARY_INITIAL_EXPANDED", payload);
    },
  },
  plugins: [vuexLocalStorage.plugin, eventsDialogPrompter.plugin],
  modules: {
    wiic: whyindoinfocontent,
    hc: homecontent,
    sc: servicescontent,
    ut: utilscontent,
    wb: whistleblowcontent,
    cs: companysearch,
  },
});
