var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data_copied)?_c('div',[_c('email-send',{attrs:{"mail_type":_vm.currentTab,"user_details":_vm.user_details,"should_send_email":_vm.email_send,"email_send_status":_vm.email_send_status},on:{"update:mail_type":function($event){_vm.currentTab=$event},"update:user_details":function($event){_vm.user_details=$event},"update:should_send_email":function($event){_vm.email_send=$event},"update:email_send_status":function($event){_vm.email_send_status=$event},"update":function($event){_vm.email_send, _vm.email_send_status}}}),_c('vs-dialog',{attrs:{"blur":"","not-padding":"","full-screen":"","auto-width":"","scroll":"","overflow-hidden":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"row px-0 mx-0"},[_c('div',{staticClass:"col-12 col-md-5 order-1 order-md-0 px-0 mx-0",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"row mx-0 px-0 align-items-center",staticStyle:{"height":"100%","width":"100%","position":"absolute"}},[_c('div',{staticClass:"col-12 py-5 white"},[_c('h1',{staticClass:"openSansBold text-right"},[_vm._v(" "+_vm._s(_vm.returnTitle.header + " " + _vm.returnTitle.outlined_text)+" "),_c('br'),_c('span')]),_c('p',{staticClass:"text-right"})])]),_c('div',{staticStyle:{"max-height":"calc(100vh - 30px)","min-height":"calc(100vh - 30px)","overflow-y":"hidden"},style:({
            'background-image': 'url(' + _vm.returnBackgrounds + ')',
          })})]),_c('div',{staticClass:"col-12 col-md-7 order-0 order-md-1",staticStyle:{"max-height":"calc(100vh - 30px)","overflow-y":"auto","height":"100vh"}},[_c('div',{staticClass:"con-form row px-5"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row px-0 pt-5"},[_c('div',{staticClass:"col-12 mx-0 px-0 col-md-6 order-2 order-md-1"},[_c('h2',{staticClass:"openSansBold c-primary"},[(_vm.currentTab === 'Register')?_c('span',[_vm._v(" "+_vm._s(_vm.page_settings.title.register)+" ")]):_vm._e(),(_vm.currentTab === 'Contact Sales')?_c('span',[_vm._v(" "+_vm._s(_vm.page_settings.title.contact_sales)+" ")]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-6 order-1 order-md-2 d-flex justify-content-start justify-content-md-end mx-0 px-0"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index + 'tabs',class:{
                    'active openSansBold c-primary': tab === _vm.currentTab,
                    openSansMedium: tab !== _vm.currentTab,
                  }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab !== tab),expression:"currentTab !== tab"}]},[_c('vs-button',{attrs:{"transparent":""},on:{"click":function($event){_vm.currentTab = tab}}},[(_vm.currentTab === 'Register')?_c('span',[_vm._v(" "+_vm._s(_vm.page_settings.title.contact_sales)+" ")]):_vm._e(),(_vm.currentTab === 'Contact Sales')?_c('span',[_vm._v(" "+_vm._s(_vm.page_settings.title.register)+" ")]):_vm._e(),_c('span',{staticClass:"material-icons-round",staticStyle:{"font-size":"15px"}},[_vm._v("arrow_forward_ios")])])],1)])}),0)])]),_c('div',{staticClass:"col-12 py-2",staticStyle:{"border-bottom":"2px solid #0052b4"}}),(_vm.email_send_status === 'success')?_c('div',{staticClass:"col-12 py-4 justify-content-center openSansSemiBold px-0"},[_c('vs-alert',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.page_settings.notification.title))])]},proxy:true}],null,false,2812949089)},[_vm._v(" "+_vm._s(_vm.page_settings.notification.text)+" ")])],1):_vm._e(),_c('div',{staticClass:"col-12 py-3 px-0"},_vm._l((_vm.returnInputs.inputs),function(input){return _c('div',{key:input.id,staticClass:"col-12 py-3 px-0"},[(input.type !== 'textarea')?_c('vs-input',{attrs:{"primary":"","icon-after":"","id":input.id,"label-placeholder":input.placeholder,"type":input.type},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"material-icons-outlined",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(input.icon))])]},proxy:true},{key:"message-danger",fn:function(){return [(
                      input.type === 'email' && !_vm.regex_matcher(input.type)
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.page_settings.validation_error.invalid_email_regex)+" ")]):_vm._e(),(input.type !== 'email' && input.input.length < 5)?_c('span',[_vm._v(_vm._s(input.placeholder)+" "+_vm._s(_vm.page_settings.validation_error.less_than_required))]):_vm._e()]},proxy:true}],null,true),model:{value:(input.input),callback:function ($$v) {_vm.$set(input, "input", $$v)},expression:"input.input"}}):_vm._e(),(input.type === 'textarea')?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"}),_c('div',{staticClass:"col-6 d-flex justify-content-end"},[_c('small',{staticClass:"ml-0",attrs:{"for":"comment"}},[(input.input.length <= 250)?_c('span',{staticClass:"openSansRegular"},[_vm._v(_vm._s(input.input.length)+"/")]):_vm._e(),(input.input.length > 250)?_c('span',{staticClass:"openSansRegular danger-text"},[_vm._v(_vm._s(input.input.length)+"/")]):_vm._e(),(input.input.length < 30)?_c('span',{staticClass:"openSansRegular danger-text px-0 py-1"},[_vm._v("30 minimum length")]):_vm._e(),(
                          input.input.length >= 30 &&
                          input.input.length <= 250
                        )?_c('span',{staticClass:"openSansRegular success-text px-0 py-1"},[_vm._v("250 maximum length")]):_vm._e(),(
                          input.input.length >= 30 && input.input.length > 250
                        )?_c('span',{staticClass:"openSansRegular danger-text px-0 py-1"},[_vm._v("250 maximum length")]):_vm._e()])])])]):_vm._e(),(input.type === 'textarea')?_c('div',{staticClass:"form-floating"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(input.input),expression:"input.input"}],staticClass:"form-control",attrs:{"placeholder":input.placeholder,"id":input.id},domProps:{"value":(input.input)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(input, "input", $event.target.value)}}})]):_vm._e()],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'Contact Sales'),expression:"currentTab === 'Contact Sales'"}],staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-items-center openSansMedium pt-5"},[_vm._v(" "+_vm._s(_vm.returnInputs.options_title)+" ")]),_vm._l((_vm.returnInputs.options),function(option,index){return _c('div',{key:index + 'contact_options',staticClass:"col-12 col-md-6 mt-4"},[_c('b-form-checkbox',{attrs:{"id":option.id,"name":option.name,"value":option.value,"unchecked-value":option.unchecked_value},model:{value:(_vm.returnInputs.options_status),callback:function ($$v) {_vm.$set(_vm.returnInputs, "options_status", $$v)},expression:"returnInputs.options_status"}},[_vm._v(" "+_vm._s(option.text)+" ")])],1)})],2)]),_c('div',{staticClass:"col-12 py-4 justify-content-center openSansSemiBold px-0"},[_c('vs-button',{ref:"getchabet",staticClass:"d-flex align-items-center",attrs:{"block":"","disabled":!_vm.regexEmailChecker ||
                !_vm.fieldsPassed ||
                !_vm.messagePassed ||
                _vm.email_send_status === 'sending',"size":"l"},on:{"click":function($event){return _vm.input_check()}}},[(
                  _vm.returnInputs.options_status !== 'Appointment' &&
                  _vm.returnInputs.options_status !== 'Pertemuan' &&
                  _vm.currentTab === 'Contact Sales'
                )?_c('span',{staticClass:"material-icons-outlined mb-2 mr-1",staticStyle:{"font-size":"20px"}},[_vm._v("whatsapp")]):_vm._e(),_c('h5',{staticClass:"py-0 px-0"},[(_vm.currentTab === 'Register')?_c('span',[_vm._v(" "+_vm._s(_vm.page_settings.title.register)+" ")]):_vm._e(),(_vm.currentTab === 'Contact Sales')?_c('span',[_vm._v(" "+_vm._s(_vm.page_settings.buttons.send)+" ")]):_vm._e()])])],1),_c('div',{staticClass:"col-12 py-1 mb-3",staticStyle:{"border-bottom":"2px solid #0052b4"}}),_c('div',{staticClass:"col-12 mx-0 px-0 d-flex justify-content-center"},[_c('div',{staticClass:"row mx-0 px-0"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.page_settings.question_login)+" ")]),_c('div',{staticClass:"col-12 mx-0 px-0 d-flex justify-content-center openSansSemiBold"},[_c('vs-button',{attrs:{"block":"","transparent":""},on:{"click":function($event){return _vm.login()}}},[_c('h5',[_vm._v("Login")])])],1)])])])])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }