<template>
  <div v-if="data_copied">
    <!-- WEB -->
    <div class="px-0 mx-0" v-if="returnOptions">
      <transition name="slide">
        <div
          v-show="returnOptions.scroll_dir === 'up'"
          class="container-nav"
          :class="returnBGOptions"
        >
          <nav>
            <div class="container px-4 px-md-0">
              <div class="row align-items-center init-container">
                <div
                  class="col-lg-1 col-6 d-flex justify-content-start pointer"
                >
                  <img
                    :src="logowhite"
                    alt=""
                    style="max-width: 125px"
                    @click="goToRoute('/', false, null, 'internal_url')"
                  />
                </div>
                <div
                  class="col-11 d-flex align-items-center justify-content-end openSansBold"
                  v-if="$screen.xl"
                >
                  <!-- LINKS -->
                  <span
                    v-for="(link, index) in page_settings.links"
                    :key="index + 'links'"
                    class="mr-3 py-2 d-flex align-items-center"
                    :class="[
                      link.type === 'item' ? 'item' : 'button',
                      { active: link.path === returnCurrentRoute },
                    ]"
                  >
                    <span
                      v-if="
                        link.path_type != 'internal_url' &&
                        link.path_type != 'external_url'
                      "
                      @click="
                        goToRoute(
                          link.path,
                          link.with_children,
                          index,
                          link.path_type
                        )
                      "
                    >
                      {{ link.name }}
                    </span>
                    <router-link
                      v-if="link.path_type == 'internal_url'"
                      :to="link.path"
                      style="text-decoration: none; color: inherit"
                    >
                      {{ link.name }}
                    </router-link>
                    <a
                      v-if="link.path_type == 'external_url'"
                      :href="link.path"
                      target="_blank"
                      style="text-decoration: none; color: inherit"
                    >
                      {{ link.name }}
                    </a>
                    <span
                      class="material-icons-round dropdown"
                      v-if="link.children !== undefined"
                      :class="{
                        more: index === chosen_child_drop,
                        less: index !== chosen_child_drop,
                      }"
                      >expand_more</span
                    >
                    <transition name="slide-container">
                      <div
                        v-if="index === chosen_child_drop"
                        class="dropdown-container"
                        v-on-clickaway="child_away"
                      >
                        <ul
                          class="openSansMedium"
                          v-for="(child, index) in link.children"
                          :key="index + 'child'"
                        >
                          <li @click="goToRoute(child.path)">
                            {{ child.name }}
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </span>
                  <!-- END OF LINKS -->
                  <!-- LANGUAGE -->
                  <span
                    class="d-flex align-items-center"
                    @click="drop_lang = !drop_lang"
                  >
                    <img
                      :src="returnLanguage == 'EN' ? en_icon : id_icon"
                      alt=""
                      class="flag-icons"
                    />
                    {{ returnLanguage }}
                    <span
                      class="material-icons-round dropdown"
                      :class="{ more: drop_lang, less: !drop_lang }"
                      >expand_more</span
                    >
                    <transition name="slide-container">
                      <div
                        v-if="drop_lang"
                        class="dropdown-container"
                        v-on-clickaway="language_away"
                      >
                        <ul
                          class="openSansMedium"
                          v-for="(lang, index) in page_settings.lang_options"
                          :key="index + 'lang'"
                        >
                          <li
                            @click="languageSwitch(lang.abbrev)"
                            :class="{
                              active: lang.abbrev === returnLanguage,
                            }"
                          >
                            {{ lang.name }}
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </span>
                  <!-- END OF LANGUAGE -->
                  <span
                    class="material-icons-round"
                    @click="openSearchDialog()"
                  >
                    search
                  </span>
                  <!-- TODO -->
                </div>
                <div
                  class="col d-flex justify-content-end hamburger-container"
                  v-if="!$screen.xl"
                >
                  <span class="mr-2 my-2">
                    <a
                      class="mr-4 button py-2 openSansSemiBold"
                      href="http://portal.indoinfo.co.id/login"
                      target="_blank"
                      style="text-decoration: none; color: inherit"
                    >
                      Login
                    </a>
                  </span>

                  <div class="hamburger-lines" @click="dropMenu()">
                    <span
                      class="line line1"
                      :class="{ active: should_drop }"
                    ></span>
                    <span
                      class="line line2"
                      :class="{ active: should_drop }"
                    ></span>
                    <span
                      class="line line3"
                      :class="{ active: should_drop }"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </transition>
    </div>
    <!-- END OF WEB -->
    <!--  MOBILE -->
    <div>
      <transition name="slide-up" mode="out-in">
        <div class="drop-menu-container" v-show="should_drop">
          <ul style="list-style: none; color: #fff" class="openSansBold">
            <li class="py-2 mt-2">
              <div class="row">
                <div class="col-12">
                  <div class="custom-switcher px-3">
                    <div class="row p-2">
                      <div
                        class="col-6 d-flex px-0 mx-0 justify-content-center align-items-center"
                        v-for="(lang, index) in page_settings.lang_options"
                        :key="index + 'lang'"
                        @click="languageSwitch(lang.abbrev)"
                        :class="{ active: returnLanguage === lang.abbrev }"
                      >
                        <img
                          :src="lang.abbrev == 'EN' ? en_icon : id_icon"
                          alt=""
                          class="flag-icons"
                        />
                        {{ lang.abbrev }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="py-2 px-3">
              <div class="row">
                <div class="col-12 px-0">
                  <b-form-input
                    :class="{ hide: !show_search }"
                    size="lg"
                    placeholder="Search Companies"
                    id="searchinput"
                    style="padding-right: 40px"
                    @click="openSearchDialog()"
                  ></b-form-input>
                </div>
                <span
                  class="material-icons d-flex justify-content-end align-items-center"
                  style="
                    color: #007bff;
                    cursor: pointer;
                    margin-left: -50px;
                    z-index: 1;
                  "
                  >search</span
                >
              </div>
            </li>
            <!-- LINKS -->
            <li
              class="pt-2"
              v-for="(link, index) in page_settings.links"
              :key="index + 'links'"
            >
              <h4>
                <span
                  v-if="
                    link.path_type != 'internal_url' &&
                    link.path_type != 'external_url'
                  "
                  @click="
                    goToRoute(
                      link.path,
                      link.with_children,
                      index,
                      link.path_type
                    )
                  "
                >
                  {{ link.name }}

                  <span
                    class="material-icons-round dropdown"
                    v-if="link.children !== undefined"
                    :class="{
                      more: index === chosen_child_drop,
                      less: index !== chosen_child_drop,
                    }"
                  >
                    expand_more
                  </span>
                </span>
                <div
                  v-if="link.path_type == 'internal_url'"
                  @click="should_drop = false"
                >
                  <router-link
                    :to="link.path"
                    style="text-decoration: none; color: inherit"
                  >
                    {{ link.name }}
                  </router-link>
                </div>

                <div
                  v-if="link.path_type == 'external_url'"
                  @click="() => (should_drop = false)"
                >
                  <a
                    :href="link.path"
                    target="_blank"
                    style="text-decoration: none; color: inherit"
                  >
                    {{ link.name }}
                  </a>
                </div>
              </h4>

              <ul
                v-if="
                  link.children !== undefined &&
                  link.children.length > 0 &&
                  chosen_child_drop === index
                "
                v-on-clickaway="child_away"
              >
                <li
                  v-for="(child, index) in link.children"
                  :key="index + 'child'"
                  @click="goToRoute(child.path)"
                >
                  {{ child.name }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <!-- END OF MOBILE -->
  </div>
</template>

<script>
import logo_img from "@/assets/logo_white_transparent.png";
import logo_white from "@/assets/logos/logo_white_full.svg";
import CompanySearch from "./CompanySearch.vue";
// import logo_blue from "@/assets/logos/logo_blue_full.svg";

import { mixin as clickaway } from "vue-clickaway";
import { mapGetters } from "vuex";
import en_ic from "@/assets/flags/en.png";
import id_ic from "@/assets/flags/id.png";

export default {
  mixins: [clickaway],
  components: {
    CompanySearch,
  },
  props: {
    scroll_direction: String,
    current_scroll: Number,
    page_settings_default: Object,
  },
  data() {
    return {
      data_copied: false,
      page_settings: {},
      logo: logo_img,
      logowhite: logo_white,
      drop_lang: false,
      should_drop: false,
      chosen_child_drop: "",
      loading: null,
      show_search: false,
      searched: "",
      transparent_enabled_path: [
        "/",
        "/articles",
        "/article",
        "/whyindoinfo",
        "/services",
        "/whistleblow",
      ],
      transparent_disabled_path: ["/search", "/company", "/search-company-redirect/*"],
      en_icon: en_ic,
      id_icon: id_ic,
    };
  },
  methods: {
    copyPageSettings() {
      this.page_settings = this.page_settings_default;
      this.data_copied = true;
    },
    dropMenu() {
      if (this.should_drop) {
        this.should_drop = false;
      } else {
        this.should_drop = true;
      }
    },
    child_away() {
      this.chosen_child_drop = "";
    },
    language_away() {
      this.drop_lang = false;
    },
    languageSwitch(lang) {
      // if (lang === "EN") {
      //   window.open("https://en.indoinfo.co.id/", "_blank");
      // }
      // TODO: WHEN NEW LANGUAGE COMES: DELETE THE ABOVE AND REMOVE THIS.
      this.current_lang = lang;
      this.$store.dispatch("CHANGE_LANGUAGE", lang);
      this.openLoading("Switching Language", "scale");
    },
    openSearchDialog() {
      this.$store.dispatch("cs/CHANGE_DIALOG_STATE", true);
      this.should_drop = false;
    },
    openLoading(text, type) {
      setTimeout(() => {
        this.loading = this.$vs.loading({
          type: type,
          text: text,
        });
      }, 300);

      setTimeout(() => {
        this.loading.close();
        this.loading = null;
        this.should_drop = false;
      }, 1500);
    },
    goToRoute(path, with_children, index, path_type) {
      console.log("opening: ", path, with_children, index);

      if (!with_children) {
        if (this.should_drop) {
          this.should_drop = false;
        }
        if (path_type === "dialog") {
          var x = {
            state: true,
            tab: path,
          };
          console.log("IS DIALOG: ", x);
          this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
        }
        if (path_type === "internal_url") {
          this.$router.push({ path: path });
        }
        if (path_type === "external_url") {
          window.open(path);
        }
      } else if (with_children) {
        console.log("with children");
        this.chosen_child_drop = index;
        console.log("with children: ", this.chosen_child_drop);
      }
    },
  },
  computed: {
    ...mapGetters(["language"]),
    returnCurrentRoute() {
      return this.$route.path;
    },
    returnLanguage() {
      return this.language;
    },
    return_current_child_drop() {
      return this.chosen_child_drop;
    },
    shouldShowSearchContainer() {
      if (this.returnCurrentRoute === "/") {
        if (this.returnOptions.curr_scroll < 150) {
          if (this.show_search) {
            this.show_search = false;
          }
          return false;
        } else if (this.returnOptions.curr_scroll > 150) {
          return true;
        }
      } else {
        return true;
      }
    },
    returnOptions() {
      let self = this;
      // ENABLES SCROLLING TRANSPARENT (SHOWS OR HIDES NAVBAR BASED ON SCROLL ON THIS ROUTE)
      if (this.transparent_enabled_path.includes(this.$route.path)) {
        if (!this.should_drop) {
          var options = {
            scroll_dir: self.scroll_direction,
            curr_scroll: self.current_scroll,
          };
          return options;
        } else {
          var options = {
            scroll_dir: "up",
            curr_scroll: 151,
          };
          return options;
        }
      }
      // DISABLE SCROLLING TRANSPARENT (MAKES NAVBAR FIX ON THIS ROUTE)
      else if (this.transparent_disabled_path.includes(this.$route.path)) {
        var options = {
          scroll_dir: "up",
          curr_scroll: 151,
        };
        return options;
      } else {
        // FIND WILDCARDS
        let transp_wc_path = this.transparent_disabled_path.filter((item) => {
          if (item.includes("*")) {
            let replace_wc = item.replaceAll("*", "");
            if (this.$route.path.includes(replace_wc)) {
              return item;
            }
          }
        });
        let transp_disbl_wc_path = this.transparent_disabled_path.filter(
          (item) => {
            if (item.includes("*")) {
              let replace_wc = item.replaceAll("*", "");
              if (this.$route.path.includes(replace_wc)) {
                return item;
              }
            }
          }
        );
        if (transp_wc_path.length > 0) {
          var options = {
            scroll_dir: "up",
            curr_scroll: 151,
          };
          return options;
        }
        if (transp_disbl_wc_path.length > 0) {
          var options = {
            scroll_dir: "up",
            curr_scroll: 151,
          };
          return options;
        }
      }
    },
    returnBGOptions() {
      if (this.returnOptions.curr_scroll > 150) {
        return "solid";
      }
      if (this.returnOptions.curr_scroll < 150) {
        return "transparent";
      }
    },
  },
  mounted() {
    this.copyPageSettings();
  },
  watch: {
    page_settings_default: {
      deep: true,
      handler(newVal, oldVal) {
        this.copyPageSettings();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container-nav {
  position: fixed;
  z-index: 100;
  width: 100%;
  color: #fff;
  transition: all 250ms ease-in-out;
  height: 60px;
  &.solid {
    background-color: #0051b4 !important;
  }
  &.transparent {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  &.solid-gradient {
    background: #000;
    background: rgb(21, 17, 54);
    background: linear-gradient(
      18deg,
      rgba(0, 82, 180, 1) 0%,
      rgb(68, 0, 164) 100%
    );
  }

  .init-container {
    min-height: 100%;
    height: 60px;
  }
  img {
    min-height: 50px;
    height: 50px;
    width: auto;

    &.flag-icons {
      min-height: 20px !important;
      height: 20px !important;
      margin-right: 5px;
    }
  }
  span {
    cursor: pointer;
    .more {
      transition: all 250ms ease-in-out;
      transform: rotate(180deg);
    }
    .less {
      transition: all 250ms ease-in-out;
      transform: rotate(0deg);
    }
  }

  .item {
    font-size: 0.9em;
    transition: all 250ms ease-in-out;
    &.active {
      color: #fff;
      padding-bottom: 5px !important;
      padding-top: 5px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      border-radius: 10px;
      background: #0068e7;
    }

    &:hover {
      color: var(--lightblue) !important;
    }
    &::after {
      display: block;
      content: "";
      border-bottom: solid 3px #ffffff;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
    &:hover:after {
      transform: scaleX(1);
    }
  }

  .button {
    border: 1px solid #fff;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-size: 0.9em;

    &:hover {
      background-color: #003c86;
      color: #fff;
      border: 1px solid #003c86;
      transition: all 250ms ease-in-out;
    }
  }

  .dropdown-container {
    position: absolute;
    background-color: #0052b4;
    margin-top: 55px;
    top: 0;
    width: auto;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 30px 30px 1px rgba(45, 45, 45, 0.137);
    transition: all 150ms ease-in-out;
    ul {
      list-style: none;
      padding: 0;

      margin: 0px;
      li {
        padding-left: 20px;
        padding-right: 20px;
        transition: all 150ms ease-in-out;
        padding-bottom: 10px;
        padding-top: 20px;

        &:hover {
          background-color: #003c86;
        }

        &.active {
          background-color: #003c86;
        }
      }
    }
  }
}

.hamburger-container {
  .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #fff;
    }

    .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .line1.active {
      transform: rotate(45deg);
    }

    .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .line2.active {
      transform: scaleY(0);
    }

    .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
    .line3.active {
      transform: rotate(-45deg);
    }
  }
}

.drop-menu-container {
  background-color: #0052b4 !important;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  padding-top: 100px;
  position: fixed;
  z-index: 99;
  overflow-y: auto;

  img {
    &.flag-icons {
      min-height: 28px !important;
      height: 28px !important;
      width: auto;
      padding: 4px;
    }
  }
  ul {
    padding-left: 10px;
    padding-right: 10px;
    li {
      h1 {
        cursor: pointer;
        .more {
          transition: all 250ms ease-in-out;
          transform: rotate(180deg);
        }
        .less {
          transition: all 250ms ease-in-out;
          transform: rotate(0deg);
        }
      }

      ul {
        li {
          color: rgba(240, 240, 240, 0.425);
          list-style: none;
          font-size: 25px;
          &:hover {
            color: #fff;
          }
        }
      }
      .custom-switcher {
        background-color: black !important;
        border-radius: 25px;
        max-width: 70%;
        .active {
          background-color: #003c86;
          border-radius: 20px;
          padding: 5px;
          transition: all 150ms ease-in-out;
        }
      }
    }
  }
}
// TRANSITIONS
// SLIDE UP TO DOWN
// BIGGER TRANSITION (used for the whole nav menu)
.slide-enter-active,
.slide-leave-active {
  transform: translateY(0px);
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-500px);
}

// SMALLER TRANSITION (used for lang drop drown)
.slide-container-enter-active,
.slide-container-leave-active {
  transform: translateY(0px);
}

.slide-container-enter,
.slide-container-leave-to {
  transform: translateY(-5px);
}

// FADE
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// SLIDE UP

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}

// ANIMATION
</style>
