<template>
  <div
    class="container-fluid px-0 mx-0"
    style="padding-top: 50px; padding-bottom: 60px"
  >
    <div class="row mx-0 px-3">
      <div class="col-12 d-flex justify-content-center">
        <h2 class="openSansBold">
          {{ left_splitted_header
          }}<span class="emphasis">{{ outlined_text }}</span
          >{{ right_splitted_header }}
        </h2>
      </div>
      <div class="col-12 mt-4">
        <div class="row">
          <div
            class="col-md-4 px-3"
            v-for="(review, index) in reviews"
            :key="index + 'top-2'"
          >
            <div class="comment-card p-4">
              <div class="col-12">
                <span
                  class="material-icons-round"
                  style="color: #0052b4"
                  v-for="(star, index) in review.stars"
                  :key="index + Math.random()"
                  >star_rate</span
                >
              </div>
              <div class="col-12">
                <p>
                  {{ review.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 mt-4">
        <div class="comment-container noselect d-flex">
          <div
            class="comment"
            v-for="(review, index) in reviews"
            :key="index + 'top-2'"
          >
            <div class="">
              <b-card class="">
                <span
                  class="material-icons-round"
                  style="color: #0052b4"
                  v-for="(star, index) in review.stars"
                  :key="index + Math.random()"
                  >star_rate</span
                >
                <b-card-text>
 
                </b-card-text>
              </b-card>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { dragscroll } from "vue-dragscroll";
export default {
  directives: {
    dragscroll,
  },
  props: {
    header: String,
    outlined_text: String,
    reviews: Array,
  },

  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
    };
  },
  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("could not find");
      }
    },
  },
  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch:{
    header(){
      this.CutandDump();
    }
  },
  mounted() {
    this.CutandDump();
  },
};
</script>

<style scoped lang="scss">
.card {
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  min-height: 240px;
  margin: 10px;
  background-color: #f2f2f2;
}
.container {
  overflow: hidden;
}

.comment-container {
  overflow-x: hidden;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  .comment {
    min-width: 500px;
    min-height: 240px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.comment-card {
  // background-color: #f2f7ff;
  border: none;
  cursor: pointer;
  transition: transform ease 300ms;
  box-shadow: 0px 0px 10px #0000001f;
  border-radius: 10px;
  font-size: 15px;
  min-height: 180px;
  min-width: 100%;
  max-width: 200px;
}

.comment-card:hover {
  transform: translate(0, -10px);
  box-shadow: 5px -5px 10px #0000001f;
}
</style>