import sampleImage from "@/assets/sample/videoThumbnail.png";
// section: IICONNEX
import ii_connex_thumbnail from "@/assets/products/iiconnex/iiconnex_thumbnail_vid.png";
import iiconnex_product_display01 from "@/assets/products/iiconnex/iiconnex_product_display01.png";
import iiconnex_product_display02 from "@/assets/products/iiconnex/iiconnex_product_display02.png";
import iiconnex_product_display03 from "@/assets/products/iiconnex/iiconnex_product_display03.png";
import iiconnex_product_display01_en from "@/assets/products/iiconnex/iiconnex_product_display01_en.png";
import iiconnex_product_display02_en from "@/assets/products/iiconnex/iiconnex_product_display02_en.png";
import iiconnex_product_display03_en from "@/assets/products/iiconnex/iiconnex_product_display03_en.png";

// section: THUMBNAIL
import lms_thumbnail from "@/assets/products/lms/lms_thumbnail_vid.png";
import lms_product_display01 from "@/assets/products/lms/lms_product_display01.png";
import lms_product_display02 from "@/assets/products/lms/lms_product_display02.png";

// section: BMA
import bma_main_icon from "@/assets/products/bma/bma_main_icon.png";
import bma_product_display01 from "@/assets/products/bma/bma_product_display01.png";
import bma_product_display02 from "@/assets/products/bma/bma_product_display02.png";
import bma_product_display03 from "@/assets/products/bma/bma_product_display03.png";
import bma_product_display01_en from "@/assets/products/bma/bma_product_display01_en.png";
import bma_product_display02_en from "@/assets/products/bma/bma_product_display02_en.png";
import bma_product_display03_en from "@/assets/products/bma/bma_product_display03_en.png";
// section: VSS
import vss_thumbnail from "@/assets/products/vss/vss_thumbnail_vid.png";
import vss_product_display01 from "@/assets/products/vss/vss_product_display01.png";
import vss_product_display02 from "@/assets/products/vss/vss_product_display02.png";
import vss_product_display03 from "@/assets/products/vss/vss_product_display03.png";
import vss_product_display01_en from "@/assets/products/vss/vss_product_display01_en.png";
import vss_product_display02_en from "@/assets/products/vss/vss_product_display02_en.png";
import vss_product_display03_en from "@/assets/products/vss/vss_product_display03_en.png";
// section: IIREPORT
import iireport_product_display01 from "@/assets/products/iireport/iireport_product_display01.png";
import iireport_product_display02 from "@/assets/products/iireport/iireport_product_display02.png";
import iireport_product_display03 from "@/assets/products/iireport/iireport_product_display03.png";
import iireport_product_display01_en from "@/assets/products/iireport/iireport_product_display01_en.png";
import iireport_product_display02_en from "@/assets/products/iireport/iireport_product_display02_en.png";
import iireport_product_display03_en from "@/assets/products/iireport/iireport_product_display03_en.png";
// section: API
import api_product_display01 from "@/assets/products/api/API_product_display01.png";
import api_product_display02 from "@/assets/products/api/API_product_display02.png";
import api_product_display03 from "@/assets/products/api/API_product_display03.png";
import api_product_display01_en from "@/assets/products/api/API_product_display01_en.png";
import api_product_display02_en from "@/assets/products/api/API_product_display02_en.png";
import api_product_display03_en from "@/assets/products/api/API_product_display03_en.png";
// section: LEAD GEN
import lead_gen_display01 from "@/assets/products/lead_gen/lead_gen_display01.png";
import lead_gen_display02 from "@/assets/products/lead_gen/lead_gen_display02.png";
import lead_gen_display03 from "@/assets/products/lead_gen/lead_gen_display03.png";
import lead_gen_display01_en from "@/assets/products/lead_gen/lead_gen_display01_en.png";
import lead_gen_display02_en from "@/assets/products/lead_gen/lead_gen_display02_en.png";
import lead_gen_display03_en from "@/assets/products/lead_gen/lead_gen_display03_en.png";
// section: icons
import iiconnex_main_icon from "@/assets/products/iiconnex/iiconnex_main_icon.png";
import api_main_icon from "@/assets/products/api/api_main_icon.png";
import lms_main_icon from "@/assets/products/lms/lms_main_icon.png";
import dr_main_icon from "@/assets/products/dr/dr_main_icon.png";
import iireport_main_icon from "@/assets/products/iireport/iireport_main_icon.png";
import iipentest_main_icon from "@/assets/products/iipentest/iipentest_main_icon.png";
import vss_main_icon from "@/assets/products/vss/vss_main_icon.png";
import csap_main_icon from "@/assets/products/csap/csap_main_icon.png";
import iicollect_main_icon from "@/assets/products/iicollect/iicollect_main_icon.png";
import marketresearch_main_icon from "@/assets/products/marketresearch/marketresearch_main_icon.png";
import huawei_main_icon from "@/assets/products/huawei/huawei_main_icon.png";
import lead_gen_main_icon from "@/assets/products/lead_gen/lead_gen_main_icon.png";
// errors
import error_image from "@/assets/products/errors/image_not_found.png";

var features = [
  {
    id: "iiPentest",
    sources: [
      {
        video: "",
        img: error_image,
        img_en: error_image,
      },
    ],
  },
  {
    id: "VSS",
    sources: [
      {
        type: "yt_video",
        video_ID: "GqNHv26YMBk",
        thumbnail: vss_thumbnail,
      },
      {
        type: "image",
        img: vss_product_display01,
        img_en: vss_product_display01_en,
      },
      {
        type: "image",
        img: vss_product_display02,
        img_en: vss_product_display02_en,
      },
      {
        type: "image",
        img: vss_product_display03,
        img_en: vss_product_display03_en,
      },
    ],
  },
  {
    id: "BMA",
    sources: [
      {
        type: "image",
        img: bma_product_display01,
        img_en: bma_product_display01_en,
      },
      {
        type: "image",
        img: bma_product_display02,
        img_en: bma_product_display02_en,
      },
      {
        type: "image",
        img: bma_product_display03,
        img_en: bma_product_display03_en,
      },
    ],
  },
  {
    id: "iiConnex",
    sources: [
      {
        type: "image",
        img: iiconnex_product_display01,
        img_en: iiconnex_product_display01_en,
      },
      {
        type: "image",
        img: iiconnex_product_display02,
        img_en: iiconnex_product_display02_en,
      },
      {
        type: "image",
        img: iiconnex_product_display03,
        img_en: iiconnex_product_display03_en,
      },
      {
        type: "yt_video",
        video_ID: "7kAN7HNnOtQ",
        thumbnail: ii_connex_thumbnail,
      },
    ],
  },
  {
    id: "iiAPI",
    sources: [
      {
        type: "image",
        img: api_product_display01,
        img_en: api_product_display01_en,
      },
      {
        type: "image",
        img: api_product_display02,
        img_en: api_product_display02_en,
      },
      {
        type: "image",
        img: api_product_display03,
        img_en: api_product_display03_en,
        button: {
          text: "Unduh formulir",
          text_en: "Download Form",
          external: true,
          type: "view-pdf",
          link: "./documents/pdf/form/API_application_form.pdf",
        },
      },
    ],
  },
  {
    id: "LG",
    sources: [
      {
        type: "image",
        img: lead_gen_display01,
        img_en: lead_gen_display01_en,
      },
      {
        type: "image",
        img: lead_gen_display02,
        img_en: lead_gen_display02_en,
      },
      {
        type: "image",
        img: lead_gen_display03,
        img_en: lead_gen_display03_en,
      },
    ],
  },
  {
    id: "iiMarketResearch",
    sources: [
      {
        video: "",
        img: error_image,
      },
    ],
  },
  {
    id: "iiReport",
    sources: [
      {
        type: "image",
        img: iireport_product_display01,
        img_en: iireport_product_display01_en,
      },
      {
        type: "image",
        img: iireport_product_display02,
        img_en: iireport_product_display02_en,
      },
      {
        type: "image",
        img: iireport_product_display03,
        img_en: iireport_product_display03_en,
        button: {
          text: "Lihat Contoh Laporan",
          text_en: "View Example Report",
          external: true,
          type: "view-pdf",
          link: "./documents/pdf/sample_ii_report.pdf",
        },
      },
    ],
  },
  {
    id: "iiCollect",
    sources: [
      {
        video: "",
        img: error_image,
        img_en: error_image,
      },
    ],
  },
  {
    id: "CSAP",
    sources: [
      {
        video: "",
        img: error_image,
        img_en: error_image,
      },
    ],
  },

  // UNUSED
  // {
  //     id: 'DR',
  //     sources: [
  //         {
  //             video: '',
  //             img: image2
  //         }
  //     ],
  // },
  // {
  //     id: 'LMS',
  //     sources: [
  //         {
  //             type: 'yt_video',
  //             video_ID: '9Rs3trcF13s',
  //             thumbnail: lms_thumbnail,
  //
  //         },
  //         {
  //             type: 'image',
  //             img: lms_product_display01,
  //
  //         },
  //         {
  //             type: 'image',
  //             img: lms_product_display02,
  //
  //         },
  //     ],
  // },
  // {
  //     id: 'CS',
  //     sources: [
  //         {
  //             video: '',
  //             img: image2
  //         }
  //     ],
  // },
];
var images = {
  vss: vss_main_icon,
  bma: bma_main_icon,
  iiconnex: iiconnex_main_icon,
  api: api_main_icon,
  lms: lms_main_icon,
  dr: dr_main_icon,
  iireport: iireport_main_icon,
  iipentest: iipentest_main_icon,
  csap: csap_main_icon,
  iicollect: iicollect_main_icon,
  marketresearch: marketresearch_main_icon,
  error: error_image,
  huawei: huawei_main_icon,
  lead_gen: lead_gen_main_icon,
};

export { images, features };
