<template>
  <div></div>
</template>

<script>
import axios from "axios";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      company_data_url: `https://gateway.indoinfo.co.id/api/detiknews?companyname=pt%20antam`,
      company_count: `https://gateway.indoinfo.co.id/api/company/count`,
      litigation_count: `https://gateway.indoinfo.co.id/api/litigation/count`,
      articles: "article/active?src=indoinfo",
      new_endpoint: `https://gateway.indoinfo.co.id/api/indoinfo/news?companyname=pertamina%20`,
    };
  },
  methods: {
    // async getDataWithCaptcha() {
    //   setTimeout(() => {
    //     console.log("CORS INITIALIZING (GETTING DATA WITH CAPTCHA) ");
    //     let self = this;
    //     load(self.returnSiteConfig.cpt, { autoHideBadge: true }).then(
    //       (recaptcha) => {
    //         recaptcha
    //           .execute("get")
    //           .then((token) => {
    //             console.log("GET DATA TOKEN: ", token);
    //             axios
    //               .get(self.returnSiteConfig.e_p+self.articles, {
    //                 params: {
    //                   init_token: token,
    //                 },
    //               })
    //               .then((data) => {
    //                 console.log("CORS TESTING RESULT", data);
    //               })
    //               .catch((error) => {
    //                 throw error;
    //               })
    //               .catch((error) => {
    //                 console.log("CORS TESTING get data token error", error);
    //               });
    //           })
    //           .catch((error) => {
    //             console.log("CORS TESTING get data recaptcha error", error);
    //           });
    //       }
    //     );
    //   }, 5000);
    // },
    async getData() {
      console.log("CORS----------CORS GET");

      axios.get(this.new_endpoint).then((res) => {
        console.log("CORS----------CORS TESTING RESULTS", res.data);
      }).catch((err)=>{
        console.log("CORS----------CORS TESTING", err);
      });
      // let results = await axios
      //   .get(this.new_endpoint)
      //   .then((data) => {
      //     return data;
      //   })
      //   .catch((error) => {
      //     throw error;
      //   });

      // await fetch(
      //   "https://gateway.indoinfo.co.id/api/detiknews?companyname=antam"
      // )
      //   .then(
      //     (resp) => resp.json() // this returns a promise
      //   )
      //   .then((repos) => {
      //     console.log(repos);
      //   })
      //   .catch((ex) => {
      //     console.error(ex);
      //   });
    },
  },
  computed: {
    ...mapGetters(["siteConfig"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
  },
  mounted() {
    // this.getDataWithCaptcha();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
</style>