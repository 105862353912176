<template>
  <div class="overlay white" v-if="open">
    <div style="position: absolute; z-index: 99; right: 10px; top: 10px">
      <span
        class="material-icons-round pointer"
        @click="closeVideo()"
        style="color: #111"
        >close</span
      >
    </div>
    <div class="video-container row" style="height: 100%">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <carousel-3d
          :space="700"
          :height="580"
          :width="720"
          :clickable="true"
          :controls-visible="true"
        >
          <slide v-for="(slide, i) in returnSourceData" :index="i" :key="i">
            <div
              class="row"
              v-if="slide.type === 'yt_video'"
              style="position: relative"
            >
              <!-- thumbnail -->
              <div
                class="col-12 d-flex justify-content-center align-items-center"
                :class="{ above: !videoPlaying, below: videoPlaying }"
              >
                <div
                  style="z-index: 2; position: absolute; cursor: pointer"
                  @click="showVideo()"
                >
                  <lottie
                    :options="lottieSearchOptions.play"
                    v-on:animCreated="handleAnimation"
                    id="lottie"
                  />
                </div>

                <img :src="slide.thumbnail" alt="" />
              </div>
              <div
                class="col-12 d-flex justify-content-center align-items-center"
                style="position: relative"
                :class="{ above: videoPlaying, below: !videoPlaying }"
              >
                <youtube
                  style="height: 400px"
                  :video-id="slide.video_ID"
                  :autoplay="autoplay"
                  ref="youtube"
                  @playing="playing()"
                  @paused="paused()"
                  @ready="playerReady()"
                  @ended="videoended()"
                ></youtube>
              </div>
            </div>
            <!-- other images -->
            <div class="row" v-else>
              <img :src="slide.img" v-if="returnLanguage === 'ID'" alt="" />
              <img :src="slide.img_en" v-if="returnLanguage === 'EN'" alt="" />
              <div
                class="col-12 d-flex justify-content-center align-items-center"
                v-if="
                  slide.description !== undefined || slide.button !== undefined
                "
              >
                <span
                  style="position: relative; color: #0052b4"
                  v-if="slide.description !== undefined"
                >
                  {{ slide.description }}
                </span>

                <vs-button
                  v-if="slide.button !== undefined"
                  class="openSansMedium"
                  @click="openLink(slide.button.type, slide.button.link)"
                >
                  {{ returnLanguage == 'ID' ?  slide.button.text : slide.button.text_en }}
                </vs-button>
              </div>
            </div>
          </slide>
        </carousel-3d>
      </div>
      <!-- close button -->
      <div
        class="col-12 d-flex justify-content-center align-items-center mb-5 mb-lg-0 close-icon poppinsMedium"
        style="margin-top: -40px"
      >
        <vs-button @click="closeVideo()" circle icon floating dark>
          <span class="material-icons-round">close</span>
          Close
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import videoThumbnail from "@/assets/sample/videoThumbnail.png";
import * as animationPlay from "@/assets/animations/play.json";
import lottie from "vue-lottie/src/lottie.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    open: Boolean,
    source: Array,
    product_id: String,
  },
  components: {
    lottie,
  },
  data() {
    return {
      videoId: "lG0Ys-2d4MA",
      autoplay: 1,
      test: "",
      anim: null, // for saving the reference to the animation
      slides: ["test", "test2", "test3"],
      lottieSearchOptions: {
        play: {
          animationData: animationPlay.default,
        },
      },
      videoPlaying: false,
    };
  },
  methods: {
    // create a function to for loop through slides in data and return the first array item that has a type of yt_video and call the function name whatsup
    // returnSourceData() {
    //   return this.source.filter((slide) => {
    //     return slide.type === "yt_video";
    //   });
    // },
    handleAnimation(anim) {
      this.anim = anim;
    },
    openSlideFunc() {
      console.log("slide main test");
    },
    async showVideo() {
      this.videoPlaying = true;
      if (await this.playerReady) {
        this.playVideo();
      }
    },
    openLink(type, link) {
      if (type === "dialog") {
        var x = {
          state: true,
          tab: link,
        };
        this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
      }
      if (type === "view-pdf") {
        window.open(link, "_blank", "fullscreen=yes");
      }
    },
    closeVideo() {
      console.log("clicked");
      this.$emit("update:open", false);
    },
    playerReady() {
      return true;
    },
    async playVideo() {
      await this.player.playVideo();
    },
    playing() {
      console.log("o/ we are watching!!!");
    },
    async paused() {
      console.log("video paused");
      this.videoPlaying = false;
    },

    async videoended() {
      console.log("video ended");
    },
  },
  computed: {
    ...mapGetters({
      language: "language",
    }),
    returnLanguage() {
      return this.language;
    },
    player() {
      return this.$refs.youtube[0].player;
    },
    returnSourceData() {
      let self = this;
      var x = this.lodash.filter(self.source, function (o) {
        return o.id === self.product_id;
      });
      console.log("testing x: ", x);
      console.log("testing source: ", this.source);
      console.log("testing pid: ", this.product_id);
      return x[0].sources;
    },
  },
};
</script>

<style lang="scss" scoped>
.below {
  z-index: 0;
  position: absolute;
  opacity: 0;
}

.above {
  z-index: 2;
  position: absolute;
}
button.VueCarousel-dot {
  background-color: rgba(255, 0, 0, 0.74) !important;
}
.overlay {
  top: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 104;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.close-icon {
  color: #fff;
  cursor: pointer;
  font-size: 30px;
}

.carousel-3d-container {
  .carousel-3d-slide {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    border: none;
    overflow-y: hidden !important;
    .title {
      font-size: 10px !important;
    }
    .comment {
      font-size: 10px !important;
      max-height: 40px;
    }
  }
}

.circles {
  border-radius: 100%;
  background-color: #c4c4c4;
  min-height: 10px;
  min-width: 10px;
  max-height: 10px;
  max-width: 10px;
}

.circles.active {
  background-color: #0052b4;
}
</style>
