<template>
  <div
    class="container-fluid px-0 mx-0"
    style="padding-bottom: 50px; padding-top: 50px"
  >
    <div class="container">
      <div class="row px-2 mx-0 align-self-center">
        <div class="col-12 d-flex justify-content-center">
          <h2 class="openSansBold text-center">
            {{ left_splitted_header
            }}<span class="emphasis">{{ outlined_header }}</span
            >{{ right_splitted_header }}
          </h2>
        </div>
        <div class="col-12 py-3 d-flex justify-content-center">
                   <img
                        src="@/assets/others/reason_image_tab.png"
                        style="max-width: 100%; max-height: 100%;"
                        alt=""/>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <div>
            <div
              class="card"
              v-for="(accordion, index) in accordions"
              :key="index + 'accordion'"
              style="min-width: 70vw; max-width: 70vw"
            >
              <div
                class="card-header pointer"
                @click="dropAccordion(accordion, index)"
                v-if="accordion.title !== false"
              >
                <div class="row">
                  <div
                    class="col-10 d-flex justify-content-start openSansMedium"
                  >
                    {{ accordion.title }}
                  </div>
                  <div
                    class="col-2 d-flex justify-content-end align-items-center"
                  >
                    <span
                      class="material-icons-round"
                      :class="{
                        rotate: accordion.body_show,
                        unrotate: !accordion.body_show,
                      }"
                      >keyboard_arrow_down</span
                    >
                  </div>
                </div>
              </div>

              <div
                id="collapseOne"
                class="collapse"
                :class="{ show: accordion.body_show }"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="row">
                    <!-- <div
                      class="
                        col-12 col-md-6
                        d-flex
                        justify-content-center
                        align-items-center
                      "
                      v-if="accordion.list"
                    >
                      <img
                        src="@/assets/others/reason_image_tab.png"
                        width="250px"
                        alt=""
                      />
                    </div> -->
                    <div
                      :class="[
                        {
                          'col-12': !accordion.list,
                          'col-12 col-md-6': accordion.list,
                        },
                      ]"
                    >
                      <div
                        v-for="(body, index) in accordion.body"
                        :key="'accordion-body' + index"
                        class="row"
                      >
                        <div class="col-12 my-2" v-if="!accordion.list">
                          {{ body }}
                        </div>
                        <div class="col-12" v-if="accordion.list">
                          <ul>
                            <li style="list-style: none" >{{ body }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomDropDown from "@/components/utils/CustomDropDown.vue";

export default {
  components: {
    CustomDropDown,
  },
  props: {
    header: String,
    outlined_text: String,
    description: String,
    accordions: Array,
    current_language: String,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      currentoption: {},
      current_index: "",
      drop_menu: false,
    };
  },
  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("could not find");
      }
    },
    dropAccordion(accordion, index) {
      var copy_accordion = this.accordions;
      copy_accordion[index].body_show = !this.accordions[index].body_show;
      // this.$emit("update:accordion", )
      console.log("original accordion: ", accordion);
      console.log("copy accordion: ", accordion);
      console.log("index: ", index);
    },
  },
  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    current_language(val) {
      console.log('REASON SECTION LANG CHANGED: ', val);
      this.CutandDump();
    },
  },
  mounted() {
    this.CutandDump();
  },
};
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 5px 5px 2px #ffffff1f;
  border-radius: 0px;
}
.card-header {
  background-color: #fff;
  color: #0052b4;
}

.card-body {
  background-color: #f2f7ff;
}
.rotate {
  transform: rotate(180deg);
  transition: transform 0.1s linear;
}
.unrotate {
  transform: rotate(0deg);
  transition: transform 0.1s linear;
}
</style>