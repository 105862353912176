<template>
  <div class="container-fluid mx-0 px-0" style="">
    <!-- NORMAL NAVIGATION -->
    <nav class="py-2">
      <div class="d-flex row mx-0" style="width: 10hw">
        <!-- CHANGE LANGUAGE -->
        <div class="col-4 d-flex justify-content-start">
          <div class="language d-flex align-items-center">
            <span class="mx-2" v-if="lang_selected === 'EN'">
              <img :src="us" class="flag" alt="" />
            </span>
            <span class="mx-2" v-if="lang_selected === 'ID'">
              <img :src="id" class="flag" alt="" />
            </span>
            <b-form-select
              v-model="lang_selected"
              :options="lang_options"
              style="border: none !important; cursor: pointer"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                  class="openSansRegular"
                  >Pick a language</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>
        <!-- END OF CHANGE LANGUAGE -->
        <!-- SEARCH -->
        <div class="col-4 d-flex align-items-center">
          <b-form-input
            size="sm"
            id="searchinput"
            style="padding-right: 40px"
            @keydown.enter="goToRoute()"
            v-model="searched"
            :class="{
              active: searched !== '',
            }"
          ></b-form-input>
        </div>
        <span
          class="
            material-icons
            d-flex
            justify-content-end
            align-items-center
            c-primary
          "
          style="
            cursor: pointer;
            margin-left: -50px;
            z-index: 1;
            font-size: 20px;
          "
          >search</span
        >
        <div
          class="
            col-4
            d-flex
            align-items-center
            justify-content-center justify-content-md-end
            py-4 py-lg-0
          "
        >
          <b-button
            variant="outline-primary"
            class=""
            size="sm"
            @click="goToRoute('team-support')"
            >Contact Sales</b-button
          >
          <b-button
            variant="primary"
            class="mx-2"
            size="sm"
            @click="goToRoute('register')"
            >Try for 10 Days</b-button
          >
          <span class="primary pointer">Login</span>
        </div>
        <!-- END OF SEARCH -->

        <!-- MAIN BAR COMPONENT -->

        <!-- LOGO -->
        <div
          class="
            col-2
            mt-2
            d-flex
            justify-content-center justify-content-md-start
          "
        >
          <img :src="logoIMG" alt="" class="logo" />
        </div>
        <!-- END OF LOGO -->
        <!-- CTAs -->

        <div
          class="col-10 mt-2 d-flex justify-content-center align-items-center"
        >
          <span class="mx-3 pointer">All</span>
          <span class="mx-3 pointer">Company Profile</span>
          <span class="mx-3 pointer">Indoinfo Marketplace</span>
          <span class="mx-3 pointer">Site Content</span>
          <span class="mx-3 pointer">Industry</span>
        </div>

        <!-- END OF CTAs -->
      </div>
    </nav>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
import indoflag from "@/assets/flags/id.png";
import usflag from "@/assets/flags/en.png";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      logoIMG: logo,
      id: indoflag,
      us: usflag,
      lang_selected: "EN",
      searched: "",
      lang_options: [
        { value: "EN", text: "English" },
        { value: "ID", text: "Indonesia" },
      ],
      open_overlay_menu: false,
    };
  },
  methods: {
    goToRoute(name) {
      this.$router.push({ name: name });
    },
    megaMenuGoToRoute(link) {
      if (link === undefined) {
        console.log("this link is undefined");
      } else {
        console.log("link: ", link);
      }
    },
    goToRoute() {
      var replaced = this.searched.split(" ").join("+");
      this.$router.push({ path: "/search", query: { q: replaced } });
    },
  },
  computed: {
    ...mapGetters(["language"]),
    returnLanguage() {
      return this.language;
    },
  },
  watch: {
    lang_selected(newVal, oldVal) {
      this.$store.dispatch("CHANGE_LANGUAGE", newVal);
    },
    returnLanguage(newVal, oldVal) {
      console.log("listening the store event", newVal);
    },
  },
};
</script>

<style scoped>
.active {
  background: #fff !important;
}
nav {
  box-shadow: 0px 1px 2px rgba(197, 197, 197, 0.823) !important;
}
.overlay {
  height: 100vh !important;
  background: rgb(30, 167, 255);
  background: linear-gradient(
    0deg,
    rgba(30, 167, 255, 1) 0%,
    rgba(3, 71, 194, 1) 100%
  );
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 50vh;
  padding-top: 4vh;
}

.overlay .row .overlay-menu {
  color: rgba(255, 255, 255, 0.611);
  cursor: pointer;
}

.overlay-active {
  color: #fff;
}

.card.card-menu-list {
  background: #f2f2f2;
  border: none;
  padding: 0px;
  margin-top: 10px;
}
.card.card-container {
  position: absolute;
  min-width: 70vw;
  z-index: 2;
  left: -20%;
  top: 100%;
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.flag {
  max-height: 15px;
}

.language {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: gray;
  cursor: pointer;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.logo {
  height: 100%;
  max-height: 40px;
  width: auto;
}
</style>