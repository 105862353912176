<template>
  <div id="head">
    <div class="bg-container">
      <div
        class="bg mx-0"
        v-bind:style="{ 'background-image': 'url(' + bg + ')' }"
      ></div>
    </div>
    <div
      class="container text-container"
      :style="{ left: textContainerMarginLeft }"
      v-if="dataready"
    >
      <GlobalAnaylstCommentarySection />

      <div class="row px-0 mx-0" style="height: 100%">
        <div class="col-12 d-flex align-items-center">
          <div class="row">
            <div
              class="
                col-12 col-lg-6
                d-flex
                justify-content-start
                text-center text-lg-left
              "
            >
              <h1 class="openSansBold white page-title">
                {{ whyindoinfocontents.banner_section.title }}
              </h1>
            </div>
            <div class="col-12 col-lg-6 text-center text-md-left"></div>
            <div
              class="
                col-12 col-lg-6
                d-flex
                justify-content-start
                text-center text-lg-left
              "
            >
              <div class="row">
                <div
                  class="col-12"
                  v-for="(desc, index) in whyindoinfocontents.banner_section
                    .description"
                  :key="index"
                >
                  <h5 class="openSansMedium white subtitle">
                    {{ desc }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-fluid mx-0 px-0"
      style="z-index: 2; position: absolute; margin-top: 100vh"
    >
      <div class="card">
        <div class="card-body px-0 mx-0 py-0" style="border: none">
          <div>
            <reason-section
              v-if="dataready"
              :header.sync="whyindoinfocontents.reason_section.header"
              :description.sync="whyindoinfocontents.reason_section.description"
              :outlined_text.sync="
                whyindoinfocontents.reason_section.outlined_text
              "
              :whychooseoptions.sync="
                whyindoinfocontents.reason_section.reasons
              "
              :current_language.sync="currentLanguage"
              :accordions.sync="whyindoinfocontents.reason_section.accordions"
            />
          </div>

          <div>
            <comparison-section
              v-if="dataready"
              :header.sync="whyindoinfocontents.comparison_section.header"
              :outlined_text.sync="
                whyindoinfocontents.comparison_section.outlined_text
              "
              :current_language.sync="currentLanguage"
              :headers.sync="whyindoinfocontents.comparison_section.headers"
              :table_rows.sync="
                whyindoinfocontents.comparison_section.table_rows
              "
            />
          </div>
          <div>
            <why-choose-us
              v-if="dataready"
              :header.sync="homecontents.why_choose_section.title"
              :description.sync="homecontents.why_choose_section.description"
              :outlined_text.sync="
                homecontents.why_choose_section.outlined_text
              "
              :whychooseoptions.sync="homecontents.why_choose_section.reasons"
              :current_language.sync="currentLanguage"
            />
          </div>
          <div class="mt-5">
            <brands-section
              v-if="dataready"
              :text_align="'center'"
              :header.sync="homecontents.brands_section.header"
              :outlined_text.sync="homecontents.brands_section.outlined_text"
              :current_language.sync="currentLanguage"
            />
          </div>
          <div>
            <our-partners-section
              v-if="dataready"
              :header.sync="homecontents.our_partners_section.title"
              :outlined_text.sync="
                homecontents.our_partners_section.outlined_text
              "
            />
          </div>
          <div>
            <no-bg-cta-section
              v-if="dataready"
              :text_align="'center'"
              :header.sync="homecontents.no_bg_cta_section.header"
              :outlined_text.sync="homecontents.no_bg_cta_section.outlined_text"
              :cta_text.sync="homecontents.no_bg_cta_section.cta_button_text"
              :current_language.sync="currentLanguage"
            />
          </div>
          <div class="px-0 mx-0">
            <footer-nav
              v-if="dataready"
              :footer_head.sync="utilscontent.footer_nav.footer_head"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import the_bg from "@/assets/bgs/earth_bg1.png";
// IMPORTED FROM WHYINDOINFOCOMPONENTS
import ReasonSection from "@/components/whyindoinfocomponents/ReasonSection.vue";
import ComparisonSection from "@/components/whyindoinfocomponents/ComparisonSection.vue";
import GlobalAnaylstCommentarySection from "@/components/globalcomponents/GlobalAnalystCommentarySection.vue";
// IMPORTED FROM HOMECOMPONENTS
import WhyChooseUs from "@/components/homecomponents/WhyChooseUs.vue";
import BrandsSection from "@/components/homecomponents/BrandsSection.vue";
import OurPartnersSection from "@/components/homecomponents/OurPartnersSection.vue";
import NoBgCtaSection from "@/components/homecomponents/NoBgCtaSection.vue";
import FooterNav from "@/components/utils/FooterNav.vue";
// IMPORTED FROM UTILS

import { mapGetters } from "vuex";
export default {
  components: {
    ReasonSection,
    ComparisonSection,
    WhyChooseUs,
    BrandsSection,
    OurPartnersSection,
    NoBgCtaSection,
    FooterNav,
    GlobalAnaylstCommentarySection,
  },
  data() {
    return {
      bg: the_bg,
      dataready: false,
      currentLanguage: "EN",
      whyindoinfocontents: {},
      homecontents: {},
      utilscontent: {},
    };
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: 'Layanan informasi untuk mitigasi risiko bisnis, berbasis data & analisa yang memberikan solusi efektif bagi organisasi dan perusahaan',
      },
    ],
  },
  methods: {
    iniateSwapLanguage() {
      this.currentLanguage = this.returnCurrentLanguage;
      if (this.returnCurrentLanguage === "EN") {
        this.whyindoinfocontents = this.returnEnglishContent;
        this.homecontents = this.returnEnglishContentHome;
        this.utilscontent = this.returnEnglishContentUtils;
        this.dataready = true;
      } else if (this.returnCurrentLanguage === "ID") {
        this.whyindoinfocontents = this.returnIndonesianContent;
        this.homecontents = this.returnIndonesianContentHome;
        this.utilscontent = this.returnIndonesianContentUtils;
        this.dataready = true;
      }
    },
    scrollToTop() {
      setTimeout(() => {
        const element = document.getElementById("head");
        if (element) {
          element.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      }, 20);
    },
  },
  computed: {
    ...mapGetters([
      "language",
      "wiic/indonesian",
      "wiic/english",
      "hc/indonesian",
      "hc/english",
      "ut/indonesian",
      "ut/english",
    ]),
    returnCurrentLanguage() {
      return this.language;
    },
    returnIndonesianContent() {
      return this["wiic/indonesian"];
    },
    returnIndonesianContentHome() {
      return this["hc/indonesian"];
    },
    returnIndonesianContentUtils() {
      return this["ut/indonesian"];
    },
    returnEnglishContent() {
      return this["wiic/english"];
    },
    returnEnglishContentHome() {
      return this["hc/english"];
    },
    returnEnglishContentUtils() {
      return this["ut/english"];
    },
    textContainerMarginLeft() {
      if (this.$screen.lg) {
        return "10%";
      } else if (this.$screen.md) {
        return "5%";
      } else {
        return "0%";
      }
    },
  },
  watch: {
    returnCurrentLanguage(newVal, oldVal) {
      this.iniateSwapLanguage();
    },
    openvideo(val) {
      console.log("open video status: ", val);
    },
    openvideo_product_id(val) {
      console.log("open video PID: ", val);
    },
  },
  mounted() {
    this.iniateSwapLanguage();
    this.scrollToTop();
  },
};
</script>

<style lang="scss" scoped>
.bg-container {
  position: fixed;
  height: 100vh;
  min-height: 95vh;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  .bg {
    height: 100%;
    min-height: 95vh;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
  }
}

.page-title {
  color: #fff;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 3vw !important;
}

.subtitle {
  font-size: 1.2vw !important;
}

@media only screen and (max-width: 600px) {
  .page-title {
    font-size: 9vw !important;
  }

  .subtitle {
    font-size: 4vw !important;
  }
}
@media only screen and (min-width: 600px) {
  .page-title {
    font-size: 9vw !important;
  }

  .subtitle {
    font-size: 4vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .page-title {
    font-size: 7vw !important;
  }

  .subtitle {
    font-size: 2vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .page-title {
    font-size: 4vw !important;
  }

  .subtitle {
    font-size: 1.5vw !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .page-title {
    font-size: 3vw !important;
  }

  .subtitle {
    font-size: 1vw !important;
  }
}
.text-container {
  position: absolute;
  height: 100vh;
  top: 0%;
  z-index: 9;
}
</style>