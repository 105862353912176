import { features, images } from "@/assets/asset_exports/features_assets.js";
export default {
  namespaced: true,
  state: {
    // indonesian
    indonesian: {
      search_section: {
        header:
          "Mitigasi risiko bisnis Anda, kenali pelanggan dan mitra bisnis Anda",
        outlined_text: "Mitigasi risiko bisnis Anda,",
        placeholder: "Cari Perusahaan",
        subtitle:
          "Dapatkan informasi yang akan membantu perkembangan bisnis Anda",
        subtitle2:
          "Cari tahu lebih lanjut tentang risiko pelanggan Anda untuk tujuan bisnis yang lebih sukses.",
      },
      features_section: {
        title: "Layanan untuk pertumbuhan bisnis yang optimal",
        outlined_title: "optimal",
        default_selections: [
          {
            id: "Popular",
            name: "Populer",
          },
          {
            id: "CR",
            name: "Manajemen Risiko Kredit",
          },
          {
            id: "CY",
            name: "Manajemen Risiko Keamanan Siber",
          },
          {
            id: "GOV",
            name: "Tata Kelola & Kepatuhan",
          },
          {
            id: "M",
            name: "Penjualan & Pemasaran",
          },
        ],
        features: [
          {
            id: "VSS",
            popular: true,
            clickable: true,
            cat: "CR",
            name: "Vendor Selection System",
            title: "MEMINIMALISIR RISIKO UNTUK ALIRAN KAS YANG LEBIH BAIK",
            description:
              "Sistem pelaporan online yang membantu Anda untuk mengevaluasi Vendor dalam memenuhi Kewajiban Bisnis mereka. Anda akan memperoleh informasi Vendor terkait kinerja keuangan mereka, kemampuan pasokan, kapatuhan hukum, dan kemungkinan akses ke lembaga keuangan.",
            url: "",
            tooltip_desc:
              "Meminimalisir Risiko Untuk Aliran Kas Yang Lebih Baik",
            img: images.vss,
            color: "#46978D",
            icon: "storefront",
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Uji Coba Gratis",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "BMA",
            popular: true,
            clickable: true,
            cat: "GOV",
            name: "Business Monitoring Alert",
            title: "PERINGATAN POTENSI MASALAH",
            tooltip_desc: "Peringatan Potensi Kasus Pelanggaran Hukum",
            description:
              "Mengetahui hukuman, penyitaan, dan kerugian materi sebuah perusahaan yang tidak bertindak sesuai dengan hukum dan peraturan.",
            url: "",
            img: images.bma,
            color: "#4B7BEC",
            icon: "assessment",
            facts: [
              "Membantu mengidentifikasi peningkatan risiko yang terkait dengan pelanggan, pemasok, dan mitra bisnis.",
              "Pemeriksaan terkait: kebangkrutan, litigasi, daftar hitam pemerintah, perubahan dalam perusahaan, berita terkait.",
              "Pemeriksaan litigasi perusahaan yang mencakup seluruh pengadilan di Indonesia. (Pengadilan Niaga & Pengadilan Negeri, Pengalidan Tinggi, Mahkamah Agung)",
              "Mengurangi risiko reputasi dan biaya operasional",
            ],
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Uji Coba Gratis",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiConnex",
            popular: true,
            clickable: true,
            cat: "GOV",
            name: "iiConnex",
            title: "KEMUDAHAN MENGIDENTIFIKASI HUBUNGAN PELANGGAN",
            tooltip_desc: "Kemudahan Mengidentifikasi Hubungan Pelanggan",
            description:
              "Menyediakan satu titik akses informasi untuk meneliti dan membuat keputusan kepatuhan menjadi lebih mudah. Melihat tampilan hubungan dan koneksi Perusahaan dan Individu dalam grafik dari pohon keluarga bisnis secara otomatis.",
            url: "",
            img: images.iiconnex,
            color: "#FD9644",
            icon: "analytics",
            // facts: [
            //     "Membantu menganalisa dan uji tuntas mitra bisnis Anda",
            //     "Mengidentifikasi dan memverifikasi target bisnis secara lokal & global",
            //     "Mengidentifikasi kepatuhan bisnis terhadap aturan",
            //     "Mengurangi risiko reputasi dan biaya operasional"
            // ],
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Uji Coba Gratis",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiReport",
            popular: true,
            clickable: true,
            cat: "CR",
            name: "iiReport",
            short_desc: "| Due Dilligence Report",
            title: "UPDATE INFORMASI MITRA ANDA",
            description:
              "Informasi pelanggan dan pemasok yang terintegrasi di seluruh unit bisnis Anda.",
            url: "",
            tooltip_desc: "Uji kelayakan Mitra Bisnis Anda",
            img: images.iireport,
            color: "#FD9644",
            icon: "analytics",
            facts: [
              "Penting dilakukan sebelum melakukan transaksi bisnis bernilai tinggi untuk mengelola risiko dengan lebih baik.",
              "Dapat terintegrasi dengan informasi pelanggan dan pemasok di seluruh unit bisnis Anda, untuk mengukur cakupan total hubungan Anda dengan mitra bisnis Andam individu, atau kelompok perusahaan.",
              "Membantu Anda mengidentifikasi dan memanfaatkan peluang tambahan dengan pelanggan dan pemasok yang merupakan anggota perusahaan yang sama.",
              "Menganalisis pola pembayaran yang seimbang",
            ],
            buttons: [
              {
                text: "Lihat Contoh Laporan",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/pdf/sample_ii_report.pdf",
                type: "view-pdf",
              },
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Uji Coba Gratis",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiAPI",
            popular: true,
            cat: "M",
            clickable: true,
            name: "iiAPI",
            short_desc: "| Application Programming Interface",
            url: "",
            tooltip_desc: "Application Programming Interface",
            img: images.api,
            color: "#FF4D4D",
            description:
              "Menggabungkan data mitra menjadi satu platform dinamis dan terhubung ke catatan tersebut dengan iiNumbers (nomor unik kami). Klien Anda dapat mengakses ke beragam rangkaian data industri melalui satu sumber platform.",
            icon: "hub",
            // facts: [
            //     "Identifikasi target menjadi pelanggan terbaik",
            //     "Menemukan prospek baru dan berikan prospek siap jual",
            //     "Membuat tampilan pelanggan yang akurat dan bebas duplikat",
            // ],
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiMarketResearch",
            cat: "M",
            clickable: false,
            name: "iiMarketResearch",
            url: "",
            description:
              "Upaya untuk mengumpulkan informasi tentang target pasar dan pelanggan. Sebagai strategi bisnis dan faktor utama dalam menjaga daya saing untuk mendapatkan informasi yang lebih detail.",
            img: images.marketresearch,
            color: "#46978D",
            icon: "balance",
            facts: [
              "Mencari pasar baru, mengikuti perkembangan industri terkini, analisis penawaran & permintaan dan berbagai insentif pemerintah yang dapat memenuhi kebutuhan spesifik Anda.",
              "Tim ahli kami akan bekerja sama dengan Anda untuk mencapai tujuan penelitian yang akan mendukung bisnis strategis Anda.",
            ],
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "LG",
            popular: false,
            cat: "M",
            clickable: true,
            name: "Lead Generation",
            short_desc: "",
            url: "",
            tooltip_desc: "Lead Generation",
            img: images.lead_gen,
            color: "#FF4D4D",
            description:
              "Perkaya database Anda dengan informasi kontak pelanggan potensial, seperti panggilan langsung dan email. Mengetahui informasi database perusahaan memungkinkan untuk perencanaan dan eksekusi strategi pemasaran yang lebih efektif, yang akan meningkatkan peluang sukses dalam menarik dan mempertahankan pelanggan.",
            icon: "hub",

            buttons: [
              {
                text: "Unduh Brosur",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/pdf/brochures/lead_generation_brochure.pdf",
                type: "view-pdf",
              },
              {
                text: "Lihat Contoh",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/excel/sample_lead_generation.xlsx",
                type: "view-pdf",
              },
              {
                text: "Coba Gratis",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiCollect",
            cat: "GOV",
            clickable: false,
            name: "iiCollect",
            url: "",
            description:
              "Membantu Anda dalam menjaga kestabilan arus kas, dan mengendalikan proses piutang yang menjadi sumber kehidupan sebuah bisnis. Proses pengumpulan bertujuan untuk meminimalkan risiko ataupun kerugian dari dana yang tertunggak, menjaga kesehatan keuangan perusahaan, membina hubungan dengan mitra bisnis.",
            img: images.iicollect,
            color: "#46978D",
            icon: "balance",
            facts: [
              "Melakukan pemulihan piutang secara instan dengan memanfaatkan teknologi unggulan kami, yang dapat digunakan atas nama perusahaan Anda.",
              "Menganalisis portofolio perusahaan Anda, menggunakan data untuk membuat rencana yang optimal.",
              "Menggunakan kemitraan internasional dan keahlian dalam menawarkan layanan outsourcing manajemen piutang yang bernilai, hemat biaya, dan terintegrasi dengan banyak perusahaan Indonesia.",
              "Anda akan memliki tingkat kualitas internal dengan proses yang efisien dan eksternal (hubungan pelanggan), untuk meningkatkan pengetahuan bisnis Anda secara signifikan.",
            ],
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "huawei",
            cat: "CY",
            clickable: false,
            name: "Huawei Cloud Services",
            short_desc: "",
            url: "",
            description:
              "Kami memberikan layanan konsultan Cloud yang dapat diandalkan. Dengan Huawei sebagai mitra kami, beragam layanan Cloud dan konsultasi yang kami rancang untuk menjembatani organisasi Anda dengan teknologi Huawei. Layanan ini memberdayakan bisnis dengan otomatisasi dan optimalisasi, mulai dari pengoptimalan biaya, data, hingga kepatuhan, keamanan, dan akselerasi bisnis melalui layanan Cloud Huawei yang dapat disesuaikan.",
            img: images.huawei,
            color: "#46978D",
            icon: "balance",
            // facts: [
            //     "Melakukan identifikasi resiko pada platform digital",
            //     "Memelihara dan meningkatkan keamanan platform bisnis Anda",
            //     "Memastikan perbaikan pada celah kebocoran platform digital Anda",
            //     "Mengurangi kendala operasional bisnis yang beresiko pada tindak pidana siber"
            // ],
            buttons: [
              {
                text: "Unduh Brosur",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/pdf/brochures/huawei_cloud_brochure.pdf",
                type: "view-pdf",
              },
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiPentest",
            cat: "CY",
            clickable: false,
            name: "iiPentest",
            short_desc: "| Penetration Testing",
            url: "",
            description:
              "Salah satu alat terpenting yang digunakan perusahaan untuk mempertahankan diri. Pengujian penetrasi adalah keterampilan keamanan siber yang menggunakan teknik yang setara dengan peretas kriminal untuk mendapatkan akses ke sistem TI Anda. Menggunakan metodologi apa pun yang mungkin digunakan penjahat seperti parole cracking, virus, atau rekayasa sosial.",
            img: images.iipentest,
            color: "#46978D",
            icon: "balance",
            // facts: [
            //     "Melakukan identifikasi resiko pada platform digital",
            //     "Memelihara dan meningkatkan keamanan platform bisnis Anda",
            //     "Memastikan perbaikan pada celah kebocoran platform digital Anda",
            //     "Mengurangi kendala operasional bisnis yang beresiko pada tindak pidana siber"
            // ],
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "CSAP",
            cat: "CY",
            clickable: false,
            name: "CSAP",
            short_desc: "| Cyber Security Awareness Program",
            url: "",
            description:
              "Program kesadaran keamanan siber yang memberikan pemahaman dan pengetahuan kepada pengguna tentang risiko keamanan siber, serta ancaman yang dihadapi organisasi ataupun jaringan. Dengan program ini, peserta akan mendapat pemahaman konsep keamanan dan kemampuan praktis dalam mengendalikan risiko siber, serta mengamankan informasi digital dalam organisasi.",
            img: images.csap,
            color: "#46978D",
            icon: "balance",
            facts: [
              "Kesadaran Pengguna",
              "Serangan Simulasi",
              "Lokakarya Pengguna",
              "Pembaruan Insiden",
            ],
            buttons: [
              {
                text: "Pelajari Lebih Lanjut",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
        ],
        features_description: [
          {
            name: "Manajemen Risiko Kredit",
            points: [
              "Memahami kelayakan kredit pelanggan.",
              "Mengidentifikasi peluang dan risiko portofolio.",
              "Membantu menganalisa keputusan dengan terstruktur dan terencana.",
              "Menganalisis pola pembayaran yang seimbang.",
            ],
          },
          {
            name: "Manajemen Risiko Keamanan Siber",
            points: [
              "Melakukan identifikasi resiko pada platform digital.",
              "Memelihara dan meningkatkan keamanan platform bisnis Anda.",
              "Memastikan perbaikan pada celah kebocoran platform digital Anda.",
              "Mengurangi kendala operasional bisnis yang beresiko pada tindak pidana siber.",
            ],
          },
          {
            name: "Tata Kelola & Kepatuhan",
            points: [
              "Membantu menganalisa dan uji tuntas mitra bisnis Anda.",
              "Mengidentifikasi dan memverifikasi target bisnis secara lokal & global.",
              "Mengidentifikasi kepatuhan bisnis terhadap aturan.",
              "Mengurangi risiko reputasi dan biaya operasional.",
            ],
          },
          {
            name: "Penjualan & Pemasaran",
            points: [
              "Identifikasi target menjadi pelanggan terbaik.",
              "Menemukan prospek baru dan berikan prospek siap jual.",
              "Membuat tampilan pelanggan yang akurat dan bebas duplikat.",
            ],
          },
        ],
      },
      marketing_stat: [
        {
          title: "",
        },
        {
          description: "",
        },
        {
          big_numbers: "",
        },
      ],
      importance_section: {
        header: "Empat alasan utama pentingnya informasi untuk bisnis anda",
        outlined_text: "pentingnya informasi",
        reasons: [
          {
            id: 1,
            text: "Perencanaan strategi bisnis yang optimal",
          },
          {
            id: 2,
            text: "Membantu menurunkan risiko kerugian bisnis",
          },
          {
            id: 3,
            text: "Mengidentifikasi, mengukur, dan mengendalikan tingkat risiko kredit",
          },
          {
            id: 4,
            text: "Memberikan laporan informasi calon mitra bisnis Anda secara lengkap.",
          },
        ],
      },
      boi_report_section: {
        header: "Dapatkan laporan BOI gratis Anda",
        outlined_text: "BOI",
        description:
          "Business Optimism Index adalah produk yang mengukur denyut nadi komunitas bisnis dan berfungsi sebagai tolak ukur yang dapat diandalkan bagi investor. Seiring waktu, indeks ini muncul sebagai indikator utama titik balik dalam kegiatan ekonomi.",
        cta_button: "Unduh Laporan",
        drop_down_label: "Pilih Laporan",
      },
      compliance_section: {
        header: "Kepatuhan Regulasi dan Registrasi",
        description:
          "Punya pertanyaan atau butuh konsultasi terkait Kepatuhan Regulasi dan Data Pribadi?",
        button: {
          text: "Hubungi Kami",
          external: true,
          type: "view-pdf",
          link: "./documents/pdf/compliance/formcompliance.pdf",
        },
      },
      globe_section: [
        {
          id: "Indonesia",
          title: "Indonesia",
          desc: "Kemudahan akses informasi perusahaan se-Indonesia",
          buttons: [
            {
              text: "Pelajari Lebih Lanjut",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
        {
          id: "Singapore",
          title: "Singapore",
          desc: "Indoinfo berakar dari kantor pusat kami di Singapura, Cyberquote Group",
          buttons: [
            {
              text: "Pelajari Lebih Lanjut",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
        {
          id: "Litigation",
          title: "Litigasi",
          desc: "Bagian penting dari kebijakan Tata Kelola Perusahaan yang Baik (GCG) dan Manajemen Resiko (RM)",
          buttons: [
            {
              text: "Pelajari Lebih Lanjut",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
        {
          id: "Overseas",
          title: "Overseas",
          // amount: 0,
          desc: "Menghadapi tantangan ekonomi global bersama Indoinfo, sebagai penyedia intelijen bisnis untuk pelanggan kami di luar negeri",
          buttons: [
            {
              text: "Pelajari Lebih Lanjut",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
      ],
      case_section: {
        cta_text: "Apakah Bisnis Anda Perlu Solusi?",
        cta_button_text: "Hubungi Tim Kami",
        cases: [
          {
            title: "Riset Pasar",
            description:
              "Memperkenalkan merek Kopi Kapsul Swiss ke pasar Indonesia. Indoinfo menganalisis dan mensurvei perilaku pembelian dan pesaing di Indonesia.",
            results: [
              "Harga menjadi semakin penting ketika orang Indonesia membeli produk dengan harga yang lebih tinggi.",
              "Peningkatan proses regulasi pada mesin pengolah makanan dan minuman berdasarkan persyaratan Halal akan menghambat masuknya pasar.",
            ],
          },
          { title: "Case 2", description: "An up Dive", results: [] },
          { title: "Case 3", description: "A steep Dive", results: [] },
        ],
      },
      brands_section: {
        header: "Telah dipercaya lebih dari bisnis lokal & global",
        outlined_text: "Telah dipercaya",
        cta_button_text: "",
        description:
          "Sebagai perusahaan konsultan, indoinfo telah terbukti dipercaya untuk membantu bisnis & perusahaan terbaik dari beragam industri dan berbagai kota di Indonesia maupun luar negeri.",
      },
      why_choose_section: {
        title: "Mengapa Indoinfo?",
        description: "",
        outlined_text: "Indoinfo",
        reasons: [
          {
            id: 1,
            name: "Pengalaman",
            header: "Pengalaman",
            desc: "Profesional dalam mengelola data dengan hasil standar tinggi yang update setiap hari",
          },
          {
            id: 2,
            name: "Ekosistem Mitra",
            header: "Ekosistem Mitra",
            desc: "Jaringan terbaik vendor pihak ketiga, kemitraan, dan Konsultan Bisnis",
          },
          {
            id: 3,
            name: "Informasi Teknologi",
            header: "Informasi Teknologi",
            desc: "Bidang teknologi informasi yang modern serta sistem keamanan data pelanggan yang sesuai dengan standar",
          },
          {
            id: 4,
            name: "Paparan Jangkauan Global",
            header: "Paparan Jangkauan Global",
            desc: "Cyberquote Indonesia adalah anggota dari CyberQuote Group. Menyediakan solusi berbasis data dan teknologi untuk membantu pertumbuhan bisnis di Asia, Asia Pasifik dan Wilayah Eropa dengan pengalaman lebih dari 20 tahun",
          },
          {
            id: 5,
            name: "Layanan Konsultasi",
            header: "Layanan Konsultasi",
            desc: "Setiap layanan konsultasi telah diikuti dengan pendekatan terstruktur, penelitian menyeluruh, dan metodologi analitik tepercaya",
          },
        ],
      },
      clients_review_section: {
        title: "Apa kata pelanggan kami?",
        outlined_text: "pelanggan",
        reviews: [
          {
            company_desc: "Indonesian Logistic Company",
            stars: 5,
            description:
              '"Setelah mengetahui resiko dan kekuatan keuangannya, jadi lebih yakin untuk mengambil keputusan. Informasi yang kami dapat ternyata tidak selalu sesuai dengan informasi yang objektif."',
          },
          {
            company_desc: "Indonesian Financing Company",
            stars: 5,
            description:
              '"Dengan Keamanan Siber Indoinfo, kami jadi lebih waspada walaupun kerja secara remote karena sudah memahami tips dan tata caranya."',
          },
          {
            company_desc: "Indonesian Chemical Company",
            stars: 5,
            description:
              '"Litigasi BMA sangat membantu kami dalam memantau kasus hukum, apalagi online jadi bisa langsung searching sendiri."',
          },
        ],
      },
      no_bg_cta_section: {
        header: "Kembangkan bisnis Anda dengan indoinfo, sekarang!",
        outlined_text: "Kembangkan bisnis Anda",
        cta_button_text: "Hubungi Sales",
      },
      hero_cta_section: {
        header:
          "Mudahkan Proses Perencanaan dan Percepat Keputusan Bisnis Bersama Indoinfo",
        buttons: [
          {
            text: "Coba Gratis!",
            style: "btn-outline-primary",
            link: "Register",
          },
          {
            text: "Hubungi Sales",
            style: "btn-primary",
            link: "Contact Sales",
          },
        ],
      },
      video_container: features,
      our_partners_section: {
        title: "Mitra kami",
        outlined_text: "Mitra",
      },
    },
    english: {
      search_section: {
        header:
          "Mitigate your business risks, know your customers and partners.",
        outlined_text: "Mitigate your business risks,",
        placeholder: "Find Companies",
        subtitle: "Get information to help you grow your business",
        // subtitle2: 'Cari tahu lebih lanjut tentang risiko pelanggan Anda untuk tujuan bisnis yang lebih sukses.'
      },
      features_section: {
        title: "Services for optimal business growth",
        outlined_title: "optimal",
        default_selections: [
          {
            id: "Popular",
            name: "Popular",
          },
          {
            id: "CR",
            name: "Credit Risk Management",
          },
          {
            id: "CY",
            name: "Cyber Security Risk Management",
          },
          {
            id: "GOV",
            name: "Governance & Compliance",
          },
          {
            id: "M",
            name: "Sales & Marketing",
          },
        ],
        features: [
          {
            id: "VSS",
            popular: true,
            clickable: true,
            cat: "CR",
            name: "Vendor Selection System",
            title: "MINIMIZING RISKS FOR BETTER CASH FLOW",
            description:
              "Online reporting system that helps you to evaluate Vendors in fulfilling their Business Obligations. You will obtain Vendor information regarding their financial performance, supply capability, legal compliance, and possible access to financial institutions.",
            url: "",
            tooltip_desc: "Minimizing risks for better cash flow",
            img: images.vss,
            color: "#46978D",
            icon: "storefront",
            facts: [
              "Data confidentiality is our top priority; we continuously monitor your Vendor information security so that it cannot be falsified.",
              "Easy access to the latest information, available online at anytime.",
              "Good quality and well-organized Vendor registration process.",
              "Review your business Vendors in ongoing bids based on your specified credit criteria.",
            ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Try For Free",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "BMA",
            popular: true,
            clickable: true,
            cat: "GOV",
            name: "Business Monitoring Alert",
            title: "POTENTIAL PROBLEM WARNING",
            tooltip_desc: "Warning of Potential Cases of Law Violation",
            description:
              "Being aware of penalties, confiscation, and material damages of a company that does not act in accordance with laws and regulations.",
            url: "",
            img: images.bma,
            color: "#4B7BEC",
            icon: "assessment",
            facts: [
              "Assisting in identifying the increased risks associated with customers, suppliers and business partners.",
              "Related checks: bankruptcy, litigation, government blacklist, changes in the company, related news.",
              "Corporate litigation checks: covering all courts in Indonesia. (Commercial Court & District Court, High Court, Supreme Court).",
              "Reducing reputational risks and operating expenses.",
            ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Try For Free",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiConnex",
            popular: true,
            clickable: true,
            cat: "GOV",
            name: "iiConnex",
            title: "EASY IDENTIFICATION OF CUSTOMER RELATIONSHIPS",
            tooltip_desc: "Easy identification of customer relationships.",
            description:
              "Providing one single point of access to information to make review and compliance decision-making easier. Viewing the relationships and connections between Company and Individual in a chart from a business family tree automatically.",
            url: "",
            img: images.iiconnex,
            color: "#FD9644",
            icon: "analytics",
            facts: [
              "Several companies can be viewed in comprehensive pictures.",
              "Avoiding Monopolistic Practices and Unfair Business Competition (Law no. 5 of 1999).",
              "The advantage of Analysis for easy and clear chart investigation.",
            ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Try For Free",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiReport",
            popular: true,
            clickable: true,
            cat: "CR",
            name: "iiReport",
            short_desc: "| Due Dilligence Report",
            title: "DUE DILIGENCE OF YOUR BUSINESS PARTNERS",
            description:
              "Integrated customer and supplier information across your business units.",
            url: "",
            tooltip_desc: "Due diligence of your business partners",
            img: images.iireport,
            color: "#FD9644",
            icon: "analytics",
            facts: [
              "It is crucial to be performed before making a high-value business transaction for better risk management.",
              "It can be integrated with customer and supplier information across your business units, to measure the total coverage of your relationships with your individual or groups of companies' business partners.",
              "Assisting you in identifying and utilizing additional opportunities with customers and suppliers from the same company.",
              "Analyzing balanced payment patterns.",
            ],
            buttons: [
              {
                text: "View Sample Report",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/pdf/sample_ii_report.pdf",
                type: "view-pdf",
              },
              {
                text: "Learn More",
                style: "btn-outline-primary",
                link: "Contact Sales",
                type: "dialog",
              },
              {
                text: "Try For Free",
                style: "btn-primary",
                link: "Register",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiAPI",
            popular: true,
            cat: "M",
            clickable: true,
            name: "iiAPI",
            short_desc: "| Application Programming Interface",
            url: "",
            tooltip_desc: "Application Programming Interface",
            img: images.api,
            color: "#FF4D4D",
            description: `Incorporating partner data into one dynamic platform and connected to records with ii numbers (our unique number).
                        Your clients can have access to a wide variety of industry data sets through one single source of platform. `,
            icon: "hub",
            // facts: [
            //     "Identifikasi target menjadi pelanggan terbaik",
            //     "Menemukan prospek baru dan berikan prospek siap jual",
            //     "Membuat tampilan pelanggan yang akurat dan bebas duplikat",
            // ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiMarketResearch",
            cat: "M",
            clickable: false,
            name: "iiMarketResearch",
            url: "",
            description: `A means to gather information about the target market and customers. As a business strategy and the main factor in maintaining competitiveness to obtain more detailed information. `,
            img: images.marketresearch,
            color: "#46978D",
            icon: "balance",
            facts: [
              `Finding new markets, keeping up with the latest industry developments, analyzing supplies & demands and various government 
                           incentives that can meet your specific needs. `,
              `Our experts team will collaborate with you to achieve the research objectives that will support your strategic business.`,
            ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "LG",
            popular: false,
            cat: "M",
            clickable: true,
            name: "Lead Generation",
            short_desc: "",
            url: "",
            tooltip_desc: "Lead Generation",
            img: images.lead_gen,
            color: "#FF4D4D",
            description:
              "Enrich your database with potential customer contact information, such as direct calls and emails. Knowing company database information allows for more effective planning and execution of marketing strategies, which will increase the chances of success in attracting and retaining customers.",
            icon: "hub",

            buttons: [
              {
                text: "Download Brochure",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/pdf/brochures/lead_generation_brochure_eng.pdf",
                type: "view-pdf",
              },
              {
                text: "View Sample",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/excel/sample_lead_generation.xlsx",
                type: "view-pdf",
              },
              {
                text: "Get Free Trial",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiCollect",
            cat: "GOV",
            clickable: false,
            name: "iiCollect",
            url: "",
            description: `Assisting you in maintaining a stable cash flow, and controlling the accounts receivable process which is the heart of a business. 
                        The collection process aims to minimize the risk or loss of funds in arrears, maintain the company's financial soundness, and establish relationships 
                        with business partners. `,
            img: images.iicollect,
            color: "#46978D",
            icon: "balance",
            facts: [
              `Performing instant receivable recovery by utilizing our leading technology that can be used on behalf of your company.`,
              `Analyzing your company portfolio, and using data to make optimal plans.`,
              `Utilizing international partnerships and expertise in offering outsourcing services for valued, cost-effective, integrated accounts 
                            receivable management with many Indonesian companies. `,
              `You will have an internal quality level with efficient and external processes (customer relations), 
                            to significantly increase your business knowledge.`,
            ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "huawei",
            cat: "CY",
            clickable: false,
            name: "Huawei Cloud Services",
            short_desc: "",
            url: "",
            description:
              "We provide reliable Cloud consulting services. With Huawei as our partner, our wide range of Cloud and consulting services are designed to bridge your organization with Huawei technology. These services empower businesses with automation and optimization, from cost optimization, data, to compliance, security and business acceleration through customizable Huawei Cloud services.",
            img: images.huawei,
            color: "#46978D",
            icon: "balance",
            // facts: [
            //     "Melakukan identifikasi resiko pada platform digital",
            //     "Memelihara dan meningkatkan keamanan platform bisnis Anda",
            //     "Memastikan perbaikan pada celah kebocoran platform digital Anda",
            //     "Mengurangi kendala operasional bisnis yang beresiko pada tindak pidana siber"
            // ],
            buttons: [
              {
                text: "Download Brochure",
                external: true,
                style: "btn-outline-primary",
                link: "./documents/pdf/brochures/huawei_cloud_brochure.pdf",
                type: "view-pdf",
              },
              {
                text: "Learn More",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "iiPentest",
            cat: "CY",
            clickable: false,
            name: "iiPentest",
            short_desc: "| Penetration Testing",
            url: "",
            description:
              "One of the most important services the company uses is to maintain data security. Penetration testing is a cyber-security skill by uses techniques equivalent to those used by a criminal hacker to obtain access to your IT systems. Using any methodologies that might be used by criminals such as parole cracking, viruses, or social engineering. ",
            img: images.iipentest,
            color: "#46978D",
            icon: "balance",
            // facts: [
            //     "Melakukan identifikasi resiko pada platform digital",
            //     "Memelihara dan meningkatkan keamanan platform bisnis Anda",
            //     "Memastikan perbaikan pada celah kebocoran platform digital Anda",
            //     "Mengurangi kendala operasional bisnis yang beresiko pada tindak pidana siber"
            // ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
          {
            id: "CSAP",
            cat: "CY",
            clickable: false,
            name: "CSAP",
            short_desc: "| Cyber Security Awareness Program",
            url: "",
            description:
              "A cyber security awareness program that provides users with understanding and knowledge on cyber security risks, as well as threats faced by organizations or networks. Through this program, participants will gain an understanding on security concepts and practical skills in controlling cyber risks and securing digital information within their organizations.",
            img: images.csap,
            color: "#46978D",
            icon: "balance",
            facts: [
              "User Awareness",
              "Simulation Attacks",
              "User Workshops",
              "Incident Updates",
            ],
            buttons: [
              {
                text: "Learn More",
                style: "btn-primary",
                link: "Contact Sales",
                type: "dialog",
              },
            ],
          },
        ],
        features_description: [
          {
            name: "Credit Risk Management",
            points: [
              "Understanding customer creditworthiness.",
              "Identifying portfolio opportunities and risks.",
              "Assisting in analyzing decisions in a structured and planned manner.",
              "Analyzing balanced payment patterns.",
            ],
          },
          {
            name: "Cyber Security Risk Management",
            points: [
              "Performing risk identification on digital platforms.",
              "Maintaining and improving your business platform security.",
              "Ensuring correction to your digital platform leaks.",
              "Reducing business operating constraints at cybercrime risks.",
            ],
          },
          {
            name: "Governance & Compliance",
            points: [
              "Assisting in analyzing and conducting due diligence to your business partners.",
              "Identifying and verifying business targets, both locally & globally.",
              "Identifying business compliance with rules and regulations.",
              "Reducing reputational risks and operating expenses.",
            ],
          },
          {
            name: "Sales & Marketing",
            points: [
              "Identifying targets as the best customers.",
              "Finding new leads and providing ready-to-sell leads.",
              "Creating accurate and duplicate-free customer display.",
            ],
          },
        ],
      },
      marketing_stat: [
        {
          title: "",
        },
        {
          description: "",
        },
        {
          big_numbers: "",
        },
      ],
      importance_section: {
        header: "Four Reasons the importance of information for your business",
        outlined_text: "importance of information",
        reasons: [
          {
            id: 1,
            text: "Optimal business strategy planning",
          },
          {
            id: 2,
            text: "Helping in reducing business damage risks",
          },
          {
            id: 3,
            text: "Identifying, measuring, and controlling the credit risk levels",
          },
          {
            id: 4,
            text: "Providing complete information reports on your prospective business partners",
          },
        ],
      },
      boi_report_section: {
        header: "Get your free BOI report",
        outlined_text: "BOI",
        description: `Business Optimism Index is a product, which measures the pulse of the business community 
                and serve as a reliable benchmark for investor. Over time, this index has emerged as a 
                leading indicator of turning points in economic activity.`,
        cta_button: "Download Report",
        drop_down_label: "Choose Report",
      },
      compliance_section: {
        header: "Regulatory Compliance and Registers",
        description:
          "Have a question or need consultation in regards to Regulatory Compliance and Data Protection?",
        button: {
          text: "Contact Us",
          external: true,
          type: "view-pdf",
          link: "./documents/pdf/compliance/formcompliance.pdf",
        },
      },
      globe_section: [
        {
          id: "Indonesia",
          title: "Indonesia",
          desc: "Easy access to company information across Indonesia",
          buttons: [
            {
              text: "Learn More",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
        {
          id: "Singapore",
          title: "Singapore",
          desc: "Indoinfo takes roots from our head office in Singapore, Cyberquote Group",
          buttons: [
            {
              text: "Learn More",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
        {
          id: "Litigation",
          title: "Litigasi",
          desc: "The important part of Good Corporate Governance (GCG) and Risk Management (RM) policies",
          buttons: [
            {
              text: "Learn More",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
        {
          id: "Overseas",
          title: "Overseas",
          // amount: 0,
          desc: "Facing global economic challenges together with Indoinfo, as a business intelligence provider for our overseas customers",
          buttons: [
            {
              text: "Learn More",
              style: "btn-outline-primary",
              link: "Contact Sales",
            },
          ],
        },
      ],
      case_section: {
        cta_text: "Apakah Bisnis Anda Perlu Solusi?",
        cta_button_text: "Hubungi Tim Kami",
        cases: [
          {
            title: "Riset Pasar",
            description:
              "Memperkenalkan merek Kopi Kapsul Swiss ke pasar Indonesia. Indoinfo menganalisis dan mensurvei perilaku pembelian dan pesaing di Indonesia.",
            results: [
              "Harga menjadi semakin penting ketika orang Indonesia membeli produk dengan harga yang lebih tinggi.",
              "Peningkatan proses regulasi pada mesin pengolah makanan dan minuman berdasarkan persyaratan Halal akan menghambat masuknya pasar.",
            ],
          },
          { title: "Case 2", description: "An up Dive", results: [] },
          { title: "Case 3", description: "A steep Dive", results: [] },
        ],
      },
      brands_section: {
        header: "Trusted by more than 150 local & global businesses",
        outlined_text: "Trusted",
        cta_button_text: "",
        description:
          "As a consulting company, indoinfo has proven to be trusted in assisting the best businesses & companies from various industries and cities in Indonesia and overseas. ",
      },
      why_choose_section: {
        title: "Why Indoinfo?",
        description: "",
        outlined_text: "Indoinfo",
        reasons: [
          {
            id: 1,
            name: "Experiences",
            header: "Experiences",
            desc: "Professional in managing data with high standard results that are updated on a daily basis",
          },
          {
            id: 2,
            name: "Partner Ecosystem",
            header: "Partner Ecosystem",
            desc: "The best network for third party vendors, partnerships and Business Consultants",
          },
          {
            id: 3,
            name: "Information Technology",
            header: "Information Technology",
            desc: "Modern information technology and customer data security systems that comply with standards",
          },
          {
            id: 4,
            name: "Global Coverages",
            header: "Global Coverages",
            desc: "PT. Cyberquote Indonesia is a member of CyberQuote Group. It provides data and technology-based solutions to help in businesses growth in Asia, the Asia Pacific, and the European Areas with more than 20 years of experience",
          },
          {
            id: 5,
            name: "Consulting Services",
            header: "Consulting Services",
            desc: "Each consulting service has been followed by a structured approach, comprehensive research, and trusted analytical methodology",
          },
        ],
      },
      clients_review_section: {
        title: "What customers say about us?",
        outlined_text: "customers",
        reviews: [
          {
            company_desc: "Indonesian Logistic Company",
            stars: 5,
            description: `
                        “After knowing the financial risks and benefits, I fell more confident in taking decision The information 
                        we got did not always match with the objective information.”
                        `,
          },
          {
            company_desc: "Indonesian Financing Company",
            stars: 5,
            description: `
                        “After knowing the financial risks and benefits, I fell more confident in taking 
                        decision The information we got did not always match with the objective information.”
                        `,
          },
          {
            company_desc: "Indonesian Chemical Company",
            stars: 5,
            description: `
                        "BMA Litigation is very helpful in monitoring legal cases, moreover, it is online and we can search it by ourselves."
                        `,
          },
        ],
      },
      no_bg_cta_section: {
        header: "Grow your business with Indoinfo now!",
        outlined_text: "Grow your business",
        cta_button_text: "Contact Sales",
      },
      hero_cta_section: {
        header:
          "Facilitate your Business Planning & Decision Making Process with Indoinfo",
        buttons: [
          {
            text: "Try For Free!",
            style: "btn-outline-primary",
            link: "Register",
          },
          {
            text: "Contact Sales",
            style: "btn-primary",
            link: "Contact Sales",
          },
        ],
      },
      video_container: features,
      our_partners_section: {
        title: "Our Partners",
        outlined_text: "Partners",
      },
    },
  },
  getters: {
    english: (s) => s.english,
    indonesian: (s) => s.indonesian,
  },
};
