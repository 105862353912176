export default {
    namespaced: true,
    state: {
        // indonesian
        indonesian: {
            banner_section: {
                title: 'Informasi adalah kunci sebuah kesuksesan',
                description: [`Kami membantu proses mengolah informasi berdasarkan fakta menjadi mudah &
                 efisien untuk perkembangan bisnis dan perusahaan. Analisa risiko usaha dengan solusi automasi, 
                 kapanpun & dimanapun.`]
            },
            reason_section: {
                header: 'Alasan Memilih Indoinfo',
                outlined_text: 'Memilih Indoinfo',
                accordions: [
                    // {
                    //     title: false,
                    //     list: true,
                    //     body_show: true,
                    //     body: [
                    //         "Laporan informasi yang akurat berdasarkan fakta ",
                    //         "Kemudahan akses, dapat dilakukan kapanpun & dimanapun",
                    //         "Fitur terlengkap dan terintegrasi dalam satu platform",
                    //         "Akses basis data global & lokal"
                    //     ],
                    //     img: ''
                    // },
                    {
                        title: 'Memudahkan Proses Identifikasi Risiko Bisnis',
                        body_show: false,
                        body: ['Indoinfo merupakan platform berbasis data yang memberikan solusi efektif untuk memudahkan proses analisa informasi yang diperlukan perusahaan dalam upaya meminimalisir & mencegah terjadinya risiko bisnis.']
                    },
                    {
                        title: 'Layanan yang disesuaikan untuk kebutuhan bisnis',
                        body_show: false,
                        body: [
                            "Ahli Data Sains kami melakukan update data secara reguler setiap harinya, mulai dari data mentah kemudian diolah kembali sebagai komitmen indoinfo untuk menyajikan informasi yang akurat dan komprehensif.",
                            "Dengan inovasi teknologi dan beragam fitur lengkap yang akan mendukung proses perkembangan bisnis dan perusahaan agar lebih efisien."
                        ]
                    }
                ]
            },
            comparison_section: {
                header: 'Perbedaan Proses Evaluasi Data',
                outlined_text: 'Perbedaan',
                headers: [
                    "Proses Evaluasi Data",
                    "Cara Tradisional",
                    "Melalui Indoinfo"
                ],
                table_rows: [
                    {
                        element: [
                            "Teknik Pengerjaan Laporan",
                            "Pengumpulan data secara manual perlu adanya survei langsung atau menghubungi orang terkait untuk mengetahui informasi satu per satu",
                            "Big Data dikelola oleh tim Profesional, Data Scientist dan Business Analyst"
                        ],
                    },
                    {
                        element: [
                            "Aksesibilitas",
                            "Data yang diperoleh secara manual berasal dari beberapa sumber dapat beresiko & mudah hilang",
                            "Laporan informasi terstruktur dan mudah diakses secara online"
                        ],
                    },
                    {
                        element: [
                            "Keakuratan Data",
                            "Berisiko human-error sehingga memakan waktu lebih lama",
                            "Sumber data yang akurat & efisien dengan bantuan Artificial Intelligence dan dipantau oleh Quality Control"
                        ],
                    },
                    {
                        element: [
                            "Biaya yang Dikeluarkan",
                            "Biaya besar ke banyak pihak untuk mendapat sumber data yang terpercaya",
                            "Biaya lebih hemat karena melalui satu platform"
                        ],
                    },
                    {
                        element: [
                            "Proses Perhitungan",
                            "Pengerjaan & input rumus secara manual",
                            "Perhitungan dan analisa data yang menyeluruh secara otomatis"
                        ],
                    },
                ]
            }
        },
        // english
        english: {
            banner_section: {
                title: 'Information is the key to success',
                description: [`We provide fact-based information processing that is easy & efficient for the development 
                of businesses and companies.`,
                `Business risk analysis with automated solutions, anytime & anywhere.`]
            },
            reason_section: {
                header: 'Reasons for Choosing Indoinfo',
                outlined_text: 'Choosing Indoinfo',
                accordions: [
                    // {
                    //     title: false,
                    //     list: true,
                    //     body_show: true,
                    //     body: [
                    //         "Accurate information reports based on facts ",
                    //         "Easy access, can be done anytime & anywhere",
                    //         "The most complete and integrated features in one platform",
                    //         "Global & local database accesses"
                    //     ],
                    //     img: ''
                    // },
                    {
                        title: 'Facilitating the business risks identification process',
                        body_show: false,
                        body: ['Indoinfo is a data-based platform that provides effective solutions to facilitate the information analysis process needed by companies in an effort to minimize & prevent business risks.']
                    },
                    {
                        title: 'Customized services for business needs',
                        body_show: false,
                        body: [
                            "Our Science Data experts update data regularly on a daily basis, starting from raw data to reprocessing it because indoinfo is committed to providing accurate and comprehensive information.",
                            "With technological innovation and various complete features, they will support the business and company development process to be more efficient."
                        ]
                    }
                ]
            },
            comparison_section: {
                header: 'Differences in Data Evaluation Process',
                outlined_text: 'Differences',
                headers: [
                    "Data Evaluation Process",
                    "Traditional Method",
                    "Through indoinfo"
                ],
                table_rows: [
                    {
                        element: [
                            "Report Preparation Techniques",
                            "Manual data collection requires direct surveys or contacting related people to find out information, one at a time",
                            "Big Data is managed by a team consisting of professionals, Data Scientists, and Business Analysts"
                        ],
                    },
                    {
                        element: [
                            "Accessibility",
                            "Data obtained manually from several sources can be risky & easy to lose",
                            "Information reports are in a structured form and easily accessible online"
                        ],
                    },
                    {
                        element: [
                            "Data Accuracy",
                            "There is a risk of human error, thus, it takes a longer time",
                            "Accurate & efficient data source with the assistance of Artificial Intelligence and monitored by Quality Control"
                        ],
                    },
                    {
                        element: [
                            "Costs Incurred",
                            "Costly in obtaining reliable data sources from many parties",
                            "More cost-effective because it is through one platform"
                        ],
                    },
                    {
                        element: [
                            "Calculation Process",
                            "Manual formula processing & input",
                            "Comprehensive and automatic data calculation and analysis"
                        ],
                    },
                ]
            }
        },
    },
    getters: {
        english: s => s.english,
        indonesian: s => s.indonesian
    }
}