var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mx-0 px-4 d-flex align-items-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",staticStyle:{"max-width":"100vw","overflow-x":"hidden"}},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"openSansBold py-3",class:{
            'text-center': this.text_align === 'center',
            'text-left': this.text_align === 'left',
            'text-right': this.text_align === 'right',
          }},[_vm._v(" "+_vm._s(_vm.left_splitted_header)),_c('span',{staticClass:"emphasis"},[_vm._v(_vm._s(_vm.outlined_text))]),_vm._v(_vm._s(_vm.right_splitted_header)+" ")])]),_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('p',{class:{
            'text-center': this.text_align === 'center',
            'text-left': this.text_align === 'left',
            'text-right': this.text_align === 'right',
          }},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',{staticClass:"col-12 d-flex",class:{
          'justify-content-center': this.text_align === 'center',
          'justify-content-start': this.text_align === 'left',
          'justify-content-end': this.text_align === 'right',
        }}),_vm._l((_vm.images),function(image,index){return _c('div',{key:index + 'image',staticClass:"col d-flex justify-content-center align-items-center",staticStyle:{"height":"180px"}},[_c('img',{attrs:{"src":image,"alt":""}})])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }