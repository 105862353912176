<template>
  <div>
    <vs-dialog v-if="returnEventDialogPrompt && returnFeaturingEvent !== undefined" v-model="show_event_dialog"
      style="max-height: 100vh">
      <div class="row dialog">
        <div class="col-9">
          <h6 class="poppinsSemiBold">
            Indoinfo | <span class="poppinsRegular">events</span>
            <span class="text-gradient poppinsBold ml-1">{{ new Date().getFullYear() }}</span>
          </h6>
        </div>
        <div class="col-12 mt-3">
          <h2 class="poppinsBold text-center text-md-left text-gradient">
            <span v-if="returnCurrentLanguage === 'EN'">
              {{ returnFeaturingEvent.title_event_en }}
            </span>
            <span v-if="returnCurrentLanguage === 'ID'">
              {{ returnFeaturingEvent.title_event }}
            </span>
          </h2>
        </div>
        <div class="
            col-md-4 col-12
            d-flex
            justify-content-center justify-content-md-start
            d-flex
            align-items-center
          ">
          <span class="material-icons-round">event</span>
          <span class="span ml-2">
            {{ returnFeaturingEvent.date_to }}
          </span>
          <!-- END OF DATE -->
        </div>
        <div class="
            col-md-4 col-12
            d-flex
            justify-content-center justify-content-md-start
            d-flex
            align-items-center
          ">
          <!-- PARTICIPANTS -->
          <span class="material-icons-round mx-2"
            v-if="returnFeaturingEvent.number_of_applicant === '-'">all_inclusive</span>
          <span class="span" v-if="
            returnFeaturingEvent.number_of_applicant === '-' &&
            returnCurrentLanguage === 'EN'
          ">
            Unlimited Entries
          </span>
          <span class="span" v-if="
            returnFeaturingEvent.number_of_applicant === '-' &&
            returnCurrentLanguage === 'ID'
          ">
            Tidak terbatas
          </span>
        </div>
        <div class="col-md-6 col-12 mt-3">
          <div class="row">
            <div class="col-12">
              <div class="image-container">
                <img v-if="!debugmode" :src="image_path_base + returnFeaturingEvent.url_image" alt="" />
                <img v-if="debugmode" :src="returnFeaturingEvent.url_image" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 mt-3" style="padding-bottom: 150px">
          <div class="row text-description">
            <div class="col-12">
              <span v-if="returnCurrentLanguage === 'EN'" v-html="returnFeaturingEvent.event_en">
              </span>
              <span v-if="returnCurrentLanguage === 'ID'" v-html="returnFeaturingEvent.event_id">
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- REGISTER BUTTON -->
      <div class="row">
        <div class="col-12 mx-0 px-0" style="
            position: absolute;
            bottom: 5px;
            max-width: 100%;
            background: white;
          ">
          <div class="row">
            <div class="col-12 pt-3 d-flex justify-content-center" style="width: 100%; !important">
              <vs-button block class="poppinsBold" floating gradient @click="goToLink(returnFeaturingEvent.url_event)">
                <span v-if="returnCurrentLanguage === 'EN'">
                  {{ returnFeaturingEvent.btn_en }}
                </span>
                <span v-if="returnCurrentLanguage === 'ID'">
                  {{ returnFeaturingEvent.btn }}
                </span>
              </vs-button>
            </div>
            <div class="col-12 py-0 d-flex justify-content-center" style="width: 100%; !important">
              <vs-checkbox v-model="notInterested">
                Don't show this again
              </vs-checkbox>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import imgsample from "@/assets/sample/eventbrochuretest.png";
import axios from "axios";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      img_sample: imgsample,
      show_event_dialog: false,
      show_map_hint: false,
      events_data: [],
      event_path: "event/active?src=indoinfo",
      dataready: true,
      image_path_base: "https://portal.indoinfo.co.id/img/",
      notInterested: false,

      // debugsss
      debugmode: false
    };
  },
  methods: {
    openMap(url) {
      window.open(url);
    },
    goToLink(event_link) {
      window.open(event_link, "_blank");
    },
    async getEvents() {
      console.log(
        "EVENT DIALOG INITIALIZING (GETTING DATA WITH CAPTCHA) ",
        location.hostname,
        this.returnSiteConfig.cpt
      );
      let self = this;
      console.log("current captcha: ", this.returnSiteConfig);

      if (this.debugmode) {
        // FAKE TESTS
        this.events_data = [
          {
            title_event: 'Resesi Global di Depan Pintu',
            title_event_en: 'Recession',
            is_unlaunched: true,
            date_from: moment().format(
              "MMMM DD, YYYY"
            ),
            date_to: moment().format(
              "MMMM DD, YYYY"
            ),
            url_image: this.img_sample,
            event_id: `<p> Ancaman krisis yang melanda dunia menjadi momok yang menakutkan di industri keuangan. Sehingga, bagi investor uang tunai adalah raja selama resesi atau istilah populernya 'cash is the king'. Menjaga aset likuid menjadi langkah utama mengamankan posisi.
              "Apa saja dampak yang perlu diwaspadai oleh masyarakat & Bagaimana cara menghadapinya?"
              Dun and Bradstreet Indonesia dan indoinfo by CyberQuote Indonesia telah merangkum informasinya, dalam Webinar Business Optimism Index - Quartal 4 2022, yang akan diselenggarakan:
              📆 Kamis 3 November 2022
              ⌚ 14.00 WIB - Selesai
              📲 Zoom meeting
              Daftar dengan Scan QR Code atau klik link : bit.ly/webinardnbid
              🎉Dapatkan certificate dan kesempatan memenangkan hadiah menarik🎉</p>`,
            btn: 'Registrasi'
          }
        ]
// 2fsa
        this.dataready = true;
        this.show_event_dialog = true;
      } else {
        //  ORIGINAL
        await load(self.returnSiteConfig.cpt, { autoHideBadge: true }).then(
          (recaptcha) => {
            recaptcha
              .execute("get")
              .then((token) => {
                console.log("GET DATA TOKEN: ", token);
                axios
                  .get(self.returnSiteConfig.e_p + self.event_path, {
                    params: {
                      init_token: token,
                    },
                  })
                  .then((res) => {
                    console.log("EVENT DIALOG DATA", res.data);
                    for (let i = 0; i < res.data.length; i++) {
                      let result_data = res.data[i];
                      result_data.is_unlaunched = moment(
                        result_data.date_to
                      ).isAfter(moment());
                      result_data.date_to = moment(result_data.date_to).format(
                        "MMMM DD, YYYY"
                      );
                      result_data.date_from = moment(
                        result_data.date_from
                      ).format("MMMM DD, YYYY");
                      self.events_data.push(result_data);
                      if (i == res.data.length - 1) {
                        setTimeout(() => {
                          self.show_event_dialog = true;
                        }, 3000);

                        self.dataready = true;
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "EVENT DIALOG ERROR url",
                      self.returnSiteConfig.e_p + self.event_path
                    );
                    console.log("EVENT DIALOG get data token error", error);
                  })
                  .catch((error) => {
                    console.log("EVENT DIALOG get data token error", error);
                  });
              })
              .catch((error) => {
                console.log("EVENT DIALOG get data recaptcha error", error);
              });
          }
        );
      }

    },
  },
  computed: {
    ...mapGetters(["siteConfig", "language", "event_dialog_prompt"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnCurrentLanguage() {
      return this.language;
    },
    returnEventDialogPrompt() {
      return this.event_dialog_prompt;
    },
    returnFeaturingEvent() {
      console.log("RETURN CURRENT EVENTS", this.events_data);
      // find is unlaunched = true;
      let current_events = this.events_data.filter(
        (event) => event.is_unlaunched == true
      );
      return current_events[0];
    },
  },

  watch: {
    show_event_dialog(val) {
      if (!val) {
        if (this.notInterested) {
          this.$store.dispatch("CHANGE_EVENT_DIALOG_PROMPT", false);
        }
      }
    },
  },

  mounted() {
    this.getEvents();
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  border-radius: 20px;
}

.text-gradient {
  background: rgb(57, 0, 138);
  background: linear-gradient(30deg,
      rgba(0, 82, 180, 1) 30%,
      rgb(104, 0, 250) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.event-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.location:hover {
  background: rgb(0, 188, 154);
  background: linear-gradient(30deg,
      rgba(0, 188, 154, 1) 30%,
      rgba(235, 255, 0, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dialog {
  max-height: 80vh;
  overflow-y: auto;

  // .image {
  //   img {
  //     width: auto;
  //     max-width: 100%;
  //     border-radius: 20px;
  //     max-height: 50vh;
  //   }
  // }
  // changed
  .image-container {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.5s ease-in-out;

    }

    &:hover {
      img {
        scale: 1.1;
      }
    }
  }

  .text-description {
    width: 100%;
    overflow-y: auto;
  }

  @media screen and (max-width: 768px) {
    .text-description {
      width: 100%;
    }
  }
}
</style>