<template>
  <div class="container d-flex align-items-center" style="padding-bottom: 60px">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div
            class="col-md-4 col-sm-12 d-flex justify-content-center"
            v-for="(item, index) in returnItems"
            :key="index"
          >
            <div class="row justify-content-center">
              <div class="col-12 d-flex justify-content-center">
                <h1 :style="{ color: item.headercolor }" class="openSansBold">
                  {{ item.amount }}
                </h1>
              </div>
              <div class="col-12 d-flex openSansMedium justify-content-center">
                <h6 style="color: #003e88" class="text-center">
                  {{ item.subtitle }}
                </h6>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <p class="text-center">
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [
        {
          id: "Company",
          subtitle: "Data in your hands",
          desc: "Clean daily updated B2B data and analytics for your business needs",
          headercolor: "#FD9644",
        },
        {
          id: "Litigation",
          subtitle: "Companies using",
          desc: "Assessment is a key part of Good Corporate Governance (GCG) and Risk Management (RM) policies",
          headercolor: "#4B7BEC",
        },
        {
          id: "Satisfaction",
          amount: "99.99%",
          subtitle: "Satisfaction",
          desc: "Our main priority is customer satisfaction and customer loyalty is our bonus.",
          headercolor: "#46978D",
        },
      ],
    };
  },
  methods: {
    getData() {
      let self = this;
      load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha.execute("get").then((token) => {
            // COMPANY
            axios
              .get(`${self.returnSiteConfig.e_p}company/count`, {
                params: {
                  init_token: token,
                },
              })
              .then((comp) => {
                this.updateObject("Company", {
                  amount: numeral(comp.data.count).format("0 a") + "+",
                });
                this.updateObject("Company", {
                  subtitle:
                    "Company data in your hands with " +
                    numeral(comp.data.inc_last_week).format("0 a") +
                    "+ data updated per week.",
                });
              });
            // LITIGATION
            axios
              .get(`${self.returnSiteConfig.e_p}litigation/count`, {
                params: {
                  init_token: token,
                },
              })
              .then((comp) => {
                this.updateObject("Litigation", {
                  amount: numeral(comp.data.count).format("0 a") + "+",
                });
                if (comp.data.inc_last_week > 20) {
                  this.updateObject("Litigation", {
                    subtitle:
                      numeral(comp.data.inc_last_week).format("0 a") +
                      "+ data updated per week ",
                  });
                } else {
                  this.updateObject("Litigation", {
                    subtitle: "Litigation Data",
                  });
                }
              });
          });
        }
      );
    },
    updateObject(id, keypair) {
      let object = this.items.find((x) => x.id === id);
      var copied = this.items;
      this.items = [];
      console.log("index data deleted: ", this.items);
      this.items = copied;
      console.log("index data: ", this.items);
      Object.assign(object, keypair);
    },
  },
  ...mapGetters(["siteConfig"]),
  returnSiteConfig() {
    return this.siteConfig;
  },
  computed: {
    returnNumeral() {
      return numeral(10000000000000).format("0 a");
    },
    returnItems() {
      return this.items;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.card {
  border: 0px;
  box-shadow: 5px 10px 10px #88888857;
  min-width: 100%;
}
</style>
