<template>
  <div class="container-fluid mx-0 px-4 d-flex" style="padding-bottom: 60px">
    <div class="container">
      <div class="row" style="max-width: 100vw; overflow-x: hidden">
        <div class="col-12">
          <h2
            class="openSansBold"
            :class="{
              'text-center': this.text_align === 'center',
              'text-left': this.text_align === 'left',
              'text-right': this.text_align === 'right',
            }"
          >
            {{ left_splitted_header
            }}<span class="emphasis">{{ outlined_text }}</span
            >{{ right_splitted_header }}
          </h2>
        </div>
        <div
          v-if="this.cta_text !== undefined"
          class="col-12 d-flex"
          :class="{
            'justify-content-center': this.text_align === 'center',
            'justify-content-start': this.text_align === 'left',
            'justify-content-end': this.text_align === 'right',
          }"
        >
          <div class="btn btn-primary" @click="goToLink()">
            {{ this.cta_text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    text_align: String,
    outlined_text: String,
    cta_text: String,
    current_language: String,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
    };
  },

  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("could not find");
      }
    },
    goToLink() {
      var x = {
        state: true,
        tab: "Contact Sales",
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
  },

  mounted() {
    this.CutandDump();
  },

  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    header() {
      this.CutandDump();
    },
  },
};
</script>

<style scoped lang="scss">
</style>