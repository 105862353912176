import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "material-icons/iconfont/material-icons.css";
import "@/assets/styles/style.css";
import "@/assets/styles/bootstrap_customized.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueScreen from "vue-screen";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import VueYoutube from "vue-youtube";
import Carousel3d from "vue-carousel-3d";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import Skeleton from "vue-loading-skeleton";
import "vue-loading-skeleton/dist/style.css";
Vue.use(Vuesax, {
  colors: {
    primary: "#0052B4",
    success: "#00A944",
    danger: "rgb(223, 18, 18)",
    warning: "rgb(255, 130, 0)",
    dark: "rgb(36, 33, 69)",
  },
});

Vue.use(Carousel3d);
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueScreen);
Vue.use(VueLodash, { name: "custom", lodash: lodash });

Vue.config.productionTip = false;
// REMOVE THIS LINE BEFORE DEPLOYING!
Vue.config.devtools = true;

Vue.use(VueMeta);

Vue.use(
  VueGtag,
  {
    config: { id: "G-7F155KTBLP" },
  },
  router
);
Vue.use(Skeleton);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
