<template>
  <div class="home" style="background: #fff">
    <div class="bg-container">
      <div
        class="bg"
        v-bind:style="{ 'background-image': 'url(' + searchbg + ')' }"
      ></div>
    </div>
    <GlobalAnaylstCommentarySection />

    <search-company-section
      id="sc"
      v-if="dataready"
      :header.sync="homecontents.search_section.header"
      :outlined_text.sync="homecontents.search_section.outlined_text"
      :placeholder.sync="homecontents.search_section.placeholder"
      :subtitle1.sync="homecontents.search_section.subtitle"
      :subtitle2.sync="homecontents.search_section.subtitle2"
      :current_language.sync="currentLanguage"
    />
    <div class="container-fluid px-0 mx-0 mt-5">
      <div class="card">
        <div class="card-body px-0 py-0">
          <video-container
            :open.sync="openvideo"
            :source.sync="homecontents.video_container"
            :product_id.sync="openvideo_product_id"
            @update="openvideo"
            v-if="openvideo"
          />

          <!-- <cors-testing /> -->

          <features-section
            id="wwd"
            ref="wwd"
            v-if="dataready"
            :header.sync="homecontents.features_section.title"
            :outlined_text.sync="homecontents.features_section.outlined_title"
            :features.sync="homecontents.features_section.features"
            :open_video.sync="openvideo"
            :open_video_pid.sync="openvideo_product_id"
            :current_language.sync="currentLanguage"
            :is_popular_available="true"
            :features_description.sync="
              homecontents.features_section.features_description
            "
            :default_selections.sync="
              homecontents.features_section.default_selections
            "
            @update="openvideo_product_id"
          />
          <importance-section
            v-if="dataready"
            :header.sync="homecontents.importance_section.header"
            :outlined_text.sync="homecontents.importance_section.outlined_text"
            :reasons.sync="homecontents.importance_section.reasons"
          />

          <boi-section
            v-if="dataready"
            :header.sync="homecontents.boi_report_section.header"
            :outlined_text.sync="homecontents.boi_report_section.outlined_text"
            :description.sync="homecontents.boi_report_section.description"
            :cta_button.sync="homecontents.boi_report_section.cta_button"
            :drop_down_label.sync="
              homecontents.boi_report_section.drop_down_label
            "
          />

          <compliance-section
            v-if="dataready"
            :header.sync="homecontents.compliance_section.header"
            :description.sync="homecontents.compliance_section.description"
            :button.sync="homecontents.compliance_section.button"
          ></compliance-section>
          <globe-section
            v-if="dataready"
            :items.sync="homecontents.globe_section"
            :current_language.sync="currentLanguage"
          />

          <review-section
            id="clients"
            ref="clients"
            v-if="dataready"
            :header.sync="homecontents.clients_review_section.title"
            :outlined_text.sync="
              homecontents.clients_review_section.outlined_text
            "
            :reviews.sync="homecontents.clients_review_section.reviews"
          />

          <brands-section
            id="clientbrands"
            ref="clientbrands"
            v-if="dataready"
            :text_align="'center'"
            :header.sync="homecontents.brands_section.header"
            :outlined_text.sync="homecontents.brands_section.outlined_text"
            :cta_text="homecontents.brands_section.cta_button_text"
            :description.sync="homecontents.brands_section.description"
            :current_language.sync="currentLanguage"
          />

          <our-partners-section
            id="partners"
            ref="partners"
            v-if="dataready"
            :header.sync="homecontents.our_partners_section.title"
            :outlined_text.sync="
              homecontents.our_partners_section.outlined_text
            "
          />

          <hero-cta-section
            v-if="dataready"
            :header.sync="homecontents.hero_cta_section.header"
            :buttons.sync="homecontents.hero_cta_section.buttons"
          />
        </div>
      </div>
    </div>

    <!-- Dialogs -->

    <!-- <why-choose-us
      id="wcu"
      v-if="dataready"
      :header.sync="homecontents.why_choose_section.title"
      :description.sync="homecontents.why_choose_section.description"
      :outlined_text.sync="homecontents.why_choose_section.outlined_text"
      :whychooseoptions.sync="homecontents.why_choose_section.reasons"
      :current_language.sync="currentLanguage"
    /> -->

    <!-- <case-section
      id="cases"
      v-if="dataready"
      :cta_text.sync="homecontents.case_section.cta_text"
      :cta_button_text.sync="homecontents.case_section.cta_button_text"
      :cases.sync="homecontents.case_section.cases"
    /> -->

    <!-- <marketing-stat /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import SearchCompanySection from "@/components/homecomponents/SearchCompanySection.vue";
import FeaturesSection from "@/components/homecomponents/FeaturesSection.vue";
import WhyChooseUs from "@/components/homecomponents/WhyChooseUs.vue";
import CaseSection from "@/components/homecomponents/CaseSection.vue";
import MarketingStat from "@/components/homecomponents/MarketingStat.vue";
import BrandsSection from "@/components/homecomponents/BrandsSection.vue";
import ReviewSection from "@/components/homecomponents/ReviewSection.vue";
import OurPartnersSection from "@/components/homecomponents/OurPartnersSection.vue";
import GlobeSection from "@/components/homecomponents/GlobeSection.vue";
import HeroCtaSection from "@/components/homecomponents/HeroCtaSection.vue";
import CorsTesting from "@/components/utils/CorsTesting.vue";
import ImportanceSection from "@/components/homecomponents/ImportanceSection.vue";
import BoiSection from "@/components/homecomponents/BoiSection.vue";
import GlobalAnaylstCommentarySection from "@/components/globalcomponents/GlobalAnalystCommentarySection.vue";
import ComplianceSection from "@/components/homecomponents/ComplianceSection.vue";
// Images
import searchbackground from "@/assets/bgs/bg_discuss.png";
// Dialogs
import VideoContainer from "@/components/featurecomponents/VideoContainer.vue";

// Store Getters
import { mapGetters } from "vuex";
export default {
  name: "HomeView",
  components: {
    SearchCompanySection,
    FeaturesSection,
    WhyChooseUs,
    CaseSection,
    MarketingStat,
    BrandsSection,
    ReviewSection,
    GlobeSection,
    VideoContainer,
    OurPartnersSection,
    HeroCtaSection,
    CorsTesting,
    ImportanceSection,
    BoiSection,
    GlobalAnaylstCommentarySection,
    ComplianceSection,
  },
  data() {
    return {
      currentLanguage: "ID",
      dataready: false,
      homecontents: {},
      openvideo: false,
      openvideo_product_id: "",
      searchbg: searchbackground,
    };
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Layanan informasi untuk mitigasi risiko bisnis, berbasis data & analisa yang memberikan solusi efektif bagi organisasi dan perusahaan.",
      },
    ],
  },
  methods: {
    iniateSwapLanguage() {
      this.currentLanguage = this.returnCurrentLanguage;
      if (this.returnCurrentLanguage === "EN") {
        this.homecontents = this.returnEnglishContent;
        this.dataready = true;
      } else if (this.returnCurrentLanguage === "ID") {
        this.homecontents = this.returnIndonesianContent;
        this.dataready = true;
      }
    },
    scrollTo() {
      console.log("router path: ", this.$route.fullPath);
      // get the second character of the path
      let path = this.$route.fullPath;
      let secondChar = path.charAt(1);

      if (secondChar === "#") {
        this.$nextTick(() => {
          const p = path.substring(2);
          var element = document.getElementById(p);
          var headerOffset = 10;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        });
      } else {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    ...mapGetters(["language", "hc/indonesian", "hc/english"]),
    returnCurrentLanguage() {
      console.log("HOME COMPONENT: ", this.language);
      return this.language;
    },
    returnIndonesianContent() {
      return this["hc/indonesian"];
    },
    returnEnglishContent() {
      return this["hc/english"];
    },
  },
  watch: {
    returnCurrentLanguage(newVal, oldVal) {
      this.iniateSwapLanguage();
    },
    openvideo(val) {
      console.log("open video status: ", val);
    },
    openvideo_product_id(val) {
      console.log("open video PID: ", val);
    },
  },
  mounted() {
    this.iniateSwapLanguage();
    this.scrollTo();
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
}
.card {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.bg-container {
  position: fixed;
  height: 100vh;
  min-height: 95vh;
  width: 100%;
  .bg {
    height: 100%;
    min-height: 95vh;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
  }
}
</style>
