<template>
  <div class="container">
    <PuSkeleton :count="1" height="50px" />
    <div class="row">
      <div class="col-4 my-2" v-for="t in 3">
        <PuSkeleton height="30px" />
      </div>
      <div class="col-12 my-2">
        <div class="row my-2" v-for="y in 10">
          <div class="col-4">
            <PuSkeleton height="30px" />
          </div>
          <div class="col-8">
            <PuSkeleton height="30px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.push({
        name: this.$route.params.redirect_target_name,
        params: {
          company_name: this.$route.params.company_name,
        },
        query: {
          q: this.$route.query.q,
          z: this.$route.query.z,
        },
      });
    }, 1000);
  },
};
</script>
