<template>
  <div
    v-if="dataready"
    class="container-fluid px-0 mx-0 events_bg"
    style="position: relative"
  >
    <transition name="slide-fade" mode="out-in">
      <span class="aside poppinsBold" v-if="currentMonth !== undefined">
        <span class="rotated-text">
          <span class="rotated-text__inner"
            ><span class="text-gradient"> {{ currentMonth }}</span>
          </span>
        </span>
      </span>
    </transition>
    <div class="mx-0 px-0 my-0">
      <!-- the top bar -->
      <top-nav-events
        :search_query.sync="search_query"
        @update="search_query"
      />
      {{ search_query }}
      <!-- end of the top bar -->

      <!-- welcome banner -->
      <div class="bg-container">
        <div
          class="bg"
          v-bind:style="{ 'background-image': 'url(' + eventsbg + ')' }"
        ></div>
      </div>
      <div class="bg-text-overlay">
        <div class="row align-items-center" style="height: 100%; width: 100%">
          <div class="col-12 engage-text">
            <span class="poppinsBold">
              Be
              <span class="text-gradient poppinsBold ml-1">
                <vue-typer
                  :text="['informed', 'connected', 'engaged']"
                  :repeat="Infinity"
                  :shuffle="false"
                  initial-action="typing"
                  :pre-type-delay="10"
                  :type-delay="80"
                  :pre-erase-delay="2000"
                  :erase-delay="100"
                  erase-style="clear"
                  :erase-on-complete="true"
                  caret-animation="phase"
                ></vue-typer>
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- end of welcome -->
      <div
        class="container-fluid px-0"
        style="
          background: white !important;
          z-index: 9;
          position: absolute;
          margin-top: 100vh;
        "
      >
        <!-- FEATURED EVENT -->
        <div
          class="py-5 px-3 mx-0 featured-event"
          id="featured_section"
          v-if="returnFeaturingEvent !== undefined"
        >
          <div class="container px-0">
            <div class="col-12 px-0">
              <div class="row px-0">
                <div
                  class="
                    col-12 col-md-12
                    d-flex
                    text-center text-md-left text-lg-left
                    poppinsBold
                    title
                    text-gradient
                    featured-event-title
                  "
                  @click="openDialog(returnFeaturingEvent)"
                >
                  <span v-if="returnCurrentLanguage === 'EN'">
                    {{ returnFeaturingEvent.title_event_en }}
                  </span>
                  <span v-if="returnCurrentLanguage === 'ID'">
                    {{ returnFeaturingEvent.title_event }}
                  </span>
                </div>
                <div class="col-12 col-md-4"></div>
                <div
                  class="
                    col-md-12 col-12
                    py-3
                    poppinsBold
                    d-flex
                    align-items-center
                    justify-content-md-start justify-content-center
                  "
                  @click="openDialog(returnFeaturingEvent)"
                >
                  <!-- DATE -->
                  <span class="material-icons-round">event</span>
                  <span class="span ml-2">
                    {{ returnFeaturingEvent.date_to }}
                  </span>
                  <!-- END OF DATE -->
                  <!-- PARTICIPANTS -->
                  <span
                    class="material-icons-round mx-2"
                    v-if="returnFeaturingEvent.number_of_applicant === '-'"
                    >all_inclusive</span
                  >
                  <span
                    class="span"
                    v-if="
                      returnFeaturingEvent.number_of_applicant === '-' &&
                      returnCurrentLanguage === 'EN'
                    "
                  >
                    Unlimited Entries
                  </span>
                  <span
                    class="span"
                    v-if="
                      returnFeaturingEvent.number_of_applicant === '-' &&
                      returnCurrentLanguage === 'ID'
                    "
                  >
                    Tidak terbatas
                  </span>
                  <!-- END OF PARTICIPANTS -->
                </div>
              </div>
            </div>
            <div class="row px-3">
              <div
                class="col-md-5 col-12 px-0 image"
                @click="openDialog(returnFeaturingEvent)"
              >
                <img
                  :src="image_path_base + returnFeaturingEvent.url_image"
                  alt=""
                />
              </div>
              <div class="col-md-7 col-12">
                <div class="row">
                  <div
                    class="col-12 text-box"
                    @click="openDialog(returnFeaturingEvent)"
                  >
                    <!-- CONTENT -->
                    <span style="width: 100%">
                      <span
                        v-if="returnCurrentLanguage === 'EN'"
                        v-html="returnFeaturingEvent.event_en"
                      >
                      </span>
                      <span
                        v-if="returnCurrentLanguage === 'ID'"
                        v-html="returnFeaturingEvent.event_id"
                      >
                      </span>
                    </span>
                    <!-- END OF CONTENT -->
                  </div>
                  <div
                    @click="openDialog(returnFeaturingEvent)"
                    class="
                      col-12
                      text-box-overlay
                      d-flex
                      align-items-end
                      justify-content-center
                    "
                  >
                    <span class="text-gradient poppinsMedium see-more">
                      <span v-if="returnCurrentLanguage === 'EN'">
                        See More
                      </span>
                      <span v-if="returnCurrentLanguage === 'ID'">
                        Lihat Lebih Lanjut
                      </span>
                    </span>
                  </div>
                  <div class="col-12">
                    <vs-button
                      floating
                      gradient
                      block
                      class="poppinsBold"
                      @click="goToLink(returnFeaturingEvent.url_event)"
                    >
                      <span v-if="returnCurrentLanguage === 'EN'">
                        {{ returnFeaturingEvent.btn_en }}
                      </span>
                      <span v-if="returnCurrentLanguage === 'ID'">
                        {{ returnFeaturingEvent.btn }}
                      </span>
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF FEATURED EVENT -->
        <!-- EVENTS -->
        <div class="row px-0 mx-0 events-container">
          <div class="col-1"></div>
          <div
            class="col-11"
            style="margin-bottom: 10vh"
            id="event_holder"
            v-if="returnEventsBasedOnMonth !== undefined"
          >
            <div
              class="row px-5"
              style="margin-top: 50px; padding-top: 50px"
              v-for="(month, index) in returnEventsBasedOnMonth"
              :key="index + 'Month'"
            >
              <div class="col-12 py-0 months_section">
                <h4 class="poppinsBold">
                  <span
                    class="text-gradient"
                    :id="'month_header-' + month.month_name"
                  >
                    {{ month.month }} {{ month.year_launch }}
                  </span>
                </h4>
              </div>
              <!-- EVENT CARDS -->
              <div
                class="col-md-6 col-lg-4 col-12 px-2 my-2 events"
                v-for="(event, index) in month.events"
                :key="index"
              >
                <vs-card>
                  <template #title>
                    <div class="row">
                      <div
                        @click="openDialog(event)"
                        v-if="$screen.width < 700"
                        class="
                          col-6
                          d-flex
                          justify-content-md-start
                          text-gradient
                        "
                        style="font-size: 2.5vw"
                      >
                        <span
                          class="material-icons-round"
                          style="font-size: 3.5vw"
                          >calendar_month</span
                        >
                        <span class="span ml-2">
                          {{ event.date_to_l_format }}
                        </span>
                      </div>
                      <div
                        @click="openDialog(event)"
                        v-if="$screen.width < 700"
                        class="
                          col-6
                          d-flex
                          justify-content-center justify-content-md-start
                          text-gradient
                        "
                        style="font-size: 2.5vw"
                      >
                        <span
                          class="material-icons-round"
                          style="font-size: 3.5vw"
                          >all_inclusive</span
                        >
                        <span
                          class="span ml-2"
                          v-if="
                            event.number_of_applicant === '-' &&
                            returnCurrentLanguage === 'EN'
                          "
                          style="font-size: 2.5vw"
                        >
                          Unlimited Entries
                        </span>
                        <span
                          class="span ml-2"
                          v-if="
                            event.number_of_applicant === '-' &&
                            returnCurrentLanguage === 'ID'
                          "
                          style="font-size: 2.5vw"
                        >
                          Tidak terbatas
                        </span>
                      </div>
                    </div>
                    <h3 class="poppinsBold" @click="openDialog(event)">
                      <span v-if="returnCurrentLanguage === 'EN'">
                        {{ event.title_event_en }}
                      </span>
                      <span v-if="returnCurrentLanguage === 'ID'">
                        {{ event.title_event }}
                      </span>
                    </h3>
                  </template>
                  <template #img>
                    <img
                      :src="image_path_base + event.url_image"
                      alt=""
                      @click="openDialog(event)"
                    />
                    <!-- <img
                      src="@/assets/sample/sample_event_image.png"
                      alt=""
                      @click="openDialog(event)"
                    /> -->
                  </template>
                  <template #text>
                    <div class="row">
                      <div
                        v-if="$screen.width > 700"
                        @click="openDialog(event)"
                        class="
                          col-12 col-md-6
                          d-flex
                          justify-content-center justify-content-md-start
                          py-2
                          text-gradient
                        "
                      >
                        <span class="material-icons-round">calendar_month</span>
                        <span class="span ml-2"> {{ event.date_to }} </span>
                      </div>
                      <div
                        v-if="$screen.width > 700"
                        @click="openDialog(event)"
                        class="
                          col-12 col-md-6
                          d-flex
                          justify-content-center justify-content-md-start
                          py-2
                          text-gradient
                        "
                      >
                        <span class="material-icons-round">all_inclusive</span>
                        <span
                          class="span ml-2"
                          v-if="
                            event.number_of_applicant === '-' &&
                            returnCurrentLanguage === 'EN'
                          "
                        >
                          Unlimited Entries
                        </span>
                        <span
                          class="span ml-2"
                          v-if="
                            event.number_of_applicant === '-' &&
                            returnCurrentLanguage === 'ID'
                          "
                        >
                          Tidak terbatas
                        </span>
                      </div>
                      <div class="col-12">
                        <p
                          class=""
                          style="
                            max-height: 50px;
                            min-height: 50px;
                            overflow-y: hidden;
                          "
                          @click="openDialog(event)"
                        >
                          <span
                            v-if="returnCurrentLanguage === 'EN'"
                            v-html="event.event_en"
                          >
                          </span>
                          <span
                            v-if="returnCurrentLanguage === 'ID'"
                            v-html="event.event_id"
                          >
                          </span>
                        </p>
                        <span class="event_month">{{ event.month }}</span>
                      </div>
                      <!-- FOOTER BUTTON -->
                      <div class="col-12">
                        <vs-button
                          gradient
                          block
                          class="poppinsBold"
                          @click="goToLink(event.url_event)"
                        >
                          <span v-if="returnCurrentLanguage === 'EN'">
                            {{ event.btn_en }}
                          </span>
                          <span v-if="returnCurrentLanguage === 'ID'">
                            {{ event.btn }}
                          </span>
                        </vs-button>
                      </div>
                      <!-- END OF FOOTER BUTTON -->
                    </div>
                  </template>
                </vs-card>
              </div>
              <!-- END OF EVENT CARDS -->
            </div>
          </div>
          <div
            class="col-11 py-5"
            style="margin-bottom: 10vh; height: 50vh"
            id="event_holder"
            v-if="returnEventsBasedOnMonth === undefined"
          >
            <div class="row">
              <div
                class="col-12 d-flex justify-content-center poppinsBold"
                v-if="this.returnCurrentLanguage === 'EN'"
              >
                No events with the title
                <span class="poppinsBoldItalic ml-1">"{{ search_query }}"</span
                >.
              </div>
              <div
                class="col-12 d-flex justify-content-center poppinsBold"
                v-if="this.returnCurrentLanguage === 'ID'"
              >
                Tidak ada event dengan judul
                <span class="poppinsBoldItalic ml-1">"{{ search_query }}"</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF EVENTS -->
      </div>
    </div>
    <!-- dialog -->
    <vs-dialog
      v-model="show_dialog"
      style="max-height: 100vh"
      v-if="dialog_content !== null"
    >
      <div class="row mt-4 dialog">
        <div class="col-12 py-0 d-flex justify-content-center">
          <h1 class="not-margin text-gradient text-center poppinsBold py-2">
            <span v-if="returnCurrentLanguage === 'EN'">
              {{ dialog_content.title_event_en }}
            </span>
            <span v-if="returnCurrentLanguage === 'ID'">
              {{ dialog_content.title_event }}
            </span>
          </h1>
        </div>
        <div
          class="
            col-12
            py-3
            poppinsBold
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <!-- DATE -->
          <span class="material-icons-round">event</span>
          <span class="span ml-2">
            {{ dialog_content.date_to }}
          </span>
          <!-- END OF DATE -->
          <!-- PARTICIPANTS -->
          <span
            class="material-icons-round mx-2"
            v-if="returnFeaturingEvent.number_of_applicant === '-'"
            >all_inclusive</span
          >
          <span
            class="span"
            v-if="
              dialog_content.number_of_applicant === '-' &&
              returnCurrentLanguage === 'EN'
            "
          >
            Unlimited Entries
          </span>
          <span
            class="span"
            v-if="
              dialog_content.number_of_applicant === '-' &&
              returnCurrentLanguage === 'ID'
            "
          >
            Tidak terbatas
          </span>
          <!-- END OF PARTICIPANTS -->
        </div>
        <div class="col-12 d-flex justify-content-center">
          <div class="image">
            <img :src="image_path_base + dialog_content.url_image" alt="" />
          </div>
        </div>
        <div class="col-12 py-3">
          <p class="px-md-5 px-2">
            <span
              v-if="returnCurrentLanguage === 'EN'"
              v-html="dialog_content.event_en"
            >
            </span>
            <span
              v-if="returnCurrentLanguage === 'ID'"
              v-html="dialog_content.event_id"
            >
            </span>
          </p>
        </div>
      </div>

      <template #footer>
        <div class="footer-dialog">
          <vs-button
            floating
            gradient
            block
            class="poppinsBold"
            @click="goToLink(dialog_content.url_event)"
          >
            <span v-if="returnCurrentLanguage === 'EN'">
              {{ dialog_content.btn_en }}
            </span>
            <span v-if="returnCurrentLanguage === 'ID'">
              {{ dialog_content.btn }}
            </span>
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import eventsbg from "@/assets/bgs/events_bg.png";
import { VueTyper } from "vue-typer";
import axios from "axios";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
import TopNavEvents from "@/components/utils/TopNavEvents.vue";
import moment from "moment";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
export default {
  components: {
    VueTyper,
    TopNavEvents,
  },
  data() {
    return {
      currentMonth: undefined,
      search_query: "",
      no_results: false,
      months: [
        {
          en: "December",
          id: "Desember",
        },
        {
          en: "November",
          id: "November",
        },
        {
          en: "October",
          id: "Oktober",
        },
        {
          en: "September",
          id: "September",
        },
        {
          en: "August",
          id: "Agustus",
        },
        {
          en: "July",
          id: "Juli",
        },
        {
          en: "June",
          id: "Juni",
        },
        {
          en: "May",
          id: "Mei",
        },
        {
          en: "April",
          id: "April",
        },
        {
          en: "March",
          id: "Maret",
        },
        {
          en: "February",
          id: "Februari",
        },
        {
          en: "January",
          id: "Januari",
        },
      ],
      years: [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014],
      ids: [],
      dataready: false,
      eventsbg: eventsbg,

      event_path: "event/active?src=indoinfo",
      events_data: [],
      filtered_events: [],
      image_path_base: "https://portal.indoinfo.co.id/img/",
      loading: null,
      // dialog
      show_dialog: false,
      dialog_content: null,
    };
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: `Never miss out a single event again. We provide a list of events that you can join. From tech events, 
          to business events, to social events, we got you covered. Join us now!`,
      },
    ],
  },
  methods: {
    async getEvents() {
      let self = this;
      console.log("current captcha: ", this.returnSiteConfig);

      await load(self.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              console.log("GET TOKEN: ", token);
              axios
                .get(self.returnSiteConfig.e_p + self.event_path, {
                  params: {
                    init_token: token,
                  },
                })
                .then((res) => {
                  console.log("result events: ", res);
                  for (let i = 0; i < res.data.length; i++) {
                    let result_data = res.data[i];
                    console.log("current event: ", result_data.date_to);
                    console.log(
                      "current day: ",
                      moment(result_data.date_to).isAfter(moment())
                    );

                    result_data.is_unlaunched = moment(
                      result_data.date_to
                    ).isAfter(moment());

                    // get the year of date_to
                    result_data.year_launch = moment(
                      result_data.date_to
                    ).year();

                    result_data.date_to = moment(result_data.date_to).format(
                      "MMMM DD, YYYY"
                    );
                    result_data.date_from = moment(
                      result_data.date_from
                    ).format("MMMM DD, YYYY");

                    result_data.date_from_month = moment(
                      result_data.date_from
                    ).format("MMMM");
                    result_data.date_to_month = moment(
                      result_data.date_to
                    ).format("MMMM");

                    result_data.date_to_l_format = moment(
                      result_data.date_to
                    ).format("L");

                    self.events_data.push(result_data);
                    console.log("EVENTS DATA: ", self.events_data);

                    if (i == res.data.length - 1) {
                      self.dataready = true;
                    }
                  }
                })
                .catch((error) => {
                  console.log(
                    "EVENT ERROR on axios",
                    error,
                    self.returnSiteConfig.e_p + self.event_path
                  );
                })
                .catch((error) => {
                  console.log("EVENT ERROR get data token error", error);
                });
            })
            .catch((error) => {
              console.log("EVENT ERROR get data recaptcha error", error);
            });
        }
      );
    },
    initiateScrollTrigger() {
      console.log("scroll trigger kicked", this.dataready);
      var current_month = { var: "" };
      let self = this;
      gsap.utils.toArray(".months_section").forEach((title) => {
        console.log("GSAP TITLE: ", title);
        // get text of title
        const text = title.innerText;
        console.log("GSAP TEXT: ", text);
        gsap
          .timeline({
            scrollTrigger: {
              trigger: title,
              duration: 5,
              // scrollTrigger: ".months_section",
              start: "top 45%",
              end: "+=0",
              scrub: true,
              // markers: true,
            },
          })
          .to(current_month, {
            var: title,
            duration: 1,
            ease: "none",
            onUpdate: changeNumber,
          });
      });

      function changeNumber() {
        self.currentMonth = current_month.var.innerText;
        console.log("change text: ", current_month.var.innerText);
        // if (num.var > 0 && num.var >= count) {
        //   self.animateValue(0, count, 500);
        // }
      }
    },
    initiateChangeDate(newVal, oldVal) {
      let month = null;
      if (newVal !== undefined) {
        console.log("RUNNING OP", oldVal, newVal);
        month = newVal;
        this.currentMonth = undefined;
        setTimeout(() => {
          this.currentMonth = month;
          console.log("running op month changed", this.currentMonth);
        }, 80);
      }
    },

    goToLink(event_link) {
      window.open(event_link, "_blank");
    },
    openDialog(content) {
      this.dialog_content = content;
      this.show_dialog = true;
    },
    scrollToSection(to) {
      if (this.$route.query.q !== undefined || this.search_query !== "") {
        // console.log("SCROLL TO SECTION: ", section);
        gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: to,
            offsetY: 50,
          },
        });
      }
    },

    initiateLoading() {
      this.loading = this.$vs.loading({
        type: "scale",
        text: "What events are happening?",
      });
    },
    // groupEventsPerYear() {
    //   return new Promise((resolve, reject) => {
    //     let events_per_year = [];
    //     for (let x = 0; x < this.years.length; x++) {
    //       let year = this.years[x];
    //       let events_per_year_data = this.events_data.filter((event) => {
    //         return event.year_launch == year;
    //       });
    //       events_per_year_data.sort((a, b) => {
    //         return moment(a.date_to).unix() - moment(b.date_to).unix();
    //       });

    //       if (events_per_year_data.length > 0) {
    //         events_per_year.push({
    //           year: year,
    //           events: events_per_year_data,
    //         });
    //       }
    //       if (x == this.years.length - 1) {
    //         resolve(events_per_year);
    //       }
    //     }
    //   });
    // },
  },
  computed: {
    ...mapGetters(["siteConfig", "language"]),
    returnSiteConfig() {
      return this.siteConfig;
    },

    groupEventsPerMonth() {
      // find all years in events_data
      let data_to_use = null;
      this.filtered_events.length > 0
        ? (data_to_use = this.filtered_events)
        : (data_to_use = this.events_data);

      const array = data_to_use;
      const unique_years = [...new Set(array.map((item) => item.year_launch))];
      console.log("UNIQUE YEARS: ", unique_years);

      // find all months in data_to_use
      const array_months = data_to_use;
      const unique_months = [
        ...new Set(array_months.map((item) => item.date_to_month)),
      ];
      console.log("UNIQUE MONTHS: ", unique_months);

      let per_year_events = [];
      data_to_use.filter((event) => {
        // return event.year_launch == unique_years[0];
        // return if event year_launch is in unique_years array

        // find in per_year_events if year_launch is already in array
        if (
          per_year_events.find((e) => e.year_launch == event.year_launch) &&
          per_year_events.find((x) => x.month == event.date_to_month)
        ) {
          // if year_launch and month is already in array, push event to per_year_events
          per_year_events
            .find((e) => e.year_launch == event.year_launch)
            .events.push(event);
        } else {
          // if year_launch is not in array, push year_launch and event to per_year_events
          if (this.language === "EN") {
            per_year_events.push({
              year_launch: event.year_launch,
              month: event.date_to_month,
              events: [event],
            });
          }
          if (this.language === "ID") {
            let indo_month = this.months.find((month) => {
              return month.en == event.date_to_month;
            });

            per_year_events.push({
              year_launch: event.year_launch,
              month: indo_month.id,
              events: [event],
            });
          }
        }
      });

      // for (let i = 0; i < this.months.length; i++) {
      //   let month = this.months[i].en;
      //   let events = this.events_data.filter((event) => {
      //     return event.date_to_month === month;
      //   });
      //   if (events.length > 0) {
      //     if (this.language === "ID") {
      //       events_per_month.push({
      //         month_name: this.months[i].id,
      //         events: events,
      //       });
      //     }
      //     if (this.language === "EN") {
      //       events_per_month.push({
      //         month_name: this.months[i].en,
      //         events: events,
      //       });
      //     }
      //   }
      // }
      // console.log("log events per month", events_per_month);
      // return events_per_month;

      console.log("log events per year", per_year_events.length);
      return per_year_events;
    },
    returnEventsBasedOnMonth() {
      if (this.search_query === "") {
        this.filtered_events = [];
        this.no_results = false;

        return this.groupEventsPerMonth;
      } else {
        console.log("search not nulled");
        // find in groupEventsPerMonth that includes the search_query in the event name or description
        let filtered_events = this.events_data.filter((event) => {
          return (
            event.title_event
              .toLowerCase()
              .includes(this.search_query.toLowerCase()) ||
            event.title_event_en
              .toLowerCase()
              .includes(this.search_query.toLowerCase())
          );
        });
        if (filtered_events.length > 0) {
          this.no_results = false;
          this.filtered_events = filtered_events;
          return this.groupEventsPerMonth;
          // let events_per_month = [];
          // for (let i = 0; i < this.months.length; i++) {
          //   let month = this.months[i].en;
          //   let events = filtered_events.filter((event) => {
          //     return event.date_to_month === month;
          //   });
          //   if (events.length > 0) {
          //     if (this.language === "ID") {
          //       events_per_month.push({
          //         month_name: this.months[i].id,
          //         events: events,
          //       });
          //     }
          //     if (this.language === "EN") {
          //       events_per_month.push({
          //         month_name: this.months[i].en,
          //         events: events,
          //       });
          //     }
          //   }
          // }
          // return events_per_month;
        } else {
          this.no_results = true;
        }
        console.log("filterd events: ", filtered_events);
      }
    },
    returnCurrentLanguage() {
      return this.language;
    },
    returnUnlaunchedEvents() {
      let is_unlaunched = [];
      for (let i = 0; i < this.events_data.length; i++) {
        let event = this.events_data[i];
        if (event.is_unlaunched) {
          is_unlaunched.push(event);
        }
      }
      return is_unlaunched;
    },

    returnFeaturingEvent() {
      if (this.$route.query.q !== undefined) {
        // find in events_data slug and slug_en if this.Route.query.q is in slug or slug_en
        let featuring_event = this.events_data.filter((event) => {
          return (
            event.slug === this.$route.query.q ||
            event.slug_en === this.$route.query.q
          );
        });
        console.log("FEATURING EVENT: ", featuring_event);
        if (featuring_event.length > 0) {
          this.dialog_content = featuring_event[0];
          this.show_dialog = true;
          return featuring_event[0];
        }
        if (featuring_event.length === 0) {
          return this.returnUnlaunchedEvents[0];
        }
      } else {
        return this.returnUnlaunchedEvents[0];
      }
    },
  },
  watch: {
    currentMonth(newVal, oldVal) {
      if (newVal !== oldVal && oldVal !== undefined) {
        this.initiateChangeDate(newVal, oldVal);
      }
    },
    dataready(val) {
      if (val) {
        setTimeout(() => {
          this.initiateScrollTrigger();
          this.scrollToSection("#featured_section");
          this.loading.close();
        }, 2000);
      }
    },
    search_query(val) {
      if (val !== "") {
        this.scrollToSection("#event_holder");
      }
    },
  },
  mounted() {
    this.getEvents();
    this.initiateLoading();
    // this.$nextTick(() => {

    // });
  },
};
</script>

<style lang="scss" scoped>
.text-gradient {
  background: rgb(57, 0, 138);
  background: linear-gradient(
    30deg,
    rgba(0, 82, 180, 1) 30%,
    rgb(104, 0, 250) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-green {
  background: rgb(0, 188, 154);
  background: linear-gradient(
    30deg,
    rgba(0, 188, 154, 1) 40%,
    rgba(235, 255, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dialog {
  max-height: 80vh;
  overflow-y: auto;
  .image {
    img {
      width: auto;
      max-width: 100%;
      border-radius: 20px;
      max-height: 50vh;
    }
  }
}
.events_bg {
  min-height: 100vh;
  // background: #000;
  // background: rgb(21, 17, 54);
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 17, 54, 1) 0%,
  //   rgba(3, 74, 164, 1) 100%
  // );

  .aside {
    // transform: rotate(-90deg);
    // transform-origin: bottom right;
    position: fixed;
    top: 40%;
    left: 5%;
    font-size: 40px;
    z-index: 99;
    .rotated-text {
      display: inline-block;
      overflow: hidden;
      width: 1.5em;
      line-height: 1.5;
      .rotated-text__inner {
        display: inline-block;
        white-space: nowrap;
        transform: translate(0, 100%) rotate(-90deg);
        transform-origin: 0 0;
      }

      .rotated-text__inner::after {
        content: "";
        float: left;
        margin-top: 100%;
      }
    }
  }

  .bg-text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.268);
    .engage-text {
      font-size: 9vw !important;
    }
  }
  .bg-container {
    height: 100vh;
    min-height: 95vh;
    width: 100%;
    position: fixed;
    .bg {
      height: 100%;
      min-height: 95vh;
      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }
  }
  .featured-event {
    background-color: #f4f4f4;
    .featured-event-title {
      font-size: 3vw !important;
    }
    cursor: pointer;
    .title {
      font-size: 2.5rem;
    }

    .image {
      img {
        width: 100%;
        border-radius: 20px;
        max-height: 50vh;
      }
    }

    .text-box {
      max-height: 40vh;
      overflow-y: hidden;
    }
    .text-box-overlay {
      position: absolute;
      height: 100%;
      margin-bottom: 50px;
      // background-color: rgba(255, 0, 0, 0.5);
      background: linear-gradient(
        0deg,
        #f4f4f4 30%,
        rgba(255, 255, 255, 0) 100%
      );
      .see-more {
        margin-bottom: 15%;
        transition: transform ease 300ms;
        cursor: pointer;
      }
      .see-more:hover {
        // move up 10px
        transform: translateY(-10px);
      }
    }

    // for small screens
    @media only screen and (max-width: 600px) {
      .featured-event-title {
        font-size: 9vw !important;
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(15px);
  opacity: 0;
}
/* rules that target drain elements */
</style>