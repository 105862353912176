<template>
  <div
    class="container-fluid mx-0 white d-flex justify-content-center"
    style="background: #3a6ab1"
  >
    <div class="row container px-2 px-md-5 py-5">
      <div class="col-12 col-md-8">
        <h3 class="openSansBold">{{ header }}</h3>
        <p>{{ description }}</p>
      </div>
      <div class="col-12 col-md-4 d-flex align-items-center justify-content-center">
        <vs-button block color="#08A2E1" @click="openPDF()">
          <h5 class="py-0 my-0 openSansBold">{{ button.text }}</h5>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    description: String,
    button: Object,
  },
  data() {
    return {};
  },

  methods: {
    openPDF() {
      window.open(this.button.link, "_blank", "fullscreen=yes");
    },
  },
};
</script>

<style lang="scss" scoped></style>
