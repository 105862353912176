let ID = {
  page_section: {
    page_title: "ID CQ Speak Up - Whistleblowing System",
    page_subtitle:
      "Whistleblowing System adalah aplikasi yang disediakan oleh indoinfo untuk Anda yang memiliki informasi dan ingin melaporkan suatu perbuatan yang berindikasi pelanggaran.",
  },
  explanation_section: [
    {
      type: "paragraph",
      content:
        "Dalam rangka menjaga serta meningkatkan reputasi indoinfo, diperlukan sarana dan sistem pengendalian risiko melalui partisipasi aktif dari mitra bisnis indoinfo untuk menyampaikan laporan pengaduan pelanggaran disiplin seperti perbuatan atau indikasi fraud, non fraud dan/atau pelanggaran lainnya yang dapat merugikan klien maupun indoinfo.",
    },
    {
      type: "paragraph",
      content:
        "Hal ini sejalan dengan Strategi Anti Fraud (SAF) yang merupakan bagian dari sistem pengendalian fraud yang salah satu diantaranya mencakup mekanisme Whistle Blowing System (WBS). Dalam implementasinya, kami telah menyediakan media pelaporan pengaduan pelanggaran tersebut secara langsung kepada tim Kepatuhan (compliance) yang dinamakan CQ Speak Up.",
    },
    {
      type: "h4",
      content: "Kerahasiaan Pelapor",
      class: "c-primary poppinsBold",
    },
    {
      type: "span",
      contents: [
        {
          class: "",
          content: "Sebagai wujud komitmen kami,",
        },
        {
          class: "poppinsBold",
          content:
            "identitas pribadi Anda sebagai whistleblower dan isi laporan yang disampaikan akan kami rahasiakan,",
        },
        {
          class: "",
          content:
            "karena kami hanya fokus pada informasi yang Anda laporkan. Untuk kerahasiaan lebih terjaga, dimohon untuk perhatikan hal-hal berikut:",
        },
      ],
    },
    {
      type: "ordered-list",
      contents: [
        {
          content:
            "Laporan dapat dibuat anonym dengan menggunakan nama samaran.",
        },
        {
          content:
            "Hindari orang lain mengetahui nama samaran (username), kata sandi (password) serta nomor registrasi Anda.",
        },
      ],
    },
  ],
  form_section: {
    title: "Form Pengaduan",
    inputs: [
      {
        id: "fullName",
        placeholder: "Nama Pelapor",
        input: "",
        render_type: "vs",
        type: "text",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 100,
            min_length: 3,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "phone",
        placeholder: "No. Telepon",
        input: "",
        render_type: "vs",
        type: "number",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 12,
            min_length: 11,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long. DO NOT USE Country Codes e.g. +62`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long. DO NOT USE Country Codes e.g. +62`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "email",
        placeholder: "Alamat E-mail",
        input: "",
        render_type: "vs",
        type: "email",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 100,
            min_length: 10,
            curr_length: this.input.length,
          };
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(this.input)) {
            return {
              status: false,
              message:
                "Please provide a legitimate email address for us to reach back to you",
              configs,
            };
          } else if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "reported_person",
        placeholder: "Nama Yang Dilaporkan",
        input: "",
        render_type: "vs",
        type: "text",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 100,
            min_length: 3,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "complaints",
        placeholder: "Pelanggaran Yang Dilaporkan",
        input: [],
        render_type: "vs-select-multiple",
        type: "textarea",
        output_variable: "_outputvariable_",
        options: [
          {
            key: 1,
            value: "Fraud",
          },
          {
            key: 2,
            value: "Kebocoran Informasi",
          },
          {
            key: 3,
            value: "Pencucian Uang",
          },
          {
            key: 4,
            value:
              "Pelanggaran Non Fraud Terhadap Norma Dan Etika (Code of Conduct)",
          },
          {
            key: 5,
            value: "Gratifikasi/Suap",
          },
          {
            key: 6,
            value: "Korupsi",
          },
        ],
        isValid: function () {
          if (this.input.length == 0) {
            return {
              status: false,
              message: "Select at least one from the options",
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
      {
        id: "chronology",
        placeholder: "Kronologi Kejadian",
        input: "",
        render_type: "textarea",
        type: "textarea",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 500,
            min_length: 50,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "incident_time",
        placeholder: "Waktu Kejadian",
        input: "",
        render_type: "vs",
        type: "date",
        output_variable: "_outputvariable_",
        isValid: function () {
          if (this.input == undefined || this.input.length == 0) {
            return {
              status: false,
              message: "Date is required",
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
      {
        id: "incident_loc",
        placeholder: "Lokasi Kejadian",
        input: "",
        render_type: "vs",
        type: "text",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 200,
            min_length: 5,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "evidence",
        placeholder: "Apakah ada bukti yang dapat diberikan kepada kami?",
        input: true,
        render_type: "vs-switch",
        type: "switch",
        output_variable: "_outputvariable_",
        switch_configs: {
          on: "Ya, Ada",
          off: "Belum Ada",
        },
      },
      {
        id: "involved_person",
        placeholder:
          "Apakah ada orang lain yang terlibat? Jika iya, siapakah mereka?",
        input: "",
        render_type: "textarea",
        type: "textarea",
        output_variable: "_outputvariable_",
        isValidityOptional: true,
        isValid: function () {
          let configs = {
            max_length: 500,
            min_length: 50,
            curr_length: this.input.length,
          };

          if (this.input.length > 0 && this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
      {
        id: "report_to_othr_person",
        placeholder:
          "Apakah Anda telah melaporkan insiden tersebut secara internal melalui saluran lain? Jika iya, kepada siapa Anda membuat laporan tersebut?",
        input: "",
        render_type: "textarea",
        type: "textarea",
        output_variable: "_outputvariable_",
        isValidityOptional: true,
        isValid: function () {
          let configs = {
            max_length: 500,
            min_length: 50,
            curr_length: this.input.length,
          };

          if (this.input.length > 0 && this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
    ],
  },
};

let EN = {
  page_section: {
    page_title: "CQ Speak Up - Whistleblowing System",
    page_subtitle:
      "Whistleblowing System is an application provided by Indoinfo for those of you who have information and want to report an action that indicates a violation.",
  },
  explanation_section: [
    {
      type: "paragraph",
      content:
        "To maintain and improve Indoinfo's reputation, risk control facilities and systems are needed through active participation from Indoinfo business partners to submit reports of complaints about disciplinary violations such as acts or indications of fraud, non-fraud and/or other violations that can harm clients and Indoinfo.",
    },
    {
      type: "paragraph",
      content:
        "This is in line with the Anti-Fraud Strategy (SAF), which is part of the fraud control system and includes a Whistle Blowing System (WBS) mechanism. In its implementation, we have provided a platform for direct reporting of violations to the Compliance team, called CQ Speak Up.",
    },
    {
      type: "h4",
      content: "Whistleblower Confidentiality",
      class: "c-primary poppinsBold",
    },
    {
      type: "span",
      contents: [
        {
          class: "",
          content: "As a form of our commitment,",
        },
        {
          class: "poppinsBold",
          content:
            "your personal identity as a whistleblower and the contents of the report submitted will be kept confidential,",
        },
        {
          class: "",
          content:
            "because we only focus on the information you report. To better maintain confidentiality, please pay attention to the following:",
        },
      ],
    },
    {
      type: "ordered-list",
      contents: [
        {
          content: "Reports can be made anonymously using a pseudonym.",
        },
        {
          content:
            "Avoid other people knowing your username, password and registration number.",
        },
      ],
    },
  ],
  form_section: {
    title: "Complaint Form",
    inputs: [
      {
        id: "fullName",
        placeholder: "Name of the Complainant",
        input: "",
        render_type: "vs",
        type: "text",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 100,
            min_length: 3,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "phone",
        placeholder: "Phone Number",
        input: "",
        render_type: "vs",
        type: "number",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 12,
            min_length: 11,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long. DO NOT USE Country Codes e.g. +62`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long. DO NOT USE Country Codes e.g. +62`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "email",
        placeholder: "E-mail Address",
        input: "",
        render_type: "vs",
        type: "email",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 100,
            min_length: 10,
            curr_length: this.input.length,
          };
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(this.input)) {
            return {
              status: false,
              message:
                "Please provide a legitimate email address for us to reach back to you",
              configs,
            };
          } else if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "reported_person",
        placeholder: "Reported Name",
        input: "",
        render_type: "vs",
        type: "text",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 100,
            min_length: 3,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "complaints",
        placeholder: "Reported Violations",
        input: [],
        render_type: "vs-select-multiple",
        type: "textarea",
        output_variable: "_outputvariable_",
        options: [
          {
            key: 1,
            value: "Fraud",
          },
          {
            key: 2,
            value: "Information Leak",
          },
          {
            key: 3,
            value: "Money Laundering",
          },
          {
            key: 4,
            value: "Non-Fraud Violation of Norms and Ethics (Code of Conduct)",
          },
          {
            key: 5,
            value: "Gratification/Bribery",
          },
          {
            key: 6,
            value: "Corruption",
          },
        ],
        isValid: function () {
          if (this.input == undefined || this.input.length == 0) {
            return {
              status: false,
              message: "Select at least one from the options",
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
      {
        id: "chronology",
        placeholder: "Detail of The Incident",
        input: "",
        render_type: "textarea",
        type: "textarea",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 500,
            min_length: 50,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "incident_time",
        placeholder: "Time of Incident",
        input: "",
        render_type: "vs",
        type: "date",
        output_variable: "_outputvariable_",
        isValid: function () {
          if (this.input == undefined || this.input.length == 0) {
            return {
              status: false,
              message: "Date is required",
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
      {
        id: "incident_loc",
        placeholder: "Location of The Incident",
        input: "",
        render_type: "vs",
        type: "text",
        output_variable: "_outputvariable_",
        isValid: function () {
          let configs = {
            max_length: 200,
            min_length: 5,
            curr_length: this.input.length,
          };
          if (this.input.length < configs.min_length) {
            return {
              status: false,
              message: `Input length must be at least ${configs.min_length} chars long.`,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.min_length,
              },
            };
          } else if (this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else if (
            this.input.length <= configs.max_length &&
            this.input.length > configs.min_length
          ) {
            return {
              status: true,
              configs: {
                curr_length: configs.curr_length,
                max_length: configs.max_length,
              },
            };
          } else {
            return {
              status: true,
              configs,
            };
          }
        },
      },
      {
        id: "evidence",
        placeholder: "Are there any evidences that can be given to us?",
        input: true,
        render_type: "vs-switch",
        type: "switch",
        output_variable: "_outputvariable_",
        switch_configs: {
          on: "Yes",
          off: "No",
        },
      },
      {
        id: "involved_person",
        placeholder:
          "Are there any other people involved? If yes, who are they?",
        input: "",
        render_type: "textarea",
        type: "textarea",
        output_variable: "_outputvariable_",
        isValidityOptional: true,
        isValid: function () {
          let configs = {
            max_length: 500,
            min_length: 50,
            curr_length: this.input.length,
          };

          if (this.input.length > 0 && this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
      {
        id: "report_to_othr_person",
        placeholder:
          "Have you reported the incident internally through other channels? If yes, to whom did you make the report?",
        input: "",
        render_type: "textarea",
        type: "textarea",
        output_variable: "_outputvariable_",
        isValidityOptional: true,
        isValid: function () {
          let configs = {
            max_length: 500,
            min_length: 50,
            curr_length: this.input.length,
          };

          if (this.input.length > 0 && this.input.length > configs.max_length) {
            return {
              status: false,
              message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
              configs,
            };
          } else {
            return {
              status: true,
              message: "",
            };
          }
        },
      },
    ],
  },
};

export { EN, ID };
