var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-0 mx-0",staticStyle:{"padding-bottom":"50px","padding-top":"50px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row px-2 mx-0 align-self-center"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('h2',{staticClass:"openSansBold text-center"},[_vm._v(" "+_vm._s(_vm.left_splitted_header)),_c('span',{staticClass:"emphasis"},[_vm._v(_vm._s(_vm.outlined_header))]),_vm._v(_vm._s(_vm.right_splitted_header)+" ")])]),_vm._m(0),_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('div',_vm._l((_vm.accordions),function(accordion,index){return _c('div',{key:index + 'accordion',staticClass:"card",staticStyle:{"min-width":"70vw","max-width":"70vw"}},[(accordion.title !== false)?_c('div',{staticClass:"card-header pointer",on:{"click":function($event){return _vm.dropAccordion(accordion, index)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 d-flex justify-content-start openSansMedium"},[_vm._v(" "+_vm._s(accordion.title)+" ")]),_c('div',{staticClass:"col-2 d-flex justify-content-end align-items-center"},[_c('span',{staticClass:"material-icons-round",class:{
                      rotate: accordion.body_show,
                      unrotate: !accordion.body_show,
                    }},[_vm._v("keyboard_arrow_down")])])])]):_vm._e(),_c('div',{staticClass:"collapse",class:{ show: accordion.body_show },attrs:{"id":"collapseOne","aria-labelledby":"headingOne","data-parent":"#accordion"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{class:[
                      {
                        'col-12': !accordion.list,
                        'col-12 col-md-6': accordion.list,
                      },
                    ]},_vm._l((accordion.body),function(body,index){return _c('div',{key:'accordion-body' + index,staticClass:"row"},[(!accordion.list)?_c('div',{staticClass:"col-12 my-2"},[_vm._v(" "+_vm._s(body)+" ")]):_vm._e(),(accordion.list)?_c('div',{staticClass:"col-12"},[_c('ul',[_c('li',{staticStyle:{"list-style":"none"}},[_vm._v(_vm._s(body))])])]):_vm._e()])}),0)])])])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 py-3 d-flex justify-content-center"},[_c('img',{staticStyle:{"max-width":"100%","max-height":"100%"},attrs:{"src":require("@/assets/others/reason_image_tab.png"),"alt":""}})])
}]

export { render, staticRenderFns }