<template>
  <div>
    <!-- class="bg"
      v-bind:style="{ 'background-image': 'url(' + searchbg + ')' }" -->
    <div class="container d-flex align-items-center" style="height: 100%">
      <div class="row pb-0 pb-md-5">
        <div class="col-12 col-md-12 d-flex align-items-center order-md-0">
          <div class="row mx-0">
            <div class="col-12 px-0 pb-0 pb-md-4">
              <h1 class="page-title openSansBold text-center">
                {{ left_splitted_header
                }}<span>{{ outlined_header }} <br /></span
                >{{ right_splitted_header }}
              </h1>
            </div>
            <div class="col-12 px-0 search-input-container">
              <b-form-input
                size="lg"
                placeholder="Search Companies"
                id="searchinput"
                style="padding-right: 40px"
                aria-autocomplete="off"
                autocomplete="off"
                ref="search_input"
                @click="openSearchDialog()"
                v-model="search_company_input"
              ></b-form-input>
              <span
                class="material-icons d-flex justify-content-end align-items-center c-primary"
                style="
                  position: absolute;
                  cursor: pointer;
                  z-index: 1;
                  top: 0;
                  right: 0;
                  transform: translateY(50%) translateX(-50%);
                "
              >
                search
              </span>
            </div>

            <div class="col-12 mx-0 px-0 mt-2">
              <h4
                style="color: #fff; width: 100%"
                class="openSansRegular text-center subtitle"
              >
                {{ subtitle1 }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchbackground from "@/assets/bgs/bg_discuss1.png";
import { mapGetters } from "vuex";
import CompanySearch from "../utils/CompanySearch.vue";
export default {
  components: {
    CompanySearch,
  },
  props: {
    placeholder: String,
    subtitle1: String,
    subtitle2: String,
    header: String,
    outlined_text: String,
    current_language: String,
    subtitle1: String,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      searchbg: searchbackground,
      search_company_input: "",
      // axios search
    };
  },
  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        console.log("CHANGING LANG");
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log("findword and return finished");
      } else {
        console.log("overview feature missing/could not find outlined text.");
      }
    },
    openSearchDialog() {
      this.$store.dispatch("cs/CHANGE_DIALOG_STATE", true);
    },
  },
  computed: {
    ...mapGetters(["siteConfig"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.CutandDump();
  },
  watch: {
    current_language() {
      this.CutandDump();
    },

    search_company_input(val) {
      console.log("search_company_input watch", val);
      if (val.length > 0) {
        this.openSearchDialog();

        setTimeout(() => {
          this.search_company_input = "";
        }, 100);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  height: 100vh;
  min-height: 95vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  height: 100vh;
  min-height: 95vh;
}

.page-title {
  color: #fff;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 3vw !important;
}

.subtitle {
  font-size: 2vw !important;
}

@media only screen and (max-width: 600px) {
  .page-title {
    font-size: 9vw !important;
  }

  .subtitle {
    font-size: 5vw !important;
  }
}

img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.search-input-container {
  position: relative;
}
</style>
