<template>
  <div
    class="container-fluid mx-0 px-4 d-flex align-items-center"
  >
    <div class="container">
      <div class="row" style="max-width: 100vw; overflow-x: hidden">
        <div class="col-12">
          <h2
            class="openSansBold py-3"
            :class="{
              'text-center': this.text_align === 'center',
              'text-left': this.text_align === 'left',
              'text-right': this.text_align === 'right',
            }"
          >
            {{ left_splitted_header
            }}<span class="emphasis">{{ outlined_text }}</span
            >{{ right_splitted_header }}
          </h2>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <p
            :class="{
              'text-center': this.text_align === 'center',
              'text-left': this.text_align === 'left',
              'text-right': this.text_align === 'right',
            }"
          >
            {{ description }}
          </p>
        </div>
        <div
          class="col-12 d-flex"
          :class="{
            'justify-content-center': this.text_align === 'center',
            'justify-content-start': this.text_align === 'left',
            'justify-content-end': this.text_align === 'right',
          }"
        >
        </div>
        <div class="col d-flex justify-content-center align-items-center" style="height: 180px" v-for="(image, index) in images" :key="index + 'image'">
            <img :src="image" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aspiluki from "@/assets/partners/ic_aspiluki.png";
import cyberquote from "@/assets/partners/ic_cyberquote.png";
import dunbradstreet from "@/assets/partners/ic_dunbradstreet.png";
import swisscham from "@/assets/partners/ic_swisscham.png";
import padi from "@/assets/partners/padi.png";
import phillipcapital from "@/assets/partners/phillipcapital.png";

export default {
  props: {
    header: String,
    description: String,
    outlined_text: String,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      images: [aspiluki, cyberquote, swisscham, phillipcapital, padi],
      text_align: 'center'
    };
  },

  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("could not find");
      }
    },
    goToLink() {
      var x = {
        state: true,
        tab: "Register",
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
  },
  watch: {
    header() {
      this.CutandDump();
    },
  },
  mounted() {
    this.CutandDump();
  },

  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
img {
  min-height: 20px;
  height: 40%;
  width: auto;
}
</style>