<template>
  <div class="container">
    <h2 class="text-center openSansBold">
      {{ left_splitted_header
      }}<span class="emphasis">{{ outlined_header }}</span
      >{{ right_splitted_header }}
    </h2>

    <!-- TABLE FOR MEDIUM SCREENS -->
    <div class="pricing" v-if="$screen.md">
      <div class="container">
        <div class="pricing-table table-responsive">
          <table class="table">
            <!-- Heading -->
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="'table-header' + index"
                  :class="{
                    openSansSemiBold: index > 0,
                    openSansBold: index == 0,
                  }"
                >
                  <h5>{{ header }}</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in table_rows" :key="index">
                <td
                  v-for="(element, index) in row.element"
                  :key="'table-el' + index"
                >
                  <span :class="{ 'ptable-title': index == 0 }">
                    {{ element }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- CARDS FOR SMALL SCREEN -->
    <div class="row px-0 mx-0" v-if="!$screen.md">
      <div class="col-12">
        <carousel-3d
          :space="365"
          :display="3"
          :height="480"
          :width="350"
          :clickable="true"
          :perspective="0"
          :controls-visible="true"
        >
          <slide
            v-for="(slide, i) in table_rows"
            :index="i"
            :key="i"
            class="slide"
          >
            <div class="row">
              <div class="col-12 px-4">
                <div class="card">
                  <div class="card-header openSansBold uppercase text-center">
                    {{ slide.element[0] }}
                  </div>
                  <div class="card-body">
                    <div class="openSansSemiBold">
                      <span v-if="current_language === 'ID'">
                        Melalui Indoinfo:
                      </span>
                      <span v-if="current_language === 'EN'">
                        Through Indoinfo:
                      </span>
                    </div>

                    {{ slide.element[1] }}
                  </div>
                </div>
              </div>
              <div class="col-12 px-4 py-2">
                <div class="card">
                  <div class="card-body">
                    <div class="openSansSemiBold">
                      <span v-if="current_language === 'ID'">
                        Cara Tradisional:
                      </span>
                      <span v-if="current_language === 'EN'">
                        Traditional Method:
                      </span>
                    </div>
                    {{ slide.element[2] }}
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    outlined_text: String,
    table_rows: Array,
    current_language: String,
    headers: Array,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      tests: [1, 2, 3, 4],
    };
  },
  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("could not find");
      }
    },
  },
  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    current_language(val) {
      console.log("COMPARISON: ", val);
      this.CutandDump();
    },
  },
  mounted() {
    this.CutandDump();
  },
};
</script>

<style lang="scss" scoped>
.pricing {
  margin: 40px 0px;
}
.pricing .table {
  border-top: 1px solid #ddd;
  background: #f9f9f9;
}
.pricing .table th,
.pricing .table {
  text-align: center;
}
.pricing .table th,
.pricing .table td {
  padding: 20px 10px;
  border: 1px solid #ddd;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.pricing .table th:first-child {
  width: 30%;
  font-weight: 400;
  border-bottom: 0px;
  background: #fff;
  color: #003d87;
  border-top-left-radius: 20px;
}
.pricing .table th:nth-child(2) {
  width: 35%;
  font-weight: 400;
  border-bottom: 0px;
  color: #003d87;
  background: #cbe1f7;
}
.pricing .table th:last-child {
  font-weight: 400;
  border-bottom: 0px;
  color: #fff;
  background: #0052b4;
  border-top-right-radius: 20px;
}
.pricing .table th.highlight {
  border-top: 4px solid #4caf50 !important;
}
.pricing .table td:first-child {
  text-align: center;
  padding-top: 35px;
  background: #fff;
  border-bottom: 3px solid #f3f8ff;
}
.pricing .table td:nth-child(2) {
  text-align: center;
  padding-top: 35px;
  background: #cbe1f7;
  border-bottom: 3px solid #aacffe;
  color: #003d87;
}
.pricing .table td:last-child {
  text-align: center;
  padding-top: 35px;
  background: #0052b4;
  border-bottom: 3px solid #004fad;
  color: #fff;
}

.pricing tr td .ptable-title {
  font-size: 20px;
  font-weight: 400;
  color: #003d87;
}

.pricing .table tr:last-child {
  td {
    border-bottom: none;
  }
  td:first-child {
    border-bottom-right-radius: 20px;
  }

  td:last-child {
    border-bottom-right-radius: 20px;
  }
}

.pricing tr td .ptable-title i {
  width: 23px;
  line-height: 25px;
  text-align: right;
  margin-right: 5px;
}

.pricing .ptable-price {
  display: block;
}
.pricing tr td {
  font-size: 16px;
  line-height: 32px;
}
.pricing tr td:first-child i {
  display: inline;
  margin-bottom: 0px;
  font-size: 22px;
}

.slide {
  background-color: rgba(75, 75, 75, 0);
  border: none;
  .row {
    .col-12 {
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.109);
    }
    .col-12:first-child {
      .card {
        border: 0px;
        .card-header {
          background-color: #0052b4;
          color: #fff;
        }

        .card-body {
          background-color: #0052b4;
          color: #fff;
        }
      }
    }

    .col-12:last-child {
      .card {
        border: 0px;
        .card-header {
          background-color: #aacffe;
          color: #003d87;
        }
        .card-body {
          background-color: #aacffe;
          color: #003d87;
        }
      }
    }
  }
}
</style>