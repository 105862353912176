<template>
  <div class="globe-section">
    <div class="row px-0 mx-0" style="position: relative">
      <div
        class="col-12"
        style="position: absolute; z-index: 10; margin-top: 100px"
      >
        <div class="row">
          <div
            class="col-2 pointer col-md-4 d-flex justify-content-center"
            @click="prev()"
          >
            <div class="span">
              <vs-button icon color="#4AC9E3">
                <span class="material-icons-round pointer white">
                  arrow_back_ios
                </span>
              </vs-button>
            </div>
          </div>
          <div class="col-8 col-md-4 d-flex justify-content-center">
            <span v-for="(data, index) in returnItems" :key="index + 'data'">
              <span v-if="index === slide">
                <h2 class="amount-data text-center c-primary openSansBold">
                  <span v-if="data.amount !== undefined">
                    {{ returnTestNum }}
                  </span>
                </h2>
                <h3 class="openSansBold c-primary text-center">
                  {{ data.title }}
                </h3>
                <h5 class="text-center black openSansRegular subtitle">
                  {{ data.desc }}
                </h5>

                <button
                  class="btn btn-block mt-3 openSansBold customized-btn"
                  v-for="(button, index) in data.buttons"
                  :key="'button' + index"
                  :class="[
                    button.style === 'btn-primary'
                      ? 'btn-primary'
                      : 'btn-outline-primary',
                  ]"
                  @click="openContactDialog(button.link)"
                >
                  {{ button.text }}
                </button>
              </span>
            </span>
          </div>
          <div
            class="col-2 pointer col-md-4 d-flex justify-content-center"
            @click="next()"
          >
            <span>
              <vs-button icon color="#4AC9E3">
                <span class="material-icons-round pointer white">
                  arrow_forward_ios
                </span>
              </vs-button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 clouds-container d-flex justify-content-center">
        <img :src="clouds" alt="" class="clouds" style="" />
      </div>
      <div class="col-12 d-flex justify-content-center px-0 mx-0">
        <div class="globe-container d-flex justify-content-center">
          <div class="globe-holder" :class="{ animation: !isDragging }">
            <img
              id="globe"
              :src="globe"
              alt=""
              class="globe"
              :style="'transform: rotate(' + rotation + 'deg)'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable);

// import globe_trial from "@/assets/sample/globe_trial.svg";
import globe_img from "@/assets/others/globe.png";
import clouds_img from "@/assets/sample/clouds.svg";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
export default {
  props: {
    items: Array,
    current_language: String,
  },
  data() {
    return {
      globe: globe_img,
      clouds: clouds_img,
      rotation: 0,
      slide: 0,
      copied_items: [],
      company_count: `company/count`,
      litigation_count: `litigation/count`,

      dynamic_counter: 0,
      isDragging: false,
    };
  },
  methods: {
    getData() {
      console.log("SITE CONFIG GLOBE: ", this.returnSiteConfig);
      this.copied_items = this.items;
      load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              console.log("COMPANY TOKEN", token);
              console.log(
                "COMPANY COUNTS: ",
                this.siteConfig.e_p + this.company_count
              );
              axios
                .get(this.siteConfig.e_p + this.company_count, {
                  params: {
                    init_token: token,
                  },
                })
                .then((res) => {
                  this.updateObject(
                    "Indonesia",
                    res.data.count,
                    res.data.inc_last_week
                  );
                  console.log("test comp", res);
                  this.initiateScrollTrigger(res.data.count);
                })
                .catch((error) => {
                  console.log("INDONESIA ERROR: ", error);
                  // throw error;
                });
            })
            .catch((error) => {
              throw error;
            });
        }
      );

      load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              console.log("COMPANY TOKEN", token);
              axios
                .get(this.returnSiteConfig.e_p + this.litigation_count, {
                  params: {
                    init_token: token,
                  },
                })
                .then((res) => {
                  this.updateObject(
                    "Litigation",
                    res.data.count,
                    res.data.inc_last_week
                  );
                })
                .catch((error) => {
                  throw error;
                });
            })
            .catch((error) => {
              throw error;
            });
        }
      );
    },
    updateObject(id, count, per_week) {
      let filtered_object = this.copied_items.filter((x) => {
        return x.id === id;
      })[0];
      filtered_object = Object.assign(filtered_object, {
        amount: count,
        amount_per_week: per_week,
      });
    },
    initiateScrollTrigger(count) {
      console.log("scroll trigger kicked");
      let self = this;
      var startCount = 0;
      let num = { var: startCount };
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".globe-section",
            duration: 5,
            scrollTrigger: ".amount-data",
            start: "top center",
            end: "+=0",
            scrub: true,
            // markers: true,
          },
        })
        .to(num, {
          var: count,
          duration: 1,
          ease: "none",
          onUpdate: changeNumber,
        });

      function changeNumber() {
        if (num.var > 0 && num.var >= count) {
          self.animateValue(0, count, 500);
        }
      }
    },

    animateValue(start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        this.dynamic_counter = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },

    returnTranformedAmount(count) {
      return numeral(count).format("0,0");
    },

    prev() {
      if (this.slide === 0) {
        this.rotation -= 90;
        this.slide = this.copied_items.length - 1;
      } else {
        this.rotation -= 90;
        this.slide -= 1;
      }
    },

    next() {
      if (this.slide < this.copied_items.length - 1) {
        this.rotation += 90;
        this.slide += 1;
      } else {
        this.rotation += 90;
        this.slide = 0;
      }

      console.log("current slide:", this.slide);
      console.log("length: ", this.copied_items.length - 1);
    },

    openContactDialog(link) {
      var x = {
        state: true,
        tab: link,
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
  },
  computed: {
    ...mapGetters(["siteConfig"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnItems() {
      return this.copied_items;
    },
    returnTestNum() {
      return numeral(this.dynamic_counter).format("0,0");
    },
  },
  watch: {
    slide(val) {
      console.log("sliders changed: ", this.copied_items[val].amount);
      if (this.copied_items[val].amount !== undefined) {
        this.initiateScrollTrigger(this.copied_items[val].amount);
      }
    },
    current_language() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.globe-section {
  background-color: #4ac9e3;
  height: 100vh;
  // background-color: #fff;
}

.subtitle {
  font-size: 1.2vw !important;
}

@media only screen and (max-width: 600px) {
  .subtitle {
    font-size: 4vw !important;
  }
}
@media only screen and (min-width: 600px) {
  .subtitle {
    font-size: 4vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .subtitle {
    font-size: 2vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .subtitle {
    font-size: 2vw !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .subtitle {
    font-size: 1.3vw !important;
  }
}

.customized-btn {
  background-color: #4ac9e300 !important;
  color: #0052b4 !important;
}

.customized-btn:hover {
  color: #fff !important;
}
.clouds-container {
  max-height: 100vh;
  overflow: hidden;
  position: absolute;
  margin-top: 50vh;
  max-height: 100vh;
  left: 0;
  padding-top: 100px;
  .clouds {
    animation: left 10s ease-in-out infinite;
    height: 700px;
  }

  @keyframes left {
    0% {
      transform: translatex(0px);
      opacity: 0;
    }

    25% {
      transform: translatex(-10px);
      opacity: 1;
    }
    50% {
      transform: translatex(-20px);
      opacity: 0;
    }

    75% {
      transform: translatex(20px);
      opacity: 1;
    }
    100% {
      transform: translatex(0px);
      opacity: 0;
    }
  }
}

.globe-container {
  max-height: 100vh;
  overflow: hidden;

  .globe-holder {
    transform: translatey(0px);

    .globe {
      height: 170vh;
      margin-top: 45vh;
      transition: transform 0.8s ease-in-out;
    }
  }

  .animation {
    animation: float 6s ease-in-out infinite;
    -webkit-transition: -webkit-transform 0.8s ease-in-out;
    -ms-transition: -ms-transform 0.8s ease-in-out;
    -webkit-transition: -webkit-transform 0.8s ease-in-out;
    -ms-transition: -ms-transform 0.8s ease-in-out;
    transition: transform 0.8s ease-in-out;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
}
</style>
