export default {
  namespaced: true,
  state: {
    //dialog
    open_dialog: false,
    dialog_input: "",
    // contents:
    english_content: {},
    indonesian_content: {},
  },

  getters: {
    open_dialog: (s) => s.open_dialog,
    dialog_input: (s) => s.dialog_input,
    english_content: (s) => s.english_content,
    indonesian_content: (s) => s.indonesian_content,
  },

  mutations: {
    SET_DIALOG_STATE(s, y) {
      console.log("hit store", y, s);
      s.open_dialog = y;
    },
  },
  actions: {
    CHANGE_DIALOG_STATE({ commit }, data) {
      commit("SET_DIALOG_STATE", data);
    },
  },
};
