<template>
  <div
    class="search-box-container px-5 col-12"
    :class="`${returnDialogState ? 'focused' : ''}`"
  >
    <div
      class="search-input-container"
      v-on-clickaway="focusAway"
      :class="companyData.length > 0 ? 'top' : ''"
      v-if="returnDialogState"
    >
      <!-- note search box -->
      <div class="col-12 px-0">
        <b-form-input
          size="lg"
          :placeholder="
            returnLanguage == 'EN' ? 'Search Companies' : 'Cari Perusahaan'
          "
          id="searchinput"
          style="padding-right: 40px"
          v-model="searched"
          @keydown.space="beginCountDown()"
          @keydown.enter="beginCountDown()"
          aria-autocomplete="off"
          autocomplete="off"
          ref="search_input"
        ></b-form-input>
      </div>
      <span
        class="material-icons d-flex justify-content-end align-items-center c-primary"
        style="
          position: absolute;
          cursor: pointer;
          z-index: 1;
          top: 0;
          right: 0;
          transform: translateY(50%) translateX(-50%);
        "
      >
        search
      </span>
      <!-- note search results -->
      <div
        class="search-result-container"
        v-if="returnDialogState"
        ref="target"
        id="target"
        :class="companyData.length == 0 ? 'empty' : 'filled'"
      >
        <div class="result">
          <div class="tip">
            <span v-if="returnLanguage == 'EN'">
              💡To enhance the precision of your search, consider utilizing
              multi-word queries.
            </span>
            <span v-else>
              💡Untuk meningkatkan ketepatan pencarian Anda, pertimbangkan untuk
              menggunakan kueri multi-kata.
            </span>
          </div>
          <div v-if="companyData.length > 0">
            <div
              v-for="(comp, compidx) in companyData"
              :key="compidx"
              class="term_data row mx-0 px-0"
            >
              <div
                class="col-12 col-md-6 d-flex align-items-center"
                @click="goToRoute(comp)"
              >
                <div class="row">
                  <div class="col-12 name">
                    {{ comp.company_name }}
                  </div>
                  <div class="col-12 address">
                    {{ comp.main_address }}
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-6 d-flex align-items-center justify-content-md-end"
              >
                <vs-button
                  class="poppinsSemiBold"
                  gradient
                  @click="goToRoute(comp)"
                >
                  <span
                    class="material-icons d-flex justify-content-end align-items-center"
                    style="font-size: 15px; margin-right: 5px"
                  >
                    location_city
                  </span>
                  <span v-if="returnLanguage == 'EN'"> View Detail </span>
                  <span v-else> Lihat Detail </span>
                </vs-button>
              </div>
            </div>
          </div>
          <div ref="loading_content"></div>
        </div>

        <div class="row px-0 mx-0" v-if="companyData.length > 0">
          <div class="col-6 d-flex align-items-center">
            <span class="poppinsBold mr-1">
              {{ company_data.length }}
            </span>
            <span v-if="returnLanguage == 'EN'"> Results found</span>
            <span v-else> Hasil ditemukan </span>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <vs-button
              @click="curr_page = 1"
              :dark="curr_page !== 1"
              :flat="curr_page !== 1"
              :primary="curr_page == 1"
            >
              1
            </vs-button>
            <vs-button
              @click="curr_page = 2"
              :dark="curr_page !== 2"
              :flat="curr_page !== 2"
              :primary="curr_page == 2"
              v-if="company_data.length > 10"
            >
              2
            </vs-button>
          </div>
        </div>

        <span
          v-if="company_data.length > 10"
          @click="changePage()"
          class="material-icons d-flex justify-content-center align-items-center pagin-icon left"
        >
          keyboard_arrow_left
        </span>
        <span
          v-if="company_data.length > 10"
          @click="changePage()"
          class="material-icons d-flex justify-content-center align-items-center pagin-icon right"
        >
          keyboard_arrow_right
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import axios from "axios";
import { load } from "recaptcha-v3";
import { createNamespacedHelpers } from "vuex";
import { mapGetters } from "vuex";

export default {
  mixins: [clickaway],
  props: {
    placeholder: String,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      searched: "",
      count_down: 99,
      countDownInterval: null,
      company_data: [],
      // axios search
      query_url: "company/search?q=",
      current_pagination: 0,
      curr_page: 1,
      loading_target: null,
      is_searching: false,
    };
  },
  methods: {
    updateDialogState(state) {
      this.$store.dispatch("cs/CHANGE_DIALOG_STATE", state);
    },
    beginCountDown() {
      this.count_down = 1;
      if (this.countDownInterval !== null) {
        clearInterval(this.countDownInterval);
      }
      this.countDownInterval = setInterval(() => {
        this.count_down--;
        if (this.count_down <= 0) {
          clearInterval(this.countDownInterval);
        }
      }, 500);
    },
    goToRoute(comp) {
      console.log(
        "comp_name",
        encodeURIComponent(comp.company_name.replace(/\s/g, "-"))
      );

      let encoded_comp_name = encodeURIComponent(
        comp.company_name.replace(/\s/g, "-")
      );
      let q = comp.source;
      let z = comp.source == "02a" ? comp.iinumber : comp._id;
      this.$router
        .push({
          path: `/company?c=${encoded_comp_name}&q=${q}&z=${z}`,
        })
        .then((res) => {
          if (res.name == this.$route.name) {
            console.log("push results is same: ", res);
            location.reload();
          }
        })
        .catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // But print any other errors to the console
            logError(err);
          } else if (err.name == "NavitationDuplicated") {
            setTimeout(() => {
              location.reload();
            }, 100);
          }
        });
      this.updateDialogState(false);
    },
    focusAway() {
      this.updateDialogState(false);
    },
    async searchData() {
      let self = this;

      let requested_query = encodeURIComponent(this.searched);
      try {
        if (!this.is_searching) {
          this.is_searching = true;
          this.loading_target = this.$vs.loading({
            target: this.$refs.loading_content,
          });

          console.log(
            "query params:",
            this.searched,
            self.returnSiteConfig.e_p +
              self.query_url +
              requested_query +
              "&start=" +
              self.current_pagination +
              ""
          );

          load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
            (recaptcha) => {
              recaptcha.execute("get").then((token) => {
                console.log("recaptchakeytoken: ", token);
                axios
                  .get(
                    self.returnSiteConfig.e_p +
                      self.query_url +
                      requested_query +
                      "&start=" +
                      self.current_pagination +
                      "",
                    {
                      params: {
                        init_token: token,
                      },
                    }
                  )
                  .then((data) => {
                    if (data.data && data.data.data) {
                      let init_data = data.data.data;
                      let root_data = data.data;
                      let data_type = Object.prototype.toString
                        .call(init_data)
                        .slice(8, -1);

                      if (data_type == "Object") {
                        self.company_data = [init_data];
                      } else if (data_type == "Array") {
                        self.company_data = init_data;
                      } else {
                        self.company_data = [];
                      }
                      console.log("init_data: ", init_data, data_type);
                      console.log("root_data: ", root_data);
                    }

                    if (this.loading_target !== null) {
                      this.loading_target.close();
                    }

                    this.is_searching = false;
                  })
                  .catch((error) => {
                    console.error("data_error", error);

                    if (this.loading_target !== null) {
                      this.loading_target.close();
                    }
                    this.is_searching = false;
                    this.company_data = [];
                  });
              });
            }
          );
        }
      } catch (error) {
        this.is_searching = false;
        this.company_data = [];
      }
    },
    changePage() {
      this.curr_page == 2 ? (this.curr_page = 1) : (this.curr_page = 2);
    },
  },
  computed: {
    ...mapGetters(["siteConfig", "cs/open_dialog", "language"]),

    returnDialogState() {
      return this["cs/open_dialog"];
    },
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnScreen() {
      return this.$screen;
    },
    returnLanguage() {
      return this.language;
    },
    isSmallScreen() {
      if (this.returnScreen.width < 900) {
        return "100%";
      } else {
        return "60%";
      }
    },
    companyData() {
      let cp = JSON.parse(JSON.stringify(this.company_data));
      return this.curr_page == 1 ? cp.splice(0, 10) : cp.splice(10, cp.length);
    },
  },
  mounted() {},
  watch: {
    async count_down(newVal, oldVal) {
      if (oldVal > 0 && newVal == 0) {
        await this.searchData();
      }
    },

    returnDialogState(newVal, oldVal) {
      if (newVal) {
        setTimeout(() => {
          let input_el = this.$refs.search_input.$el;
          input_el.focus();
          console.log("returnDialogStateTrueFocused", newVal, input_el);
        }, 200);
      }

      if (!newVal) {
        this.company_data = [];
        this.searched = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-box-container {
  .search-input-container {
    position: relative;
    .search-result-container {
      position: absolute;
      width: 100%;
      top: 50px;
      max-height: 90vh;
      z-index: 99;
      box-shadow: 0px 0px 150px rgba(43, 43, 43, 0.298);
      border-radius: 10px;
      padding: 10px;

      .result {
        max-height: 80vh;
        overflow-y: auto;
        .tip {
          background: #fef3c7;
          border: 1px solid #fdc76a;
          padding: 5px;
          border-radius: 5px;
          color: #212327;
        }
        .term_data {
          padding: 5px 0px;
          border-bottom: 1px solid #acacace0;
          cursor: pointer;
          animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          animation-duration: 500ms;
          .name {
            color: var(--c-primary);
            font-size: 20px;
          }

          .address {
          }

          &:hover {
            .name {
              color: #0049a1;
              font-weight: 800;
            }
            .address {
              color: #0049a1;
            }
          }
        }
      }

      &.empty {
        background: rgba(255, 255, 255, 0.038);
      }

      &.filled {
        border: 1px solid #dcdcdc;
        background: white;
      }
    }
  }

  &.focused {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #000000c3;
    z-index: 99999;
    width: 100%;
    .search-input-container {
      top: 50%;

      &.top {
        top: 2% !important;
      }
    }

    .pagin-icon {
      color: #fff;
      position: absolute;
      top: 45%;
      font-size: 2em;
      background: #f0f0f045;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      cursor: pointer;
      user-select: none;
      &.right {
        right: -45px;
      }

      &.left {
        left: -45px;
      }
      &:hover {
        background: #f0f0f0;
        color: var(--c-primary);
      }
    }
  }
}

img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

input {
  border-radius: 20px;
}
input#searchinput {
  background-color: rgba(255, 255, 255, 0.927) !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.075) inset, 0 0 1px rgb(170, 170, 170) !important;
  border: none !important;
  color: #212327;
}

input#searchinput:focus {
  background-color: rgb(255, 255, 255) !important;
}

input#searchinput::placeholder {
  color: rgb(88, 88, 88) !important;
}

.form-select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: var(--lightblue) !important;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.075) inset, 0 0 0px black !important;
  outline: 0 none !important;
  background-color: var(--white) !important;
}

input,
textarea {
  background-color: var(--lightgray) !important;
  border-radius: 10px !important;
}

button {
  border-radius: 10px !important;
}

.btn:focus {
  border-color: var(--pastelblue) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 1px var(--pastelblue) !important;
  outline: 0 none !important;
}
</style>
