<template>
  <div class="container-fluid d-flex justify-content-center">
    <div class="container mx-0 px-0 px-md-5 pb-5" style="padding-top: 10px">
      <div class="row mx-0 px-1 px-md-5">
        <!-- note: TITLE -->

        <div class="col-12 py-3">
          <div class="row company-header-container">
            <div
              class="col-12 py-3 d-flex align-items-center justify-content-md-end px-0"
            >
              <vs-input
                placeholder="Search Companies"
                v-model="search_company_input"
                style="width: 100%"
                primary
                icon-after
                @click="openSearchDialog()"
              >
                <template #icon>
                  <span class="material-icons">search</span>
                </template>
              </vs-input>
            </div>
            <div class="col-12 py-3">
              <Skeleton v-if="!company_name" :count="1" height="50px" />

              <h1 class="company-name openSansSemiBold" v-else>
                {{ company_name }}
              </h1>
            </div>
          </div>
        </div>
        <!-- note tabs -->
        <div class="col-12">
          <div class="row" v-if="$screen.md">
            <div
              class="col-12 col-md-12"
              style="border-bottom: 1px solid #00000041"
            >
              <div class="row tab-container">
                <div
                  v-for="(tab, tabidx) in tabs"
                  :key="tabidx"
                  class="tab col-auto"
                  :class="
                    curr_tab == tab.id
                      ? 'active openSansSemiBold'
                      : 'openSansMedium'
                  "
                  @click="scrollToSection(tab.element_id)"
                >
                  <div v-if="isTabRenderable(tab.id)">
                    {{ tab[returnLanguage] }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col-12 px-3" style="border-bottom: 1px solid #00000041">
              <h5 class="openSansSemiBold">Company information</h5>
            </div>
          </div>
        </div>
        <!-- note company information -->
        <div class="col-12">
          <div class="row">
            <div class="col-12 company-information-container py-3">
              <div
                class="row py-3"
                v-for="(cd, cd_name, cd_idx) in company_data"
                :key="cd_idx"
                :class="
                  cd_idx == Object.keys(company_data).length - 1 ? '' : 'item'
                "
              >
                <div class="col-12 col-md-3 openSansBold">
                  {{ returnLanguage == "EN" ? cd.EN : cd.ID }}
                </div>
                <div class="col-12 col-md-9">
                  <Skeleton :count="1" height="25px" v-if="!cd.should_render" />
                  <div v-else>
                    <div v-if="cd.data.length > 0 && cd.data !== 'N/A'">
                      {{ cd.data }}
                    </div>
                    <span class="material-icons c-primary" v-else> lock </span>
                  </div>
                </div>
              </div>
              <div
                class="lock-path d-flex align-items-end justify-content-center"
              >
                <div class="">
                  <h5 class="openSansBold text-center">
                    <span v-if="returnLanguage == 'EN'">
                      Sign up for free or log in to get the complete profile of
                      this company
                    </span>
                    <span v-else>
                      Daftar Gratis atau Masuk untuk mendapatkan Profil Lengkap
                      Perusahaan ini
                    </span>
                  </h5>
                  <div class="d-flex justify-content-center">
                    <vs-button @click="openDialog('Register')">
                      <h5>
                        <span v-if="returnLanguage == 'EN'">
                          Register Now
                        </span>
                        <span v-else> Daftar Sekarang </span>
                      </h5>
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- note: Get Report -->
        <div
          class="col-12 pricing-container"
          v-if="company_data.registered_name.data.length > 0"
        >
          <div class="row">
            <div class="col-12 pricing-header-container">
              <h3 class="openSansBold" v-if="returnLanguage == 'EN'">
                Get Your Insights
              </h3>

              <h3 class="openSansBold" v-else>Dapatkan Wawasan Perusahaan</h3>
            </div>

            <div class="col-12 py-3">
              <h5 v-if="returnLanguage == 'EN'">
                The comprehensive report of
                {{
                  company_data.registered_name.data !== "N/A"
                    ? company_data.registered_name.data
                    : route_company_name
                }}
                provides tailored business insights, covering company
                identification, risk information, pertinent events, management,
                business structure, activities, and press updates.
              </h5>
              <h5 v-else>
                Laporan lengkap perusahaan
                {{
                  company_data.registered_name.data !== "N/A"
                    ? company_data.registered_name.data
                    : route_company_name
                }}
                memberikan sesuai kebutuhan bisnis dengan identifikasi
                perusahaan, informasi risiko, peristiwa yang relevan, manajemen,
                struktur bisnis, aktivitas, dan informasi pers.
              </h5>
            </div>

            <div class="col-12">
              <div
                class="d-flex align-items-center openSansSemiBold py-1"
                v-for="(pcb, pcbidx) in pricing_content.benefits[
                  returnLanguage
                ]"
                :key="pcbidx"
              >
                <span class="material-icons mr-2 c-primary"> verified </span>
                <span>
                  {{ pcb }}
                </span>
              </div>
            </div>
            <!-- NOTE: PRODUCTS -->
            <div
              class="col-12 px-4 py-3"
              v-for="(pcd, pcdidx) in pricing_content.products"
              :key="pcdidx"
            >
              <div class="row pricing-card-container p-3">
                <div class="col-12 title-container openSansBold">
                  <h5>{{ pcd.title[returnLanguage] }}</h5>
                </div>
                <div class="col-12 col-md-8 py-3">
                  <h5 class="c-primary">{{ pcd.question[returnLanguage] }}</h5>
                  <ul class="px-3">
                    <li
                      v-for="(pcd_b, pcdb_idx) in pcd.benefits[returnLanguage]"
                      :key="pcdb_idx"
                    >
                      {{ pcd_b }}
                    </li>
                  </ul>
                </div>
                <div
                  class="col-12 col-md-4 py-3 d-flex align-items-center justify-content-center"
                >
                  <div style="width: 100%">
                    <button
                      class="btn btn-block btn-outline-primary customized-btn"
                      @click="viewPDF(pcd.download_btn.download_link)"
                    >
                      {{ pcd.download_btn[returnLanguage] }}
                    </button>
                    <button
                      class="btn btn-block mr-3 btn-primary"
                      @click="openDialog('Contact Sales')"
                    >
                      {{ pcd.contact_sales_btn[returnLanguage] }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- note: Other Related Company -->

        <div
          class="col-12"
          v-if="similar_industries.length > 0"
          id="related_companies"
        >
          <div class="row">
            <div class="col-12 mt-5 section-title">
              <h5 class="openSansSemiBold">
                <span v-if="returnLanguage == 'EN'">
                  Find Similar Companies
                </span>
                <span v-else> Cari Perusahaan Serupa </span>
              </h5>
            </div>
            <div
              class="related-companies-container mt-4 p-3"
              style="width: 100%"
            >
              <vs-table striped>
                <template #thead>
                  <vs-tr>
                    <vs-th>
                      <span v-if="returnLanguage == 'EN'">
                        Company &amp; LOB</span
                      >
                      <span v-else> Perusahaan &amp; LOB </span>
                    </vs-th>
                    <vs-th>
                      <span v-if="returnLanguage == 'EN'"> Address </span>
                      <span v-else> Alamat </span>
                    </vs-th>
                    <vs-th>iiNumber</vs-th>
                    <vs-th>Action</vs-th>
                  </vs-tr>
                </template>
                <template #tbody>
                  <vs-tr
                    v-for="(ri, ridx) in $vs.getPage(
                      similar_industries,
                      si_tble_settings.page,
                      si_tble_settings.max
                    )"
                    :key="ridx"
                    :data="ri"
                  >
                    <vs-td
                      class="table-item-header"
                      style="width: 150px !important"
                      @click="goToRoute(ri)"
                    >
                      <div class="openSansMedium name">
                        {{ ri.name }}
                      </div>
                      <div style="lob">
                        {{ ri.lob }}
                      </div>
                    </vs-td>
                    <vs-td>
                      {{ ri.address }}
                    </vs-td>
                    <vs-td>
                      {{ ri.iinumber }}
                    </vs-td>
                    <vs-td>
                      <vs-button
                        v-if="!isCurrentComp(ri)"
                        primary
                        @click="goToRoute(ri)"
                        style="width: 150px"
                      >
                        <span v-if="returnLanguage == 'EN'"> View Report </span>
                        <span v-else> Lihat Laporan </span>
                      </vs-button>
                      <div v-else>
                        <vs-button style="width: 150px" flat disabled>
                          <span v-if="returnLanguage == 'EN'">
                            This Report
                          </span>
                          <span v-else> Laporan Ini </span>
                        </vs-button>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
                <template #footer>
                  <vs-pagination
                    v-model="si_tble_settings.page"
                    :length="
                      $vs.getLength(similar_industries, si_tble_settings.max)
                    "
                  />
                </template>
              </vs-table>
            </div>
          </div>
        </div>

        <!-- note: News -->
        <div class="col-12" v-if="company_news.length > 0">
          <div class="row">
            <div class="col-12 mt-5 section-title">
              <div id="news">
                <h5 class="openSansSemiBold">
                  <span v-if="returnLanguage == 'EN'"> News related to </span>
                  <span v-else> Berita tentang </span>
                  {{ company_data.registered_name.data }}
                  <i class="text-danger">
                    <span v-if="returnLanguage == 'EN'"> (News) </span>
                    <span v-else> (Berita) </span>
                  </i>
                </h5>
              </div>
            </div>
            <div class="col-12 mt-4 p-3">
              <div class="row">
                <div
                  v-for="(cn, cnidx) in company_news"
                  :key="cnidx"
                  class="col-12 col-md-6"
                >
                  <div class="news-card p-2" @click="openNewsDialog(cn)">
                    <div class="news-title">
                      {{ returnLanguage == "EN" ? cn.title_ENG : cn.title }}
                    </div>
                    <div class="news-source">
                      {{ cn.news_source }}
                    </div>
                    <div class="news-date">
                      {{ returnDateFormatted(cn.news_date) }}
                    </div>
                    <div class="row">
                      <div class="col-1">
                        <vs-tooltip
                          :primary="
                            cn.news_tone != 'Positive' &&
                            cn.news_tone != 'Negative'
                          "
                          :danger="cn.news_tone == 'Negative'"
                          :success="cn.news_tone == 'Positive'"
                        >
                          <div>
                            <div class="news-tone">
                              <div
                                v-if="cn.news_tone == 'Positive'"
                                class="positive"
                              >
                                <span class="material-icons-outlined icon">
                                  keyboard_arrow_up
                                </span>
                              </div>
                              <div
                                v-if="cn.news_tone == 'Negative'"
                                class="negative"
                              >
                                <span class="material-icons-outlined icon">
                                  keyboard_arrow_down
                                </span>
                              </div>
                              <div
                                v-if="
                                  cn.news_tone != 'Negative' &&
                                  cn.news_tone != 'Positive'
                                "
                                class="neutral"
                              >
                                <span class="material-icons-outlined icon">
                                  horizontal_rule
                                </span>
                              </div>
                            </div>
                          </div>

                          <template #tooltip>
                            <span v-if="returnLanguage == 'EN'">
                              News Tone:
                            </span>
                            <span v-else> Sentimen Berita: </span>
                            {{ cn.news_tone }}
                          </template>
                        </vs-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-dialog v-model="show_news_dialog">
      <div
        class="row"
        :style="
          $screen.md ? ' overflow-y: auto' : 'height: 70vh; overflow-y: auto'
        "
      >
        <div class="col-12 openSansBold">
          <h1>
            {{
              returnLanguage == "EN" ? viewed_news.title_ENG : viewed_news.title
            }}
          </h1>
        </div>

        <div class="col-12">
          <div class="row news-property-container">
            <div class="col-12 col-md-8 d-flex">
              <div class="row init-container">
                <div class="col-6 col-md-4">
                  <div class="news-source">
                    {{ viewed_news.news_source }}
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="news-date">
                    {{ returnDateFormatted(viewed_news.news_date) }}
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <vs-tooltip
                    :primary="
                      viewed_news.news_tone != 'Positive' &&
                      viewed_news.news_tone != 'Negative'
                    "
                    :danger="viewed_news.news_tone == 'Negative'"
                    :success="viewed_news.news_tone == 'Positive'"
                  >
                    <div class="d-flex align-items-center">
                      <div class="news-tone d-flex align-items-center">
                        <div
                          v-if="viewed_news.news_tone == 'Positive'"
                          class="positive"
                        >
                          <span class="material-icons-outlined icon">
                            keyboard_arrow_up
                          </span>
                        </div>
                        <div
                          v-if="viewed_news.news_tone == 'Negative'"
                          class="negative"
                        >
                          <span class="material-icons-outlined icon">
                            keyboard_arrow_down
                          </span>
                        </div>
                        <div
                          v-if="
                            viewed_news.news_tone != 'Negative' &&
                            viewed_news.news_tone != 'Positive'
                          "
                          class="neutral"
                        >
                          <span class="material-icons-outlined icon">
                            horizontal_rule
                          </span>
                        </div>
                        <div
                          class="ml-1"
                          :class="
                            viewed_news.news_tone == 'Negative' ||
                            viewed_news.news_tone == 'Positive'
                              ? `${viewed_news.news_tone}-text`
                              : 'Neutral-text'
                          "
                        >
                          {{ viewed_news.news_tone }}
                        </div>
                      </div>
                    </div>

                    <template #tooltip>
                      News Tone: {{ viewed_news.news_tone }}
                    </template>
                  </vs-tooltip>
                </div>
              </div>
            </div>
            <div class="col-4"></div>
          </div>
        </div>

        <div class="col-12 pt-2">
          {{
            returnLanguage == "EN"
              ? viewed_news.news_content_ENG
              : viewed_news.news_content
          }}
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import sample_news from "@/assets/sample/sample_news_photo.jpg";
import CompanySearch from "@/components/utils/CompanySearch.vue";
import axios from "axios";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";
import moment from "moment";
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import "vue-loading-skeleton/dist/style.css";
export default {
  components: {
    CompanySearch,
    Skeleton,
    SkeletonTheme,
  },
  data() {
    return {
      curr_tab: "CI",
      sample_news_photo: sample_news,
      tabs: [
        {
          id: "CI",
          label: "Company Information",
          EN: "Company Information",
          ID: "Informasi Perusahaan",
          showIn: "sm",
          element_id: "",
        },
        {
          id: "OR",
          label: "Other results",
          EN: "Other Results",
          ID: "Hasil Lain",
          element_id: "related_companies",
          clickAction: function () {},
          showIn: "md",
        },
        {
          id: "I",
          label: "Insight",
          EN: "Insights",
          ID: "Informasi Mendalam",
          element_id: "news",
          showIn: "md",
        },
      ],
      company_name: "",
      old_comp_data: {},
      similar_industries: [],
      si_tble_settings: {
        page: 1,
        max: 10,
      },
      company_news: [],
      loading: null,
      search_company_input: "",
      show_news_dialog: false,
      viewed_news: {},
      company_data: {
        registered_name: {
          EN: "Registered Name",
          ID: "Nama Terdaftar",
          data: "",
        },
        legal_entity_type: {
          EN: "Legal Entity Type",
          ID: "Jenis Entitas Hukum",
          data: "",
        },
        iinumber: {
          EN: "IINumber",
          ID: "IINumber",
          data: "",
        },
        registered_address: {
          EN: "Registered Address",
          ID: "Alamat Terdaftar",
          data: "",
        },
        city: {
          EN: "City",
          ID: "Kota",
          data: "",
        },
        phone_number: {
          EN: "Phone Number",
          ID: "Nomor Telepon",
          data: "",
        },
        ceo: {
          EN: "C.E.O.",
          ID: "C.E.O.",
          data: "",
        },
        revenues: {
          EN: "Revenues",
          ID: "Pendapatan",
          data: "",
        },
      },
      pricing_content: {
        header: {
          EN: "",
          ID: "",
        },
        benefits: {
          EN: [
            "Reports are in English",
            "Reports are available for delivery within 1 working day.",
          ],
          ID: [
            "Menggunakan Bahasa Inggris",
            "Tersedia pengiriman dalam 1 hari kerja",
          ],
        },
        products: [
          {
            title: {
              EN: "Business Monitoring Alert",
              ID: "Business Monitoring Alert",
            },
            question: {
              EN: "What is included in the report?",
              ID: "Apa yang termasuk dalam laporan?",
            },
            benefits: {
              EN: [
                "Business Information",
                "Company Litigation Review",
                "Risk Indicators",
                "Comprehensive report containing the entire history of cases that have occurred",
                "List of Status Determinations",
              ],
              ID: [
                "Informasi Bisnis",
                "Pemeriksaan Litigasi Perusahaan",
                "Indikator Risiko",
                "Laporan lengkap yang memuat seluruh riwayat kasus yang terjadi",
                "Daftar Penetapan Status",
              ],
            },
            download_btn: {
              EN: "Download Report",
              ID: "Unduh Laporan",
              download_link: "/documents/pdf/sample_bma_report.pdf",
            },
            contact_sales_btn: {
              EN: "Contact Sales",
              ID: "Hubungi Sales",
            },
          },
          {
            title: {
              EN: "Indoinfo Registry Of Company",
              ID: "Indoinfo Registry Of Company",
            },
            question: {
              EN: "What is included in the report?",
              ID: "Apa yang termasuk dalam laporan?",
            },
            benefits: {
              EN: [
                "Legal company name and legal structure",
                "Contact details : registered address & telephone",
                "Line of business with SIC code",
                "Registration details: Legalization no issued by Indonesia Ministry of Justice",
                "Notary name, Deed No and date",
                "Capital details (Authorized, Issued & Paid-up Capital)",
                "Shareholders details (Shareholders Name, Country & Ownership Percentage)",
                "Management details (Name and position)",
                "Related companies (Parent and affiliates)",
              ],
              ID: [
                "Nama perusahaan dan struktur hukum",
                "Detail kontak: alamat terdaftar & telepon",
                "Bidang usaha dengan SIC",
                "Detail pendaftaran: Nomor legalisasi yang dikeluarkan oleh Kementerian Kehakiman Indonesia",
                "Nama notaris, Nomor Akta, dan tanggal",
                "Detail modal (Modal Dasar, Modal Ditempatkan & Modal Disetor)",
                "Detail pemegang saham (Nama Pemegang Saham, Negara & Persentase Kepemilikan)",
                "Detail manajemen (Nama dan posisi)",
                "Perusahaan terkait (Induk dan afiliasi)",
              ],
            },
            download_btn: {
              EN: "Download Report",
              ID: "Unduh Laporan",
              download_link: "/documents/pdf/sample_iiroc_report.pdf",
            },
            contact_sales_btn: {
              EN: "Contact Sales",
              ID: "Hubungi Sales",
            },
          },
        ],
      },
      route_company_name: "",
    };
  },

  methods: {
    fetchData() {
      this.loading = this.$vs.loading();
      const self = this;
      this.route_company_name = this.$route.query.c.replace(/-/g, " ");
      let c_q = this.$route.query.q;
      let c_z = this.$route.query.z;
      let url = `${self.returnSiteConfig.e_p}company/search-detail?src=${c_q}`;

      load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha.execute("get").then((token) => {
            console.log("recaptchakeytoken: ", token);
            axios
              .post(
                url,
                {
                  _id: c_z,
                },
                {
                  params: {
                    init_token: token,
                  },
                }
              )
              .then((data) => {
                if (this.loading !== null) {
                  this.loading.close();
                  this.loading = null;
                }
                console.log("fetchDataResults: ", data);

                setTimeout(() => {
                  let data_entry = data.data.data;

                  if (data_entry) {
                    this.company_name =
                      data_entry.registered_name !== "N/A"
                        ? data_entry.registered_name
                        : this.route_company_name;
                    this.old_comp_data = data_entry;

                    this.similar_industries = data_entry.similar_industries
                      ? data_entry.similar_industries
                      : [];

                    this.company_news = data_entry.news ? data_entry.news : [];

                    //SECTION: handle company data

                    let keys = Object.keys(this.company_data);
                    for (let key of keys) {
                      console.log("fetchData key loop", key);
                      if (data_entry[key]) {
                        this.company_data[key]["data"] = data_entry[key];
                      }
                      this.company_data[key]["should_render"] = true;
                    }
                  }
                }, 1000);
              })
              .catch((error) => {
                console.error("data_error", error);
                if (this.loading !== null) {
                  this.loading.close();
                  this.loading = null;
                }
              });
          });
        }
      );
    },
    scrollToSection(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    returnCompanyName() {
      return decodeURI(this.$route.query.c).replace(/-/g, " ");
    },
    openDialog(tab) {
      var x = {
        state: true,
        tab: tab,
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
    openSearchDialog() {
      this.$store.dispatch("cs/CHANGE_DIALOG_STATE", true);
    },
    returnDateFormatted(date) {
      return moment(date).format("MMMM DD, YYYY");
    },
    viewPDF(pdf_link) {
      window.open(pdf_link, "_blank", "fullscreen=yes");
    },
    openNewsDialog(news) {
      this.viewed_news = news;
      this.show_news_dialog = true;
    },
    goToRoute(comp) {
      let encoded_comp_name = encodeURIComponent(comp.name.replace(/\s/g, "-"));
      let q = this.$route.query.q;
      let z = comp.iinumber;
      let old_path = `${this.$route.path}?c=${this.$route.query.c}&q=${this.$route.query.q}&z=${this.$route.query.z}`;
      if (old_path !== `/company?c=${encoded_comp_name}&q=${q}&z=${z}`) {
        if (!this.loading) {
          this.loading = this.$vs.loading();
        }
        this.$router
          .push({
            path: `/company?c=${encoded_comp_name}&q=${q}&z=${z}`,
          })
          .then((res) => {
            if (res.name == this.$route.name) {
              console.log("push results is same: ", res);
              location.reload();
            }
          })
          .catch((err) => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // But print any other errors to the console
              logError(err);
            } else if (err.name == "NavitationDuplicated") {
              setTimeout(() => {
                location.reload();
              }, 100);
            }
          });
      }
    },
    isCurrentComp(comp) {
      let encoded_comp_name = encodeURIComponent(comp.name.replace(/\s/g, "-"));
      let q = this.$route.query.q;
      let z = comp.iinumber;
      let old_path = `${this.$route.path}?c=${this.$route.query.c}&q=${this.$route.query.q}&z=${this.$route.query.z}`;
      if (old_path === `/company?c=${encoded_comp_name}&q=${q}&z=${z}`) {
        return true;
      } else {
        return false;
      }
    },
    isTabRenderable(tab_id) {
      if (tab_id == "OR") {
        if (this.similar_industries.length > 0) {
          return true;
        } else {
          return false;
        }
      } else if (tab_id == "I") {
        if (this.company_news.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    scrolToSection(element_id) {
      if (element_id && element_id.length > 0) {
        const element = document.getElementById(element_id);
        // Calculate the offset you want to add (e.g., 50 pixels)
        var offset = 50;

        // Get the top position of the element
        var elementPosition = element.getBoundingClientRect().top;

        // Get the current scroll position
        var scrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;

        // Scroll to the element's view with an offset
        window.scrollTo({
          top: scrollPosition + elementPosition - offset,
          behavior: "smooth",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["siteConfig", "language"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnLanguage() {
      return this.language;
    },
    returnRoutePagePath() {
      return `${this.$route.path}?c=${this.$route.query.c}&q=${this.$route.query.q}&z=${this.$route.query.z}`;
    },
  },

  watch: {
    search_company_input(val) {
      if (val.length > 0) {
        this.openSearchDialog();

        setTimeout(() => {
          this.search_company_input = "";
        }, 100);
      }
    },
    returnRoutePagePath(newVal, oldVal) {
      console.log(
        "returnRoutePagePath watchers",
        newVal,
        oldVal,
        newVal !== oldVal
      );

      if (newVal !== oldVal) {
        location.reload();
      }
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.fetchData();
  },
};
</script>

<style lang="scss">
.company-header-container {
  .company-name {
    color: #407dc7;
  }

  border-bottom: 1px solid rgb(230, 230, 230);
  margin-top: 50px;
  margin-bottom: 50px;
}

.tab-container {
  padding: 0px 10px;
  .tab {
    color: #00000043;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &.active {
      color: #1f56a6;
      background: #f2f2f2;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 1px solid #1f56a6;
    }
    &:hover {
      color: #1f56a6;
      background: #f2f2f2;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 1px solid #1f56a6;
    }
  }

  &.droppable {
    position: relative;
    padding: 0px !important;

    .current-tab {
      padding: 10px;
      border: 1px solid rgb(210, 210, 210);
      border-radius: 9px;
    }

    .drop-container {
    }
  }
}

.section-title {
  border-bottom: 1px solid #00000041;
}

.company-information-container {
  position: relative;
  .item {
    border-bottom: 1px solid #00000041;
  }

  .lock-path {
    position: absolute;
    bottom: 20px;
    left: 0;
    height: 80%;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 0px 0px 10px 10px;
  }
}

.pricing-container {
  border: 1px solid #ececec;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 30px;
  .pricing-header-container {
    background: rgb(0, 89, 195);
    background: linear-gradient(
      45deg,
      rgba(0, 89, 195, 1) 0%,
      rgba(31, 86, 166, 1) 100%
    );
    padding: 20px 20px;
    color: #fff;
  }
}

.pricing-card-container {
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: -1px 9px 20px 10px rgba(214, 238, 255, 0.3);
  transition: transform ease-in-out 300ms;

  .title-container {
    color: #1f56a6;
    border-bottom: 1px solid #1f55a622;
  }

  &:hover {
    transform: translate(0, -10px);
  }
}

.related-companies-container {
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: -1px 9px 20px 10px rgba(214, 238, 255, 0.3);
}

a {
  text-decoration: none !important;
}

.table-item-header {
  cursor: pointer;
  transition: all 0.3s ease-in-out !important;
  .name {
    color: var(--c-primary) !important;
  }
  &:hover {
    .name {
      font-weight: 800;
      color: #0049a1;
    }

    .lob {
      font-weight: 600;
    }
  }
}

.news-card {
  background: white;
  box-shadow: -1px 9px 20px 10px rgba(214, 238, 255, 0.3);
  border: 1px solid #ececec;
  border-radius: 15px;
  cursor: pointer;
  transition: transform ease-in-out 300ms;

  &:hover {
    transform: translate(0, -10px);
  }
}

.news-property-container {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 15px;
  padding-bottom: 15px;
  .init-container {
    width: 100%;
  }
}

.news-title {
  font-size: 1.25rem;
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  font-weight: 800;
}
.news-source {
  color: gray;
}
.news-date {
  color: gray;
}
.news-tone {
  .Positive-text {
    color: rgb(0, 176, 0);
  }
  .Negative-text {
    color: red;
  }
  .Neutral-text {
    color: #0052b4;
  }
  .positive {
    height: 18px;
    width: 18px;
    background-color: rgba(0, 128, 0, 0.15);
    color: rgb(0, 176, 0);
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 0.9rem !important;
    }
  }
  .negative {
    height: 18px;
    width: 18px;
    background-color: rgba(255, 0, 0, 0.201);
    color: red;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 0.9rem !important;
    }
  }
  .neutral {
    height: 18px;
    width: 18px;
    background-color: rgba(0, 89, 255, 0.424);
    color: #0052b4;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 0.9rem !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .tab-container {
    .tab {
      font-size: 15px;
    }
  }
}
@media only screen and (min-width: 600px) {
  .tab-container {
    .tab {
      font-size: 15px;
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .tab-container {
    .tab {
      font-size: 15px;
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .tab-container {
    .tab {
      font-size: 20px;
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .tab-container {
    .tab {
      font-size: 20px;
    }
  }
}

.customized-btn {
  background-color: #4ac9e300 !important;
  color: #0052b4 !important;
}

.customized-btn:hover {
  color: #fff !important;
}
</style>
