<template>
  <div id="head">
    <div v-if="dataready">
      <div class="bg-container">
        <div
          class="bg mx-0"
          v-bind:style="{ 'background-image': 'url(' + bg + ')' }"
        ></div>
        <div class="text-container">
          <div class="container">
            <h1 class="openSansBold text-center page-title">
              {{ returnPageContent.page_section.page_title }}
            </h1>
            <h5 class="openMedium text-center subtitle">
              {{ returnPageContent.page_section.page_subtitle }}
            </h5>
          </div>
        </div>
      </div>
      <div class="container py-5">
        <div
          v-for="(es, esidx) in returnPageContent.explanation_section"
          :key="esidx"
        >
          <p v-if="es.type == 'paragraph'" :class="es.class">
            {{ es.content }}
          </p>
          <h4 v-if="es.type == 'h4'" :class="es.class">
            {{ es.content }}
          </h4>
          <span v-if="es.type == 'span'">
            <span
              v-for="(esc, escidx) in es.contents"
              :key="escidx"
              :class="esc.class"
            >
              {{ esc.content }}
            </span>
          </span>
          <ol v-if="es.type == 'ordered-list'" class="py-3">
            <li
              v-for="(esc, escidx) in es.contents"
              :key="escidx"
              :class="esc.class"
            >
              {{ esc.content }}
            </li>
          </ol>
        </div>
      </div>
      <div class="container">
        <div class="form-body">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <h3 class="c-primary poppinsBold">
                {{ returnPageContent.form_section.title }}
              </h3>
            </div>
            <div
              class="col-12 my-3"
              v-for="(fi, fidx) in returnPageContent.form_section.inputs"
              :key="fidx"
            >
              <div class="row">
                <div class="col-6">
                  <div class="form-label">
                    {{ fi.placeholder }}
                    <span
                      v-if="
                        !fi.isValidityOptional && fi.hasOwnProperty('isValid')
                      "
                      class="danger-text"
                      >*
                    </span>
                  </div>
                </div>
                <div
                  class="col-6 d-flex justify-content-end align-items-end"
                  v-if="
                    fi.hasOwnProperty('isValid') &&
                    fi.isValid().hasOwnProperty('configs')
                  "
                  :class="[
                    fi.isValid().status == false && once_focused.includes(fi.id)
                      ? 'danger-text'
                      : '',
                    fi.isValid().status == true && once_focused.includes(fi.id)
                      ? 'success-text'
                      : '',
                  ]"
                >
                  <small style="font-size: 0.7em">
                    {{ fi.isValid().configs.curr_length }} /
                    {{ fi.isValid().configs.max_length }}
                  </small>
                </div>
              </div>
              <vs-input
                v-if="fi.render_type == 'vs'"
                placeholder="testing"
                :type="fi.type"
                :placeholder="fi.placeholder"
                v-model="fi.input"
                @focus="pushOnceFocused(fi.id)"
              ></vs-input>

              <textarea
                v-model="fi.input"
                class="form-control"
                v-if="fi.render_type == 'textarea'"
                @focus="pushOnceFocused(fi.id)"
              ></textarea>
              <vs-switch
                v-if="fi.render_type == 'vs-switch'"
                v-model="fi.input"
                style="width: 150px"
              >
                <template #off> {{ fi.switch_configs.off }} </template>
                <template #on> {{ fi.switch_configs.on }} </template>
              </vs-switch>
              <vs-select
                v-if="fi.render_type === 'vs-select-multiple'"
                filter
                multiple
                placeholder="Filter"
                v-model="fi.input"
              >
                <vs-option-group>
                  <vs-option
                    v-for="(option, optin) in fi.options"
                    :key="optin"
                    :label="option.value"
                    :value="option.key"
                  >
                    {{ option.value }}
                  </vs-option>
                </vs-option-group>
              </vs-select>
              <div
                v-if="
                  fi.hasOwnProperty('isValid') &&
                  fi.isValid().hasOwnProperty('message') &&
                  once_focused.includes(fi.id)
                "
                :class="
                  fi.isValid().status == false ? 'danger-text' : 'success-text'
                "
              >
                <small style="font-size: 15px">{{
                  fi.isValid().message
                }}</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <vs-button
                @click="submitReport()"
                class="openSansBold"
                style="width: 100%"
                :disabled="returnHasErrors"
              >
                Submit
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <div class="container py-5">
        <div>
          <div style="position: relative; width: auto">
            <img
              :src="ic_cs"
              alt=""
              width="50px"
              height="50px"
              style="position: relative; z-index: 1"
            />
            <span
              class="pl-5 pr-3 py-2 c-primary openSansBold"
              style="
                border-radius: 100px;
                position: relative;
                margin-left: -50px;
                z-index: 0;
                border: 1px solid #7f9ec3;
                width: auto;
              "
            >
              {{
                returnCurrentLanguage == "EN" ? "Contact Us" : "Hubungi Kami"
              }}
            </span>
          </div>
        </div>
        <div class="py-2 d-flex align-items-center ml-2">
          <div
            class="d-flex justify-content-center align-items-center"
            style="
              border-radius: 100%;
              background: #cacbcb;
              width: 30px;
              height: 30px;
              color: white;
            "
          >
            <div class="material-icons-outlined" style="font-size: 15px">
              email
            </div>
          </div>
          <div class="c-primary ml-2 openSansSemiBold">
            wbsbi@cyberquote.co.id
          </div>
        </div>
      </div>
    </div>
    <vs-dialog v-model="show_result_dialog">
      <div class="result-dialog">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <svg
              v-if="!dialog_messages.is_success"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="red"
                d="m12 13.4l2.9 2.9q.275.275.7.275t.7-.275q.275-.275.275-.7t-.275-.7L13.4 12l2.9-2.9q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275L12 10.6L9.1 7.7q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7l2.9 2.9l-2.9 2.9q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275zm0 8.6q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"
              />
            </svg>
            <svg
              v-if="dialog_messages.is_success"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="green"
                d="m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"
              />
            </svg>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <h3 class="montserratBold">{{ dialog_messages.title }}</h3>
          </div>
          <div class="col-12 d-flex justify-content-center result-message my-3">
            {{ dialog_messages.message }}
          </div>
          <div
            class="col-12 d-flex justify-content-center"
            v-if="dialog_messages.is_success != false"
          >
            <div class="reference-container">
              <div class="ref-text py-2 px-5">
                {{ dialog_messages.reference_code }}
              </div>
              <div class="copy-icon-container" @click="copyToClipBoard()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M9 18q-.825 0-1.412-.587T7 16V4q0-.825.588-1.412T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.587 1.413T18 18zm-4 4q-.825 0-1.412-.587T3 20V7q0-.425.288-.712T4 6q.425 0 .713.288T5 7v13h10q.425 0 .713.288T16 21q0 .425-.288.713T15 22z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="col-12 d-flex justify-content-center my-2"
            v-if="reference_code_copied"
          >
            <small
              class="px-3"
              style="
                background: rgb(0, 0, 0);
                border-radius: 20px;
                color: white;
              "
            >
              reference code copied!
            </small>
          </div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>
<script>
import the_bg from "@/assets/bgs/whistleblow_bg.png";
import ic_customer_service from "@/assets/icons/ic_customer_support.svg";
import { mapGetters } from "vuex";
import { EN, ID } from "@/assets/contents/whistleblowcontent";
import moment from "moment";
import axios from "axios";
import { load } from "recaptcha-v3";

export default {
  data() {
    return {
      bg: the_bg,
      dataready: false,
      currentLanguage: "EN",
      page_contents: {
        EN,
        ID,
      },
      ic_cs: ic_customer_service,
      once_focused: [],
      loading: null,
      show_result_dialog: false,
      dialog_messages: {
        is_success: "",
        title: "",
        reference_code: "",
        message: "",
      },
      reference_code_copied: false,
    };
  },
  methods: {
    iniateSwapLanguage() {
      this.currentLanguage = this.returnCurrentLanguage;
      this.dataready = true;
    },
    pushOnceFocused(id) {
      this.once_focused.push(id);
    },

    scrollToTop() {
      setTimeout(() => {
        const element = document.getElementById("head");
        if (element) {
          element.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      }, 20);
    },
    submitReport() {
      // this.returnPageContent.form_section.inputs
      let built = {};
      for (
        let i = 0;
        i < this.returnPageContent.form_section.inputs.length;
        i++
      ) {
        let curr_item = this.returnPageContent.form_section.inputs[i];
        if (curr_item.render_type !== "vs-select-multiple") {
          built = Object.assign(built, {
            [curr_item.id]: curr_item.input,
          });
        } else {
          let cp_input = [];
          for (let x = 0; x < curr_item.input.length; x++) {
            let curr_input_ans = curr_item.input[x];
            let filter_opti = curr_item.options.filter((item) => {
              return item.key == curr_input_ans;
            })[0];

            cp_input.push(filter_opti.value);
          }
          built = Object.assign(built, {
            [curr_item.id]: cp_input,
          });
        }
      }

      built.incident_time = moment(built.incident_time).format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      if (!this.returnHasErrors) {
        built = Object.assign(built, { source: "indoinfo.co.id" });
        this.pushToAPI(built);
        console.log("building: ", built);
      }
    },

    pushToAPI(data) {
      let self = this;

      this.loading = this.$vs.loading({
        scale: "0.6",
        background: "primary",
        opacity: 0.5,
        color: "#fff",
      });

      let final_data = data;
      console.log("the final data", final_data);
      load(this.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              axios
                .post(`${self.returnSiteConfig.e_p}whistle-blow`, final_data, {
                  params: {
                    init_token: token,
                  },
                })
                .then((res) => {
                  console.log("submission res: ", res);
                  this.dialog_messages = {
                    is_success: true,
                    title: "Report Submitted",
                    reference_code: res.data.ref_code,
                    message:
                      this.returnLanguage == "EN"
                        ? "Thank you for submitting your report. We have received it and will provide you with an update regarding your report shortly. To track the current status of your report, kindly copy the reference code provided below."
                        : "Terima kasih telah mengirimkan laporan Anda. Laporan Anda telah kami terima dan kami akan segera memberikan kabar terbaru terkait laporan Anda. Untuk melacak status terbaru laporan Anda, silakan salin kode referensi yang disediakan di bawah ini.",
                  };
                  this.show_result_dialog = true;
                  this.loading.close();
                })
                .catch((error) => {
                  this.loading.close();
                  this.dialog_messages = {
                    is_success: false,
                    title: "An Error Occured",
                    message:
                      this.returnLanguage == "EN"
                        ? "We regret to inform you that there was an issue processing your report submission. Please double-check the information provided and attempt to submit the report again. If the problem persists, kindly contact our support team for further assistance. We apologize for any inconvenience."
                        : "Kami menyesal untuk memberitahu Anda bahwa terdapat masalah dalam memproses pengiriman laporan Anda. Harap periksa kembali informasi yang diberikan dan coba kirimkan laporan lagi. Jika masalah tetap berlanjut, mohon hubungi tim dukungan kami untuk bantuan lebih lanjut. Kami mohon maaf atas segala ketidaknyamanan yang terjadi.",
                  };
                  this.show_result_dialog = true;
                });
            })
            .catch((error) => {
              console.log("BA COMMENT ERROR", error);
              this.loading.close();
              this.dialog_messages = {
                is_success: false,
                title: "An Error Occured",
                message:
                  this.returnLanguage == "EN"
                    ? "We regret to inform you that there was an issue processing your report submission. Please double-check the information provided and attempt to submit the report again. If the problem persists, kindly contact our support team for further assistance. We apologize for any inconvenience."
                    : "Kami menyesal untuk memberitahu Anda bahwa terdapat masalah dalam memproses pengiriman laporan Anda. Harap periksa kembali informasi yang diberikan dan coba kirimkan laporan lagi. Jika masalah tetap berlanjut, mohon hubungi tim dukungan kami untuk bantuan lebih lanjut. Kami mohon maaf atas segala ketidaknyamanan yang terjadi.",
              };
              this.show_result_dialog = true;
              throw error;
            });
        }
      );
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.dialog_messages.reference_code);
      this.reference_code_copied = true;

      setTimeout(() => {
        this.reference_code_copied = false;
      }, 4000);
    },
  },
  computed: {
    ...mapGetters(["siteConfig", "language"]),
    returnCurrentLanguage() {
      return this.language;
    },
    returnPageContent() {
      return this.page_contents[this.language];
    },
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnHasErrors() {
      let has_errors = this.page_contents[
        this.language
      ].form_section.inputs.filter((item) => {
        return item.hasOwnProperty("isValid") && item.isValid().status == false;
      });

      console.log("returnHasErrors: ", has_errors);

      if (has_errors.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    returnCurrentLanguage(newVal, oldVal) {
      this.iniateSwapLanguage();
    },
  },
  mounted() {
    this.iniateSwapLanguage();
    this.scrollToTop();
  },
};
</script>
<style lang="scss" scoped>
.bg-container {
  position: relative;
  height: 70vh;
  min-height: 5vh;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  .bg {
    height: 100%;
    min-height: 70vh;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
  }
}

.text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  color: #fff;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 3vw !important;
}

.subtitle {
  font-size: 1.2vw !important;
}

.form-body {
  border-radius: 20px;
  background: #f2f7ff;
  padding: 20px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 600px) {
  .page-title {
    font-size: 9vw !important;
  }

  .subtitle {
    font-size: 4vw !important;
  }
}
@media only screen and (min-width: 600px) {
  .page-title {
    font-size: 9vw !important;
  }

  .subtitle {
    font-size: 4vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .page-title {
    font-size: 7vw !important;
  }

  .subtitle {
    font-size: 2vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .page-title {
    font-size: 4vw !important;
  }

  .subtitle {
    font-size: 1.5vw !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .page-title {
    font-size: 3vw !important;
  }

  .subtitle {
    font-size: 1vw !important;
  }
}

.form-label {
  color: #464646;
  font-family: openSansSemiBold;
}
</style>
