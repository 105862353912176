export default {
  namespaced: true,
  state: {
    english: {
      page_section: {
        page_title: "CQ Speak Up - Whistleblowing System",
        page_subtitle:
          "Whistleblowing System is an application provided by Indoinfo for those of you who have information and want to report an action that indicates a violation.",
      },
      explanation_section: [
        {
          type: "paragraph",
          content:
            "To maintain and improve Indoinfo's reputation, risk control facilities and systems are needed through active participation from Indoinfo business partners to submit reports of complaints about disciplinary violations such as acts or indications of fraud, non-fraud and/or other violations that can harm clients and Indoinfo.",
        },
        {
          type: "paragraph",
          content:
            "This is in line with the Anti Fraud Strategy (SAF) which is part of the fraud control system, one of which includes the Whistle Blowing System (WBS) mechanism. In its implementation, we have provided a medium for reporting complaints of violations directly to the Information Security Management Standards (SMKI) team called CQ Speak Up.",
        },
        {
          type: "h4",
          content: "Whistleblower Confidentiality",
          class: "c-primary poppinsBold",
        },
        {
          type: "span",
          contents: [
            {
              class: "",
              content: "As a form of our commitment,",
            },
            {
              class: "poppinsBold",
              content:
                "your personal identity as a whistleblower and the contents of the report submitted will be kept confidential,",
            },
            {
              class: "",
              content:
                "because we only focus on the information you report. To better maintain confidentiality, please pay attention to the following:",
            },
          ],
        },
        {
          type: "ordered-list",
          contents: [
            {
              content:
                "Do not reveal/fill in personal data, such as your name, or your relationship with the perpetrator in question",
            },
            {
              content:
                "Avoid other people knowing your username, password and registration number",
            },
          ],
        },
      ],
      form_section: {
        title: "Complaint Form",
        inputs: [
          {
            id: "fullName",
            placeholder: "Name of the Complainant",
            input: "",
            render_type: "vs",
            type: "text",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 100,
                min_length: 3,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "phone",
            placeholder: "Phone Number",
            input: "",
            render_type: "vs",
            type: "number",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 12,
                min_length: 11,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long. DO NOT USE Country Codes e.g. +62`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long. DO NOT USE Country Codes e.g. +62`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "email",
            placeholder: "E-mail Address",
            input: "",
            render_type: "vs",
            type: "email",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 100,
                min_length: 10,
                curr_length: this.input.length,
              };
              const emailRegex =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!emailRegex.test(this.input)) {
                return {
                  status: false,
                  message:
                    "Please provide a legitimate email address for us to reach back to you",
                  configs,
                };
              } else if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "reported_person",
            placeholder: "Reported Name",
            input: "",
            render_type: "vs",
            type: "text",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 100,
                min_length: 3,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "reported_violation",
            placeholder: "Reported Violations",
            input: "",
            render_type: "textarea",
            type: "textarea",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 500,
                min_length: 10,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "incident_time",
            placeholder: "Time of Incident",
            input: "",
            render_type: "vs",
            type: "date",
            output_variable: "_outputvariable_",
            isValid: function () {
              if (this.input == undefined || this.input.length == 0) {
                return {
                  status: false,
                  message: "Date is required",
                };
              } else {
                return {
                  status: true,
                  message: "",
                };
              }
            },
          },
          {
            id: "incident_loc",
            placeholder: "Location of The Incident",
            input: "",
            render_type: "vs",
            type: "text",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 200,
                min_length: 5,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "chronology",
            placeholder: "Detail of The Incident",
            input: "",
            render_type: "textarea",
            type: "textarea",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 500,
                min_length: 50,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
        ],
      },
    },
    indonesian: {
      page_section: {
        page_title: "ID CQ Speak Up - Whistleblowing System",
        page_subtitle:
          "Whistleblowing System adalah aplikasi yang disediakan oleh indoinfo untuk Anda yang memiliki informasi dan ingin melaporkan suatu perbuatan yang berindikasi pelanggaran.",
      },
      explanation_section: [
        {
          type: "paragraph",
          content:
            "Dalam rangka menjaga serta meningkatkan reputasi indoinfo, diperlukan sarana dan sistem pengendalian risiko melalui partisipasi aktif dari mitra bisnis indoinfo untuk menyampaikan laporan pengaduan pelanggaran disiplin seperti perbuatan atau indikasi fraud, non fraud dan/atau pelanggaran lainnya yang dapat merugikan klien maupun indoinfo.",
        },
        {
          type: "paragraph",
          content:
            "Hal ini sejalan dengan Strategi Anti Fraud (SAF) yang merupakan bagian dari sistem pengendalian fraud yang salah satu diantaranya mencakup mekanisme Whistle Blowing System (WBS). Dalam implementasinya, kami telah menyediakan media pelaporan pengaduan pelanggaran tersebut secara langsung kepada tim Standar Manajemen Keamanan Informasi (SMKI) yang dinamakan CQ Speak Up.",
        },
        {
          type: "h4",
          content: "Kerahasiaan Pelapor",
          class: "c-primary poppinsBold",
        },
        {
          type: "span",
          contents: [
            {
              class: "",
              content: "Sebagai wujud komitmen kami,",
            },
            {
              class: "poppinsBold",
              content:
                "identitas pribadi Anda sebagai whistleblower dan isi laporan yang disampaikan akan kami rahasiakan,",
            },
            {
              class: "",
              content:
                "karena kami hanya fokus pada informasi yang Anda laporkan. Untuk kerahasiaan lebih terjaga, dimohon untuk perhatikan hal-hal berikut:",
            },
          ],
        },
        {
          type: "ordered-list",
          contents: [
            {
              content:
                "Jangan memberitahukan/mengisikan data pribadi, seperti nama Anda, atau hubungan Anda dengan pelaku yang bersangkutan",
            },
            {
              content:
                "Hindari orang lain mengetahui nama samaran (username), kata sandi (password) serta nomor registrasi Anda",
            },
          ],
        },
      ],
      form_section: {
        title: "Form Pengaduan",
        inputs: [
          {
            id: "fullName",
            placeholder: "Nama Pelapor",
            input: "",
            render_type: "vs",
            type: "text",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 100,
                min_length: 3,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "phone",
            placeholder: "No. Telepon",
            input: "",
            render_type: "vs",
            type: "number",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 12,
                min_length: 11,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long. DO NOT USE Country Codes e.g. +62`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long. DO NOT USE Country Codes e.g. +62`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "email",
            placeholder: "Alamat E-mail",
            input: "",
            render_type: "vs",
            type: "email",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 100,
                min_length: 10,
                curr_length: this.input.length,
              };
              const emailRegex =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!emailRegex.test(this.input)) {
                return {
                  status: false,
                  message:
                    "Please provide a legitimate email address for us to reach back to you",
                  configs,
                };
              } else if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "reported_person",
            placeholder: "Nama Yang Dilaporkan",
            input: "",
            render_type: "vs",
            type: "text",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 100,
                min_length: 3,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "reported_violation",
            placeholder: "Pelangggaran Yang Dilaporkan",
            input: "",
            render_type: "textarea",
            type: "textarea",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 500,
                min_length: 10,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "incident_time",
            placeholder: "Waktu Kejadian",
            input: "",
            render_type: "vs",
            type: "date",
            output_variable: "_outputvariable_",
            isValid: function () {
              if (this.input == undefined || this.input.length == 0) {
                return {
                  status: false,
                  message: "Date is required",
                };
              } else {
                return {
                  status: true,
                  message: "",
                };
              }
            },
          },
          {
            id: "incident_loc",
            placeholder: "Lokasi Kejadian",
            input: "",
            render_type: "vs",
            type: "text",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 200,
                min_length: 5,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
          {
            id: "chronology",
            placeholder: "Kronologi Kejadian",
            input: "",
            render_type: "textarea",
            type: "textarea",
            output_variable: "_outputvariable_",
            isValid: function () {
              let configs = {
                max_length: 500,
                min_length: 50,
                curr_length: this.input.length,
              };
              if (this.input.length < configs.min_length) {
                return {
                  status: false,
                  message: `Input length must be at least ${configs.min_length} chars long.`,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.min_length,
                  },
                };
              } else if (this.input.length > configs.max_length) {
                return {
                  status: false,
                  message: `The input length is more than the maximum chars of ${configs.max_length} chars long.`,
                  configs,
                };
              } else if (
                this.input.length <= configs.max_length &&
                this.input.length > configs.min_length
              ) {
                return {
                  status: true,
                  configs: {
                    curr_length: configs.curr_length,
                    max_length: configs.max_length,
                  },
                };
              } else {
                return {
                  status: true,
                  configs,
                };
              }
            },
          },
        ],
      },
    },
  },
  getters: {
    english: (s) => s.english,
    indonesian: (s) => s.indonesian,
  },
};
