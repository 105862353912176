<template>
  <div>
    <div v-if="articles.length == 0">
      <PuSkeleton :count="1" height="60vh" />
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-md-4 py-2"
            v-for="(loadimg, lidximg) in 6"
            :key="lidximg"
          >
            <PuSkeleton :count="1" height="150px" width="100%" />
            <PuSkeleton :count="1" height="30px" width="80%" />
            <PuSkeleton :count="3" height="20px" width="100%" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        id="head"
        class="backgroundImage mx-0"
        v-bind:style="{ 'background-image': 'url(' + blog_bg + ')' }"
      ></div>
      <div class="row px-0 mx-0" style="margin-top: -50vh">
        <div class="col-12 d-flex justify-content-center">
          <h6 class="white openSansBold" style="font-size: 5em">
            {{ returnHeaderText }}<span class="c-primary ml-0">.</span>
          </h6>
        </div>
      </div>

      <div class="row px-5 mx-0" style="margin-top: 40vh; margin-bottom: 5vh">
        <div
          class="col-12 col-md-6 col-lg-3 d-flex justify-content-center py-2"
          v-for="(item, index) in articles"
          :key="index"
          style="border-shadow: 0px 0px 10px 10px #000"
        >
          <router-link :to="`/article?q=${item.slug}`">
            <vs-card>
              <template #title>
                <h3 class="openSansBold" style="width: 100%">
                  <span>{{ item.title_artikel }}</span>
                </h3>
              </template>
              <template #img>
                <img :src="image_path_base + item.url_image" alt="" />
              </template>
              <template #text>
                <p></p>
              </template>
            </vs-card>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blogbg from "@/assets/bgs/blog_bg.png";
import axios from "axios";
import { load } from "recaptcha-v3";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      articles: [],
      article_path: "article/active?src=indoinfo",
      blog_bg: blogbg,
      image_path_base: "https://portal.indoinfo.co.id/img/",
      loading: null,
    };
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: `Stay up to date with our latest news and articles. 
            We provide you with the latest breaking news and articles straight from the business world.`,
      },
    ],
  },
  methods: {
    async getDataWithCaptcha() {
      console.log(
        "ARTICLE VIEW INITIALIZING (GETTING DATA WITH CAPTCHA) ",
        location.hostname,
        this.returnSiteConfig.cpt
      );
      let self = this;
      console.log("current captcha: ", this.returnSiteConfig);
      await load(self.returnSiteConfig.cpt, { autoHideBadge: true }).then(
        (recaptcha) => {
          recaptcha
            .execute("get")
            .then((token) => {
              console.log("GET DATA TOKEN: ", token);
              axios
                .get(self.returnSiteConfig.e_p + self.article_path, {
                  params: {
                    init_token: token,
                  },
                })
                .then((res) => {
                  console.log("the data first", res.data);
                  this.articles = res.data;
                  this.scrollToTop();
                })
                .catch((error) => {
                  this.scrollToTop();
                  console.log("ARTICLE get data token error", error);
                })
                .catch((error) => {
                  console.log("ARTICLE get data token error", error);
                  this.scrollToTop();
                });
            })
            .catch((error) => {
              console.log("ARTICLE get data recaptcha error", error);
              this.scrollToTop();
            });
        }
      );
    },
    openArticle(slug) {
      this.$router.push({ path: "/article", query: { q: slug } });
      console.log("OPEN ARTICLE: ", slug);
    },

    scrollToTop() {
      setTimeout(() => {
        const element = document.getElementById("head");
        if (element) {
          element.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      }, 20);
    },
    initiateLoading() {
      // this.loading = this.$vs.loading({
      //   type: "scale",
      //   text: "Knowledge is loading...",
      //   opacity: 1,
      // });
    },
  },
  computed: {
    ...mapGetters(["siteConfig", "language"]),
    returnSiteConfig() {
      return this.siteConfig;
    },
    returnHeaderText() {
      if (this.language == "ID") {
        return "Artikel";
      } else {
        return "Articles";
      }
    },
  },
  watch: {
    articles: {
      handler(val) {
        if (val.length > 0) {
          // this.loading.close();
        }
        // this.loading.close();
      },
      deep: true,
    },
  },
  mounted() {
    this.scrollToTop();
    this.getDataWithCaptcha();
    this.initiateLoading();
  },
};
</script>

<style lang="scss" scoped>
.backgroundImage {
  min-height: 80vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000;
}
</style>
