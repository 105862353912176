<template>
  <div>
    <vs-dialog v-model="show_dialog">
      <div class="dialog">
        <div class="">
          <div class="announcement-container">
            <img :src="returnLanguage == 'EN' ? EN_IMG : ID_IMG" alt="" />
            <div class="poppinsBold text-center">
              <h3>
                <div v-if="returnLanguage == 'EN'">
                  Get potential customer information directly from your email.
                  Start your free trial!
                </div>
                <div v-else>
                  Dapatkan informasi pelanggan potensial secara langsung dari
                  email Anda. Mulai uji coba gratis!
                </div>
              </h3>
            </div>
            <div class="row px-0 px-md-5 mx-0 py-3">
              <div class="col-12 col-md-6">
                <vs-button block @click="openDialog('Contact Sales')">
                  <h5 class="poppinsSemiBold my-0 py-2">
                    <span v-if="returnLanguage == 'EN'">
                      Schedule a Meeting
                    </span>
                    <span v-else> Jadwalkan Meeting </span>
                  </h5>
                </vs-button>
              </div>
              <div class="col-12 col-md-6">
                <vs-button block flat @click="openDialog('Register')">
                  <h5 class="poppinsSemiBold my-0 py-2">
                    <span v-if="returnLanguage == 'EN'"> Register Now </span>
                    <span v-else> Daftar Sekarang </span>
                  </h5>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ENIMG from "@/assets/announcement/alt-img-prod-EN.png";
import IDIMG from "@/assets/announcement/alt-img-prod-ID.png";

export default {
  data() {
    return {
      show_dialog: true,
      end_date: "2024-12-28", //yyyy-mm-dd
      EN_IMG: ENIMG,
      ID_IMG: IDIMG,
    };
  },
  methods: {
    shouldShowBaner() {
      const currentDate = new Date();
      const targetDate = new Date(this.end_date);

      // Comparing dates
      if (currentDate > targetDate) {
        console.log("FALSE: current: ", currentDate, "| target:", targetDate);
        this.show_dialog = false;
      } else {
        console.log("TRUE: current", currentDate, "| target:", targetDate);
        this.show_dialog = true;
      }
    },

    openDialog(name) {
      if (name === "Register" || name === "Contact Sales") {
      }

      var x = {
        state: true,
        tab: name,
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
  },
  computed: {
    ...mapGetters(["language"]),
    returnLanguage() {
      return this.language;
    },
  },
  mounted() {
    this.shouldShowBaner();
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  // max-height: 80vh;
  // max-width: 40vw;
  width: auto;
  width: auto;
  overflow-y: auto;

  img {
    width: 100%;
  }
}
</style>
