<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    if (this.$route.path == "/en") {
      this.$store.commit("SET_LANGUAGE", "EN");
      console.log("this is en", this.$route.path);
      this.$router.push({ path: "/" });
    } else if (this.$route.path == "/id") {
      console.log("this is id", this.$route.path);
      this.$store.commit("SET_LANGUAGE", "ID");

      this.$router.push({ path: "/" });
    } else {
      this.$router.push({ path: "/" });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>