<template>
  <div class="container-fluid mx-0 px-4 d-flex" style="padding-bottom: 60px">
    <div class="container">
      <div class="row" style="max-width: 100vw; overflow-x: hidden">
        <div class="col-12">
          <h2
            class="openSansBold"
            :class="{
              'text-center': this.text_align === 'center',
              'text-left': this.text_align === 'left',
              'text-right': this.text_align === 'right',
            }"
          >
            {{ left_splitted_header
            }}<span class="emphasis">{{ outlined_text }}</span
            >{{ right_splitted_header }}
          </h2>
        </div>
        <div class="col-12 d-flex justify-content-end" v-if="description !== undefined">
          <p
            :class="{
              'text-center': this.text_align === 'center',
              'text-left': this.text_align === 'left',
              'text-right': this.text_align === 'right',
            }"
          >
            {{ description }}
          </p>
        </div>
        <div
        v-if="this.cta_text !== undefined"
          class="col-12 d-flex"
          :class="{
            'justify-content-center': this.text_align === 'center',
            'justify-content-start': this.text_align === 'left',
            'justify-content-end': this.text_align === 'right',
          }"
        >
          <div class="btn btn-primary" @click="goToLink()" v-if="this.cta_text !== ''">
            {{ this.cta_text }}
          </div>
        </div>
        <div class="col-12 py-5" style="overflow-x: hidden">
          <div class="row align-items-center">
            <div class="container rounded">
              <div class="slider">
                <div
                  class="logos mx-4"
                  v-for="(logos, index) in logostop"
                  :key="index + 'top-1'"
                >
                  <span class="icon"><img :src="logos" alt="" /></span>
                </div>
                <div
                  class="logos mx-4"
                  v-for="(logos, index) in logostop"
                  :key="index + 'top-2'"
                >
                  <span class="icon"><img :src="logos" alt="" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 mt-4" style="overflow-x: hidden">
        <div class="row align-items-center">
          <div class="container container-center rounded">
            <div class="slider-center">
              <div
                class="logos-center mx-4"
                v-for="(logos, index) in center_logos"
                :key="index + 'center-1'"
              >
                <span class="icon-center"><img :src="logos" alt="" /></span>
              </div>
              <div
                class="logos-center mx-4"
                v-for="(logos, index) in center_logos"
                :key="index + 'center-2'"
              >
                <span class="icon-center"><img :src="logos" alt="" /></span>
              </div>
              <div
                class="logos-center mx-4"
                v-for="(logos, index) in center_logos"
                :key="index + 'center-3'"
              >
                <span class="icon-center"><img :src="logos" alt="" /></span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
        <!-- <div class="col-12 mt-4" style="overflow-x: hidden">
        <div class="row align-items-center">
          <div class="container rounded">
            <div class="slider">
              <div
                class="logos mx-4"
                v-for="(logos, index) in bottom_logos"
                :key="index + 'top-1'"
              >
                <span class="icon"><img :src="logos" alt="" /></span>
              </div>
              <div
                class="logos mx-4"
                v-for="(logos, index) in bottom_logos"
                :key="index + 'top-2'"
              >
                <span class="icon"><img :src="logos" alt="" /></span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import app_sinarmas from "@/assets/brands/app_sinarmas.png";
import atradius from "@/assets/brands/atradius.png";
import mandiri from "@/assets/brands/mandiri.png";
import marubeni from "@/assets/brands/marubeni.png";
import mitsui from "@/assets/brands/mitsui.png";
import otis from "@/assets/brands/otis.png";
import pertamina from "@/assets/brands/pertamina.png";
import pln from "@/assets/brands/pln.png";
import sampoerna from "@/assets/brands/sampoerna.png";
import siemens from "@/assets/brands/siemens.png";
import sojitz from "@/assets/brands/sojitz.png";
import standard_chartered from "@/assets/brands/standard_chartered.png";
import waskita from "@/assets/brands/waskita.png";

export default {
  props: {
    header: String,
    text_align: String,
    description: String,
    cta_text: String,
    outlined_text: String,
    current_language: String
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      logostop: [
        marubeni,
        mitsui,
        otis,
        pertamina,
        pln,
        sampoerna,
        atradius,
        mandiri,
      ],
      center_logos: [sojitz, standard_chartered, waskita, siemens, pertamina],
      bottom_logos: [
        standard_chartered,
        app_sinarmas,
        waskita,
        atradius,
        mandiri,
        siemens,
        sampoerna,
      ],
    };
  },

  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("could not find");
      }
    },
    goToLink() {
      var x = {
        state: true,
        tab: "Register",
      };
      this.$store.dispatch("CHANGE_DIALOG_STATUS", x);
    },
  },

  mounted() {
    this.CutandDump();
  },

  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    current_language() {
      this.CutandDump();
    },
  },
};
</script>

<style scoped lang="scss">
img {
  height: 50px;
  width: auto;
}
.container {
  overflow: hidden;
  .slider {
    animation: slidein 30s linear infinite;
    white-space: nowrap;
    .logos {
      width: auto;
      display: inline-block;
      .icon {
        width: calc(100% / 5);
        animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      }
    }
  }
}
.container-center {
  overflow: hidden;
  .slider-center {
    animation: slidein 10s linear infinite;
    white-space: nowrap;
    .logos-center {
      width: auto;
      display: inline-block;
      .icon-center {
        width: calc(100% / 8);
        animation: fade-in 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      }
    }
  }
}

@keyframes slidein {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideout {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
</style>