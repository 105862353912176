<template>
  <div v-if="data_copied">
    <email-send
      :mail_type.sync="currentTab"
      :user_details.sync="user_details"
      :should_send_email.sync="email_send"
      :email_send_status.sync="email_send_status"
      @update="email_send, email_send_status"
    />
    <vs-dialog
      v-model="active"
      blur
      not-padding
      full-screen
      auto-width
      scroll
      overflow-hidden
    >
      <div class="row px-0 mx-0">
        <div
          class="col-12 col-md-5 order-1 order-md-0 px-0 mx-0"
          style="position: relative"
        >
          <div
            class="row mx-0 px-0 align-items-center"
            style="height: 100%; width: 100%; position: absolute"
          >
            <div class="col-12 py-5 white" style="">
              <h1 class="openSansBold text-right">
                {{ returnTitle.header + " " + returnTitle.outlined_text }}
                <br />
                <span> </span>
              </h1>
              <p class="text-right"></p>
            </div>
          </div>
          <div
            v-bind:style="{
              'background-image': 'url(' + returnBackgrounds + ')',
            }"
            style="
              max-height: calc(100vh - 30px);
              min-height: calc(100vh - 30px);
              overflow-y: hidden;
            "
          ></div>
        </div>
        <div
          class="col-12 col-md-7 order-0 order-md-1"
          style="
            max-height: calc(100vh - 30px);
            overflow-y: auto;
            height: 100vh;
          "
        >
          <div class="con-form row px-5">
            <div class="col-12">
              <div class="row px-0 pt-5">
                <div class="col-12 mx-0 px-0 col-md-6 order-2 order-md-1">
                  <h2 class="openSansBold c-primary">
                    <span v-if="currentTab === 'Register'">
                      {{ page_settings.title.register }}
                    </span>
                    <span v-if="currentTab === 'Contact Sales'">
                      {{ page_settings.title.contact_sales }}
                    </span>
                  </h2>
                </div>
                <div
                  class="col-12 col-md-6 order-1 order-md-2 d-flex justify-content-start justify-content-md-end mx-0 px-0"
                >
                  <div
                    v-for="(tab, index) in tabs"
                    :key="index + 'tabs'"
                    :class="{
                      'active openSansBold c-primary': tab === currentTab,
                      openSansMedium: tab !== currentTab,
                    }"
                  >
                    <div v-show="currentTab !== tab">
                      <vs-button @click="currentTab = tab" transparent>
                        <span v-if="currentTab === 'Register'">
                          {{ page_settings.title.contact_sales }}
                        </span>
                        <span v-if="currentTab === 'Contact Sales'">
                          {{ page_settings.title.register }}
                        </span>
                        <span
                          class="material-icons-round"
                          style="font-size: 15px"
                          >arrow_forward_ios</span
                        ></vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-12 py-2"
              style="border-bottom: 2px solid #0052b4"
            ></div>

            <div
              v-if="email_send_status === 'success'"
              class="col-12 py-4 justify-content-center openSansSemiBold px-0"
            >
              <vs-alert>
                <template #title>
                  <b>{{ page_settings.notification.title }}</b>
                </template>
                {{ page_settings.notification.text }}
              </vs-alert>
            </div>
            <!-- INPUTS -->
            <div class="col-12 py-3 px-0">
              <div
                class="col-12 py-3 px-0"
                v-for="input in returnInputs.inputs"
                :key="input.id"
              >
                <vs-input
                  primary
                  icon-after
                  :id="input.id"
                  v-model="input.input"
                  :label-placeholder="input.placeholder"
                  :type="input.type"
                  v-if="input.type !== 'textarea'"
                >
                  <template #icon>
                    <span
                      class="material-icons-outlined"
                      style="font-size: 15px"
                      >{{ input.icon }}</span
                    >
                  </template>

                  <template #message-danger>
                    <span
                      v-if="
                        input.type === 'email' && !regex_matcher(input.type)
                      "
                    >
                      {{ page_settings.validation_error.invalid_email_regex }}
                    </span>

                    <span
                      v-if="input.type !== 'email' && input.input.length < 5"
                      >{{ input.placeholder }}
                      {{
                        page_settings.validation_error.less_than_required
                      }}</span
                    >
                  </template>
                </vs-input>
                <div class="col-12" v-if="input.type === 'textarea'">
                  <div class="row">
                    <div class="col-6">
                      <!-- <small for="comment" class="ml-0">{{ input.placeholder }}</small> -->
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <small for="comment" class="ml-0">
                        <span
                          class="openSansRegular"
                          v-if="input.input.length <= 250"
                          >{{ input.input.length }}/</span
                        >
                        <span
                          class="openSansRegular danger-text"
                          v-if="input.input.length > 250"
                          >{{ input.input.length }}/</span
                        >
                        <span
                          class="openSansRegular danger-text px-0 py-1"
                          v-if="input.input.length < 30"
                          >30 minimum length</span
                        >
                        <span
                          class="openSansRegular success-text px-0 py-1"
                          v-if="
                            input.input.length >= 30 &&
                            input.input.length <= 250
                          "
                          >250 maximum length</span
                        >
                        <span
                          class="openSansRegular danger-text px-0 py-1"
                          v-if="
                            input.input.length >= 30 && input.input.length > 250
                          "
                          >250 maximum length</span
                        >
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-floating" v-if="input.type === 'textarea'">
                  <textarea
                    class="form-control"
                    :placeholder="input.placeholder"
                    :id="input.id"
                    v-model="input.input"
                  ></textarea>
                </div>
              </div>
            </div>
            <!-- END OF INPUTS -->

            <!-- CHECKBOXES -->
            <div class="col-12" v-show="currentTab === 'Contact Sales'">
              <div class="row">
                <div
                  class="col-12 d-flex align-items-center openSansMedium pt-5"
                >
                  {{ returnInputs.options_title }}
                </div>
                <div
                  class="col-12 col-md-6 mt-4"
                  v-for="(option, index) in returnInputs.options"
                  :key="index + 'contact_options'"
                >
                  <b-form-checkbox
                    :id="option.id"
                    v-model="returnInputs.options_status"
                    :name="option.name"
                    :value="option.value"
                    :unchecked-value="option.unchecked_value"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <!-- END OF CHECKBOXES -->

            <div
              class="col-12 py-4 justify-content-center openSansSemiBold px-0"
            >
              <vs-button
                block
                ref="getchabet"
                :disabled="
                  !regexEmailChecker ||
                  !fieldsPassed ||
                  !messagePassed ||
                  email_send_status === 'sending'
                "
                @click="input_check()"
                size="l"
                class="d-flex align-items-center"
              >
                <span
                  v-if="
                    returnInputs.options_status !== 'Appointment' &&
                    returnInputs.options_status !== 'Pertemuan' &&
                    currentTab === 'Contact Sales'
                  "
                  class="material-icons-outlined mb-2 mr-1"
                  style="font-size: 20px"
                  >whatsapp</span
                >
                <h5 class="py-0 px-0">
                  <span v-if="currentTab === 'Register'">
                    {{ page_settings.title.register }}
                  </span>
                  <span v-if="currentTab === 'Contact Sales'">
                    {{ page_settings.buttons.send }}
                  </span>
                </h5>
              </vs-button>
            </div>
            <div
              class="col-12 py-1 mb-3"
              style="border-bottom: 2px solid #0052b4"
            ></div>

            <div class="col-12 mx-0 px-0 d-flex justify-content-center">
              <div class="row mx-0 px-0">
                <div class="col-12 d-flex justify-content-center">
                  {{ page_settings.question_login }}
                </div>
                <div
                  class="col-12 mx-0 px-0 d-flex justify-content-center openSansSemiBold"
                >
                  <vs-button block transparent @click="login()">
                    <h5>Login</h5>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import rocketImg from "@/assets/others/rocket.png";
import consult_background from "@/assets/bgs/consult_bg.png";
import register_background from "@/assets/bgs/register_bg.png";
import EmailSend from "@/components/utils/EmailSend.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    EmailSend,
  },
  props: {
    page_settings_default: Object,
  },
  data() {
    return {
      active: false,
      data_copied: false,
      page_settings: {},
      rocket: rocketImg,
      consult_bg: consult_background,
      register_bg: register_background,
      currentTab: "Contact Sales",

      tabs: ["Register", "Contact Sales"],
      loading: null,
      button_loading: null,
      noti: null,
      email_send: false,
      email_send_status: "",
      user_details: {},
      send_amount: {
        reg: 0,
        con: 0,
      },
    };
  },
  methods: {
    login() {
      window.open("https://portal.indoinfo.co.id/login", "_blank");
    },
    input_check() {
      console.log("regex email checker", this.regexEmailChecker);
      if (this.fieldsPassed && this.regexEmailChecker) {
        this.initiateTextSend();
      }
    },
    regex_matcher(type) {
      if (type === "email") {
        return this.regexEmailChecker;
      }
    },
    initiateTextSend() {
      this.loading = this.$vs.loading({
        text: "Please Wait",
      });
      setTimeout(() => {
        this.loading.close();
        this.noti = this.$vs.notification({
          color: "primary",
          position: "top-center",
          duration: "none",
          width: "100%",
          title: this.page_settings.notification.title,
          text: this.page_settings.notification.text,
        });
        this.sendText();
      }, 5000);
    },
    sendText() {
      var inputs = this.returnInputs.inputs;
      var input_string = "";
      let email_object = {};
      for (var i = 0; i < inputs.length; i++) {
        console.log("test key: ", inputs[i].output_variable);
        email_object[inputs[i].email_key] = inputs[i].input;
        input_string +=
          inputs[i].output_variable + ": " + inputs[i].input + "\r\n";
      }
      let currentTab = "*" + this.currentTab + "* \r\n\r\n";

      var whatsappMessage = "";
      if (this.currentTab === "Register") {
        // REGISTER TAB
        console.log("email body: ", email_object);

        if (this.send_amount.reg === 0) {
          this.user_details = email_object;
          this.user_details = {
            ...this.user_details,
            source: "indoinfo.co.id",
          };
          this.email_send = true;
          console.log("user_send_details: ", this.user_details);
        } else {
          this.email_send_status = "";
        }
      } else if (this.currentTab === "Contact Sales") {
        // CONTACT SALES TAB
        email_object["appointment"] = this.returnInputs.options_status;

        if (this.send_amount.con === 0) {
          this.user_details = email_object;
          this.user_details = {
            ...this.user_details,
            source: "indoinfo.co.id",
          };

          this.email_send = true;
          console.log("user_send_details: ", this.user_details);
        } else {
          this.email_send_status = "";
        }

        // if returnInputs.options_status is not equal to appointment or pertemuan
        if (
          this.returnInputs.options_status !== "Appointment" &&
          this.returnInputs.options_status !== "Pertemuan"
        ) {
          console.log(
            "CURRENT OPTIONS STATUS: ",
            this.returnInputs.options_status
          );
          var booking =
            "_Book a Meeting/Demo?_:" +
            "" +
            this.returnInputs.options_status +
            "\r\n";

          whatsappMessage = currentTab + input_string + booking;
          whatsappMessage = window.encodeURIComponent(whatsappMessage);
          var url = "https://wa.me/+628179009790?text=" + whatsappMessage;
          window.open(url, "_blank");
        }
        // console.log("email body: ", email_object);
      }
    },
    failedSendMessage() {
      setTimeout(() => {
        this.noti = this.$vs.notification({
          color: "warn",
          position: "top-center",
          duration: "none",
          width: "100%",
          title: this.page_settings.notification_error.title,
          text: this.pages_settings.notification_error.text,
        });
      }, 3000);
    },
    cleanSlate() {
      this.email_send_status = "";
    },
    copyPageSettings() {
      this.page_settings = this.page_settings_default;
      this.data_copied = true;
    },
  },
  computed: {
    ...mapGetters(["register_dialog"]),
    returnRegisterDialogStat() {
      return this.register_dialog;
    },
    returnInputs() {
      if (this.currentTab === "Register") {
        return this.page_settings.register_items;
      } else if (this.currentTab === "Contact Sales") {
        return this.page_settings.contact_items;
      }
    },
    returnTitle() {
      if (this.currentTab === "Register") {
        var x = {
          header: this.page_settings.register_items.header,
          outlined_text: this.page_settings.register_items.outlined_text,
          img: this.page_settings.register_items.img,
        };
        return x;
      } else if (this.currentTab === "Contact Sales") {
        var x = {
          header: this.page_settings.contact_items.header,
          outlined_text: this.page_settings.contact_items.outlined_text,
          img: this.page_settings.contact_items.img,
        };
        return x;
      }
    },

    returnBackgrounds() {
      if (this.currentTab === "Contact Sales") {
        return this.consult_bg;
      }

      if (this.currentTab === "Register") {
        return this.register_bg;
      }
    },

    fieldsPassed() {
      const check_empty = (obj) => obj.input === "";
      const is_empty = this.returnInputs.inputs.some(check_empty);
      if (is_empty) return false;
      return true;
    },

    messagePassed() {
      if (this.currentTab === "Contact Sales") {
        // find in return inputs where type is equal to textarea
        var textarea = this.returnInputs.inputs.find(function (post, index) {
          if (post.type == "textarea") return true;
        });

        if (textarea.input.length >= 30 && textarea.input.length <= 250) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    regexEmailChecker() {
      let object_to_test = {};
      const check_email = function (obj) {
        const x = obj.type === "email";
        if (x) object_to_test = obj;
        return x;
      };
      if (this.returnInputs.inputs.some(check_email)) {
        const test_validity =
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            object_to_test.input
          );
        return test_validity;
      }
    },
  },
  watch: {
    returnRegisterDialogStat(val) {
      console.log("reached register dialog: ", val);
      this.active = val.state;
      this.currentTab = val.tab;

      this.data_copied = false;
      this.cleanSlate();
      this.page_settings = {};
      this.copyPageSettings();
    },
    email_send_status(val) {
      if (val === "sending") {
        this.button_loading = this.$vs.loading({
          target: this.$refs.getchabet,
          scale: "0.6",
          background: "primary",
          opacity: 1,
          color: "#fff",
        });
      }
      if (val === "success") {
        // clear inputs in register items
        if (this.currentTab === "Register")
          this.send_amount.reg = this.send_amount.reg + 1;
        if (this.currentTab === "Contact Sales")
          this.send_amount.con = this.send_amount.con + 1;
        this.button_loading.close();
      }

      if (val === "failed") {
        this.button_loading.close();
      }
    },
  },

  mounted() {
    this.copyPageSettings();
  },
};
</script>

<style lang="scss" scoped>
::-webkit-input-placeholder {
  font-size: 13px !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px !important;
}
.bg {
  height: 50%;
  width: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: fill;
}
.link {
  cursor: pointer;
}
</style>
